/*
 *
 * Global actions
 *
 */
import {
  generateActionWithBody,
  generateActionWithId,
  generateActionWithMultipleId,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

export const fetchLabPhase = generateActionWithMultipleValues(
  constants.fetchLabPhase.request,
);

export const getAllStudents = generateActionWithMultipleValues(
  constants.getAllStudents.request,
);
export const getStudentByID = generateActionWithId(
  constants.getStudentById.request,
);
export const getStudentStats = generateActionWithMultipleValues(
  constants.getStudentStats.request,
);

export const getLabPhaseAssignment = generateActionWithMultipleValues(
  constants.getLabPhaseAssignment.request,
);

export const fetchAllLabPhaseApplications = generateActionWithMultipleValues(
  constants.fetchAllLabPhaseApplications.request,
);

export const getStudentAbsence = generateActionWithMultipleId(
  constants.getStudentAbsence.request,
);

export const getStudentAbsenceNew = generateActionWithMultipleId(
  constants.getStudentAbsenceNew.request,
);

export const getAllSessionInstance = generateActionWithMultipleValues(
  constants.getAllSessionInstance.request,
);

export const getLeaderBoardStudents = generateActionWithMultipleValues(
  constants.getLeaderBoardStudents.request,
);
export const getStudentLearnings = generateActionWithMultipleId(
  constants.getStudentLearnings.request,
);
export const resetState = generateEmptyAction(constants.resetState.request);
export const getLabPhaseAssignmentByID = generateActionWithId(
  constants.getLabPhaseAssignmentByID.request,
);
export const fetchStudentDiplomaByTrackId = generateActionWithMultipleValues(
  constants.fetchStudentDiplomaByTrackId.request,
);
export const fetchStudentsPerformanceById = generateActionWithMultipleValues(
  constants.fetchStudentsPerformanceById.request,
);
export const setPaginationIndex = generateActionWithBody(
  constants.setPaginationIndex.request,
);
export const getStudentsDetails = generateActionWithMultipleValues(
  constants.getStudentsDetails.request,
);
