import { generateActionWithMultipleValues } from '../../shared/utils/generic-saga';
import constants from './constants';

export const fetchAllAutomatedOneToOne = generateActionWithMultipleValues(
  constants.fetchAllAutomatedOneToOne.request,
);

export const fetchAllFaceToFaceOneToOne = generateActionWithMultipleValues(
  constants.fetchAllFaceToFaceOneToOne.request,
);

export const fetchAllOneToOne = generateActionWithMultipleValues(
  constants.fetchAllOneToOne.request,
);
