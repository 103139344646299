import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getSkill = async (trackId: string, skillId: string) => {
  const result = await axiosInstance.get(
    URL.learn.skills.getSkill(trackId, skillId),
  );
  return result.data;
};

export default {
  getSkill,
};
