import React from 'react';

type OverviewIconProps = {
  className?: string;
};

const OverviewIcon: React.FC<OverviewIconProps> = ({ className }) => (
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.53125 0.367188C4.82422 0.230469 5.15625 0.230469 5.44922 0.367188L9.72656 2.33984C9.88281 2.41797 10 2.57422 10 2.75C10 2.94531 9.88281 3.10156 9.72656 3.17969L5.44922 5.15234C5.15625 5.28906 4.82422 5.28906 4.53125 5.15234L0.253906 3.17969C0.0976562 3.10156 0 2.94531 0 2.75C0 2.57422 0.0976562 2.41797 0.253906 2.33984L4.53125 0.367188ZM9.72656 4.83984C9.88281 4.91797 10 5.07422 10 5.25C10 5.44531 9.88281 5.60156 9.72656 5.67969L5.44922 7.65234C5.15625 7.78906 4.82422 7.78906 4.53125 7.65234L0.253906 5.67969C0.0976562 5.60156 0 5.44531 0 5.25C0 5.07422 0.0976562 4.91797 0.253906 4.83984L1.30859 4.35156L4.27734 5.71875C4.72656 5.93359 5.25391 5.93359 5.70312 5.71875L8.67188 4.35156L9.72656 4.83984ZM5.70312 8.21875L8.67188 6.85156L9.72656 7.33984C9.88281 7.41797 10 7.57422 10 7.75C10 7.94531 9.88281 8.10156 9.72656 8.17969L5.44922 10.1523C5.15625 10.2891 4.82422 10.2891 4.53125 10.1523L0.253906 8.17969C0.0976562 8.10156 0 7.94531 0 7.75C0 7.57422 0.0976562 7.41797 0.253906 7.33984L1.30859 6.85156L4.27734 8.21875C4.72656 8.43359 5.25391 8.43359 5.70312 8.21875Z"
      fill="#333333"
    />
  </svg>
);

export default OverviewIcon;

OverviewIcon.defaultProps = {
  className: '',
};
