import React from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './TaskCard.module.css';
import OneToOneIcon from '../../../../shared/Icons/OneToOne';
import BulletEyeIcon from '../../../../shared/Icons/BulletEye';
import LabPhaseIcon from '../../../../shared/Icons/LabPhase';
import ApplicationIcon from '../../../../shared/Icons/ApplicationIcon';
import { hideLongText } from '../../../../shared/constants';
import { OneToOneApiResponse } from '../../../../store/oneToOneList/types';
import { CheckpointApiResponse } from '../../../../store/checkpoint/types';
import { Application, LabPhaseAssignmentsApiResponse } from '../../../../store/Student/types';

type Entity = OneToOneApiResponse | CheckpointApiResponse | LabPhaseAssignmentsApiResponse | Application;

interface ITaskCardProps {
  type: string;
  entity: Entity;
}

const TaskCard: React.FC<ITaskCardProps> = ({ type, entity }) => {
  const renderIcon = (entityType: string) => {
    switch (entityType) {
      case 'oneToOne':
        return <OneToOneIcon />;
      case 'automatedOneToOne':
        return <OneToOneIcon />;
      case 'checkpoint':
        return <BulletEyeIcon />;
      case 'labPhase':
        return <LabPhaseIcon />;
      case 'application':
        return <ApplicationIcon />;
      default:
        return <OneToOneIcon />;
    }
  };

  const renderEventTitle = (entityType: string) => {
    switch (entityType) {
      case 'oneToOne':
        return 'On site 1-1: ';
      case 'automatedOneToOne':
        return 'Online 1-1: ';
      case 'checkpoint':
        return 'Checkpoint: ';
      case 'labPhase':
        return 'Labphase: ';
      case 'application':
        return 'Labphase request: ';
      default:
        return <OneToOneIcon />;
    }
  };

  const renderEntityName = (_entity: Entity) => {
    let result = '';
    if ('oneToOneAssignment' in _entity) {
      result = _entity.oneToOneAssignment.name;
    } else if ('checkpoint' in _entity) {
      result = _entity.checkpoint.name;
    } else if ('currentPhase' in _entity) {
      result = _entity.currentPhase.name;
    } else if ('requestedBy' in _entity) {
      result = _entity.requestedBy.title;
    }

    return result;
  };

  const renderUnlockDate = (_entity: Entity) => {
    let result = '';
    if ('oneToOneAssignment' in _entity) {
      result = moment(_entity.oneToOneAssignment.unlockDate).format('DD/MM/YYYY');
    } else if ('checkpointAssignment' in _entity) {
      result = moment(_entity.checkpointAssignment.submissionDate).format('DD/MM/YYYY');
    } else if ('currentPhase' in _entity) {
      result = moment(_entity.currentPhase.unlockDate).format('DD/MM/YYYY');
    } else if ('requestedBy' in _entity) {
      result = moment(_entity.requestedBy.dateEffective).format('DD/MM/YYYY');
    }
    return result;
  };

  const getRedirectionLink = (entityType: string, _entity: Entity) => {
    let result = '';

    if ('oneToOneAssignment' in _entity) {
      if (entityType === 'oneToOne') {
        result = `one-to-one/${_entity.oneToOneAssignment.id}`;
      } else {
        result = `automated-one-to-one-details/${_entity.oneToOneAssignment.id}`;
      }
    } else if ('checkpointAssignment' in _entity) {
      result = `checkpoint/${_entity.checkpointAssignment.id}`;
    } else if ('currentPhase' in _entity) {
      result = `instructor-labPhase-details/${_entity.labPhaseAssignment.id}/${_entity.currentPhase.id}`;
    } else if ('requestedBy' in _entity) {
      result = `/instructor-labPhase-request-review/${_entity.labPhase.id}/${_entity.student.id}`;
    }

    return result;
  };

  return (
    <div className={styles.taskCard}>
      <div className={styles.header}>
        <p className={styles.studentName}>{hideLongText(entity.student.fullName, 37)}</p>
        <p className={styles.date}>{renderUnlockDate(entity)}</p>
      </div>
      <div className={styles.body}>
        <p className={styles.type}>
          {renderIcon(type)}
          {' '}
          {renderEventTitle(type)}
          {' '}
          {renderEntityName(entity)}
        </p>
        <Link className={styles.link} to={getRedirectionLink(type, entity)}>
          Details
        </Link>
      </div>
    </div>
  );
};

export default TaskCard;
