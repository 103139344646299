import React from 'react';

type SuperSkillIconProps = {
  className?: string;
};

const SuperSkillIcon: React.FC<SuperSkillIconProps> = ({ className }) => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.9375 4.625H7.125C7.37891 4.625 7.61328 4.80078 7.69141 5.03516C7.78906 5.28906 7.73047 5.5625 7.53516 5.73828L2.53516 10.1133C2.30078 10.2891 1.98828 10.3086 1.75391 10.1328C1.51953 9.97656 1.42188 9.66406 1.53906 9.39062L3.04297 5.875H0.855469C0.601562 5.875 0.367188 5.71875 0.289062 5.48438C0.191406 5.23047 0.25 4.95703 0.445312 4.78125L5.44531 0.40625C5.67969 0.230469 5.99219 0.210938 6.22656 0.386719C6.46094 0.542969 6.55859 0.855469 6.44141 1.12891L4.9375 4.625Z"
      fill="#176FFF"
    />
  </svg>
);

export default SuperSkillIcon;

SuperSkillIcon.defaultProps = {
  className: '',
};
