import React from 'react';

type BookIconProps = {
  className?: string;
};

const BookIcon: React.FC<BookIconProps> = ({ className }) => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.75 6.8125C8.75 7.10547 8.61328 7.33984 8.4375 7.53516V9.11719C8.61328 9.23438 8.75 9.42969 8.75 9.64453C8.75 9.97656 8.45703 10.25 8.125 10.25H1.875C0.820312 10.25 0 9.42969 0 8.375V2.125C0 1.08984 0.820312 0.25 1.875 0.25H7.8125C8.32031 0.25 8.75 0.679688 8.75 1.1875V6.8125ZM2.79297 2.75C2.63672 2.75 2.5 2.90625 2.5 3.0625C2.5 3.23828 2.63672 3.375 2.79297 3.375H6.5625C6.71875 3.375 6.875 3.23828 6.875 3.0625C6.875 2.90625 6.71875 2.75 6.54297 2.75H2.79297ZM2.79297 4C2.63672 4 2.5 4.15625 2.5 4.3125C2.5 4.48828 2.63672 4.625 2.79297 4.625H6.5625C6.71875 4.625 6.875 4.48828 6.875 4.3125C6.875 4.15625 6.71875 4 6.54297 4H2.79297ZM7.5 9V7.75H1.875C1.52344 7.75 1.25 8.04297 1.25 8.375C1.25 8.72656 1.52344 9 1.875 9H7.5Z"
      fill="#828A92"
    />
  </svg>
);

export default BookIcon;

BookIcon.defaultProps = {
  className: '',
};
