import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchStudentDiplomaByTrackId = async (values: {
    query: [];
    trackId: string;
    studentId: string;
  }) => {
  const { query, trackId, studentId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.store.diploma.getStudentDiploma(studentId, trackId)
        + queryString,
  );
  return result.data;
};

export default {
  fetchStudentDiplomaByTrackId,
};
