import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

type Query = {
    query: [];
  };

export const getNPS = async (values: Query) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.feedbacks.fetchInstructorNps + queryString,
  );
  return result.data;
};

export default {
  getNPS,
};
