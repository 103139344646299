/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { lazy, Suspense } from 'react';

const LazyLoad = (importComponent:any) => {
  const LazyLoadComponent = (props:any) => {
    const LazyComponent = lazy(importComponent);

    return (
      <Suspense fallback={<div />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };

  return LazyLoadComponent;
};

export default LazyLoad;
