import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getInstructorEarnings = async (id:string) => {
  const result = await axiosInstance.get(
    URL.pay.paycheck.getAllEarnings(id),
  );
  return result.data;
};

export const getEarningsSession = async (earningId:string, instructorId:string) => {
  const result = await axiosInstance.get(
    URL.pay.paycheck.getEarningsSession(earningId, instructorId),
  );

  return result.data;
};

export const getPayCheckById = async (payCheckId:string) => {
  const result = await axiosInstance.get(
    URL.pay.paycheck.getPayCheckById(payCheckId),
  );
  return result.data;
};

export default {
  getInstructorEarnings,
  getEarningsSession,
  getPayCheckById,
};
