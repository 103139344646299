import { Reducer } from 'redux';
import constants from './constants';
import {
  TransformTracksData,
  TransformoneToOneAssignmentsFilterData,
  TransformAutomatedoneToOneAssignmentsFilterData,
} from './helper';
import { oneToOneListState } from './types';

// Type-safe initialState!
export const initialState: oneToOneListState = {
  errors: undefined,
  fetchAutomatedOneToOneloading: false,
  fetchAllOneToOnesLoading: false,
  automatedOneToOnes: [],
  OneToOnes: [],
  AllOneToOnes: [],
  automatedOneToOneAssignmentsFilter: [],
  oneToOneAssignmentsFilter: [],
  tracks: [],
  fetchAllFaceToFaceOneToOneloading: false,
  fetchAllOneToOneErrors: [],
  fetchAllFaceToFaceOneToOneErrors: [],
};
// eslint-disable-next-line default-param-last
const reducer: Reducer<oneToOneListState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchAllAutomatedOneToOne.request:
      return { ...state, fetchAutomatedOneToOneloading: true };

    case constants.fetchAllAutomatedOneToOne.success: {
      return {
        ...state,
        fetchAutomatedOneToOneloading: false,
        tracks: TransformTracksData(action.data.model),
        automatedOneToOneAssignmentsFilter:
          TransformAutomatedoneToOneAssignmentsFilterData(action.data.model),
        oneToOneAssignmentsFilter: TransformoneToOneAssignmentsFilterData(
          action.data.model,
        ),
        automatedOneToOnes: action.data.model
          .filter(
            (onetoone: {
              oneToOneAssignment: { isAutomated: Boolean; status: number };
            }) => onetoone.oneToOneAssignment.isAutomated
              && onetoone.oneToOneAssignment.status >= 2,
          )
          .sort(
            (
              a: {
                oneToOneAssignment: { isAutomated: Boolean; status: number };
              },
              b: {
                oneToOneAssignment: { isAutomated: Boolean; status: number };
              },
            ) => a.oneToOneAssignment.status - b.oneToOneAssignment.status,
          ),
      };
    }
    case constants.fetchAllAutomatedOneToOne.failure: {
      return { ...state, fetchAutomatedOneToOneloading: false, errors: [] };
    }

    case constants.fetchAllFaceToFaceOneToOne.request:
      return { ...state, fetchAllFaceToFaceOneToOneloading: true };

    case constants.fetchAllFaceToFaceOneToOne.success: {
      return {
        ...state,
        fetchAllFaceToFaceOneToOneloading: false,
        tracks: TransformTracksData(action.data.model),
        oneToOneAssignmentsFilter: TransformoneToOneAssignmentsFilterData(
          action.data.model,
        ),
        OneToOnes: action.data.model
          .filter(
            (onetoone: {
              oneToOneAssignment: { isAutomated: Boolean; status: number };
            }) => !onetoone.oneToOneAssignment.isAutomated
              && onetoone.oneToOneAssignment.status >= 2,
          )
          .sort(
            (
              a: {
                oneToOneAssignment: { isAutomated: Boolean; status: number };
              },
              b: {
                oneToOneAssignment: { isAutomated: Boolean; status: number };
              },
            ) => a.oneToOneAssignment.status - b.oneToOneAssignment.status,
          ),
      };
    }
    case constants.fetchAllFaceToFaceOneToOne.failure: {
      return {
        ...state,
        fetchAllFaceToFaceOneToOneloading: false,
        fetchAllFaceToFaceOneToOneErrors: [],
      };
    }
    case constants.fetchAllOneToOne.request:
      return { ...state, fetchAllOneToOnesLoading: true };
    case constants.fetchAllOneToOne.success:
      return {
        ...state,
        AllOneToOnes: action.data.model,
        fetchAllOneToOnesLoading: false,
      };
    case constants.fetchAllOneToOne.failure:
      return {
        ...state,

        fetchAllFaceToFaceOneToOneErrors: [],
      };

    default: {
      return state;
    }
  }
};

export { reducer as oneToOneListReducer };
