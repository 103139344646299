import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getTrackStats = async (values: {
  query: [];
  trackId: string;
}) => {
  const { trackId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.store.tracks.getTrackStats(trackId) + queryString,
  );

  return result.data;
};

export const fetchSkeleton = async (values: { query: []; trackId: string }) => {
  const { trackId, query } = values;

  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.store.tracks.getSkeleton(trackId) + queryString,
  );

  return result.data;
};

export default {
  getTrackStats,
  fetchSkeleton,
};
