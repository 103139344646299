import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getAllStudents = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.students.getAllStudents + queryString,
  );
  return result.data;
};

export const fetchStudentById = async (values: { query: [], id: string }) => {
  const { query, id } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.students.getStudentById(id) + queryString,
  );
  return result.data;
};

export const getStudentStats = async (values: { query: []; id: string }) => {
  const { query, id } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.students.getStudentStats(id)
        + queryString,
  );
  return result.data;
};

export default {
  getAllStudents,
  fetchStudentById,
  getStudentStats,
};
