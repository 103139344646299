import React from 'react';

type GraduationCapProps = {
  className?: string;
};

const GraduationCapIcon: React.FC<GraduationCapProps> = ({ className }) => (
  <svg
    width="21"
    height="15"
    viewBox="0 0 21 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M20.3584 3.80078C20.6709 3.92578 20.8897 4.20703 20.8897 4.51953C20.8897 4.86328 20.6709 5.14453 20.3584 5.26953L11.5459 8.42578C11.3272 8.48828 11.1084 8.51953 10.8897 8.51953C10.6397 8.51953 10.4209 8.48828 10.2022 8.42578L4.26465 6.30078C3.88965 6.70703 3.6084 7.20703 3.4834 7.76953C3.70215 7.92578 3.88965 8.20703 3.88965 8.51953C3.88965 8.83203 3.7334 9.11328 3.51465 9.30078L4.26465 13.957C4.32715 14.2695 4.07715 14.5195 3.7959 14.5195H1.95215C1.6709 14.5195 1.4209 14.2695 1.4834 13.957L2.2334 9.30078C2.01465 9.11328 1.88965 8.83203 1.88965 8.51953C1.88965 8.11328 2.13965 7.76953 2.4834 7.61328C2.6084 6.98828 2.88965 6.42578 3.26465 5.92578L1.38965 5.26953C1.07715 5.14453 0.889653 4.86328 0.889653 4.51953C0.889653 4.20703 1.07715 3.92578 1.38965 3.80078L10.2022 0.644531C10.4209 0.582031 10.6397 0.519531 10.8897 0.519531C11.1084 0.519531 11.3272 0.582031 11.5147 0.644531L20.3584 3.80078ZM11.0459 7.01953L17.9522 4.51953L11.0147 2.05078C10.9522 2.05078 10.8272 2.01953 10.7022 2.05078L3.7959 4.51953L4.7959 4.89453L10.7334 3.05078C10.9834 2.95703 11.2647 3.11328 11.3584 3.39453C11.4209 3.64453 11.2959 3.92578 11.0147 4.01953L6.3584 5.45703L10.7334 7.01953C10.7647 7.01953 10.8897 7.05078 11.0459 7.01953ZM15.0147 8.23828L16.4522 7.73828L16.8584 11.6758C16.8897 13.582 13.8584 14.5195 10.8897 14.5195C7.88965 14.5195 4.88965 13.582 4.88965 11.6758L5.2959 7.73828L6.7334 8.23828L6.38965 11.7383C6.38965 12.082 7.9209 13.0195 10.8897 13.0195C13.8272 13.0195 15.3897 12.082 15.3897 11.7383L15.0147 8.23828Z"
      fill="#828A92"
    />
  </svg>
);

export default GraduationCapIcon;

GraduationCapIcon.defaultProps = {
  className: '',
};
