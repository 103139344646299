import React from 'react';

type LabPhaseIconProps = {
  className?: string;
};

const LabPhaseIcon: React.FC<LabPhaseIconProps> = ({ className }) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.28516 2.75H5.04297C6.58594 0.191406 8.98828 0.09375 10.4336 0.367188C10.668 0.40625 10.8438 0.582031 10.8828 0.816406C11.1562 2.26172 11.0586 4.66406 8.5 6.20703V7.96484C8.5 8.45312 8.22656 8.92188 7.79688 9.17578L6.07812 10.1914C5.92188 10.2695 5.74609 10.2891 5.60938 10.1914C5.45312 10.1133 5.375 9.95703 5.375 9.78125V7.55469C5.375 7.10547 5.19922 6.67578 4.88672 6.36328C4.57422 6.05078 4.14453 5.875 3.69531 5.875H1.46875C1.29297 5.875 1.13672 5.79688 1.05859 5.64062C0.960938 5.50391 0.960938 5.32812 1.05859 5.17188L2.07422 3.45312C2.32812 3.02344 2.79688 2.75 3.28516 2.75ZM8.5 1.96875C8.05078 1.96875 7.71875 2.32031 7.71875 2.75C7.71875 3.19922 8.05078 3.53125 8.5 3.53125C8.92969 3.53125 9.28125 3.19922 9.28125 2.75C9.28125 2.32031 8.92969 1.96875 8.5 1.96875ZM4.24219 9.42969C3.28516 10.4062 1 10.25 1 10.25C1 10.25 0.84375 7.96484 1.80078 7.00781C2.48438 6.34375 3.57812 6.34375 4.24219 7.00781C4.90625 7.67188 4.90625 8.76562 4.24219 9.42969ZM3.32422 7.92578C3.10938 7.69141 2.73828 7.69141 2.52344 7.92578C2.19141 8.23828 2.25 9 2.25 9C2.25 9 3.01172 9.05859 3.32422 8.72656C3.55859 8.51172 3.55859 8.14062 3.32422 7.92578Z"
      fill="#A463FF"
    />
  </svg>
);

export default LabPhaseIcon;

LabPhaseIcon.defaultProps = {
  className: '',
};
