/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from '@gomycode/types-utils/Types/types';
import { put, call, take } from 'redux-saga/effects';

export const sagaTypes = {
  GET: 'get',
  GET_BY_ID: 'get_by_id',
  DELETE: 'delete',
  POST: 'post',
  PUT: 'put',
  WITHOUT_API: 'without_api',
  GET_WITH_MULTIPLE_ID: 'get_with_multiple_id',
  DELETE_WITH_MULTIPLE_VALUES: 'delete_with_multiple_values',
  TEMPLATE_WITH_MULTIPLE_ID_AND_BODY: 'template_with_multiple_id_and_body',
  TEMPLATE_WITH_MULTIPLE_VALUES: 'template_with_multiple_values',
  GET_WITH_MULTIPLE_IDS: 'get_with_multiple_Ids',
};
function* fetchTemplate(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);
      const { id } = action;
      const result = yield call(api, id);
      yield put({
        type: actionTypes.success,
        data: result,
        id,
      });
    }
  } catch (e) {
    console.log('fetch/delete', e);
    yield put({ type: actionTypes.failure, e });
  }
}

function* deleteWithMultipleValues(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  // Receive the action parameter separately

  try {
    while (true) {
      const action = yield take(actionTypes.request);
      yield call(api, action.values);
      yield put({
        type: actionTypes.success,
        data: action.values,
      });
    }
  } catch (e) {
    console.log('deleteWithMultipleValues', e);
    yield put({ type: actionTypes.failure, e });
  }
}

function* postTemplate(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      const result = yield call(api, action.body);
      yield put({
        type: actionTypes.success,
        data: result,
        request: action.body,
      });
    }
  } catch (e) {
    console.log('post', e);
    yield put({ type: actionTypes.failure, e });
  }
}

function* putTemplate(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      const result = yield call(api, action.id, action.body);
      yield put({
        type: actionTypes.success,
        data: result,
        request: { body: action.body, id: action.id },
      });
    }
  } catch (e) {
    console.log('put', e);
    yield put({ type: actionTypes.failure, e });
  }
}
function* withoutApiTemplate(actionTypes: Action): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      yield put({
        type: actionTypes.success,
        data: action.body,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.failure, e });
  }
}

export function* getWithMultipleId(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      const result = yield call(api, action.id1, action.id2);
      yield put({
        type: actionTypes.success,
        data: result,
        id1: action.id1,
        id2: action.id2,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.failure, e });
  }
}

export function* getWithMultipleIdsAndBody(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      const result = yield call(
        api,
        action.id1,
        action.id2,
        action.id3,
        action.values,
      );

      yield put({
        type: actionTypes.success,
        data: result,
        id1: action.id1,
        id2: action.id2,
        id3: action.id3,
        request: action.values,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.failure, e });
  }
}

export function* templateWithMultipleIdAndBody(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);

      const result = yield call(api, action.id1, action.id2, action.body);
      yield put({
        type: actionTypes.success,
        data: result,
        id1: action.id1,
        id2: action.id2,
        request: action.body,
      });
    }
  } catch (e) {
    console.log('the error is: ', e);
    yield put({ type: actionTypes.failure, e });
  }
}

export function* templateWithMultipleValues(
  actionTypes: Action,
  api: any,
): Generator<any, void, any> {
  try {
    while (true) {
      const action = yield take(actionTypes.request);
      const { values } = action;
      const result = yield call(api, values);
      yield put({
        type: actionTypes.success,
        data: result,
        request: values,
      });
    }
  } catch (e) {
    console.log('e: get with multi values ', e);
    yield put({ type: actionTypes.failure, e });
  }
}

export const generateSaga = (
  sagaType: {},
  actionTypes: Action,
  api?: any,
): any => {
  switch (sagaType) {
    case sagaTypes.GET:
    case sagaTypes.DELETE:
      return fetchTemplate(actionTypes, api);
    case sagaTypes.DELETE_WITH_MULTIPLE_VALUES:
      return deleteWithMultipleValues(actionTypes, api);
    case sagaTypes.GET_WITH_MULTIPLE_ID:
      return getWithMultipleId(actionTypes, api);
    case sagaTypes.GET_WITH_MULTIPLE_IDS:
      return getWithMultipleIdsAndBody(actionTypes, api);

    case sagaTypes.TEMPLATE_WITH_MULTIPLE_ID_AND_BODY:
      return templateWithMultipleIdAndBody(actionTypes, api);
    case sagaTypes.TEMPLATE_WITH_MULTIPLE_VALUES:
      return templateWithMultipleValues(actionTypes, api);
    case sagaTypes.POST:
      return postTemplate(actionTypes, api);
    case sagaTypes.PUT:
      return putTemplate(actionTypes, api);
    case sagaTypes.WITHOUT_API:
      return withoutApiTemplate(actionTypes);
    default:
      return null;
  }
};

export const generateActionWithBody = (type: string) => (body: {}) => ({
  type,
  body,
});

export const generateActionWithId = (type: string) => (id: string) => ({
  type,
  id,
});

export const generateActionWithMultipleId = (type: string) => (id1: string, id2: string) => ({
  type,
  id1,
  id2,
});

export const generateActionWithMultipleIdsAndBody = (type: string) => (id1: string, id2: string, id3: string, values: any) => ({
  type,
  id1,
  id2,
  id3,
  values,
});

export const generateActionWithMultipleIdAndBody = (type: string) => (id1: string, id2: string, body: {}) => ({
  type,
  id1,
  id2,
  body,
});

export const generateEmptyAction = (type: string) => () => ({
  type,
});

export const generateActionWithBodyAndId = (type: string) => (id: string, body: {}) => ({
  type,
  id,
  body,
});

export const generateActionWithMultipleValues = (type: string) => (values: any) => ({
  type,
  values,
});

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export const generateActionTypes = (root: string, prefix: string) => ({
  request: `${root}${prefix}_${REQUEST}`,
  success: `${root}${prefix}_${SUCCESS}`,
  failure: `${root}${prefix}_${FAILURE}`,
});
