import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchAllOneToOne = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.oneToOnes.fetchAllOneToOnes + queryString,
  );

  return result.data;
};

export const fetchOneToOneById = async (values: {
    query: [];
    oneToOneAssignmentId: string;
  }) => {
  const { query, oneToOneAssignmentId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.oneToOnes.fetchOneToOneById(oneToOneAssignmentId)
        + queryString,
  );
  return result.data;
};
export const startOneToOne = async (id: string) => {
  const result = await axiosInstance.put(
    URL.instructor.oneToOnes.startOneToOne(id),
    null,
  );
  return result.data;
};

export const updateFaceToFaceStudentAnswer = async (id: string, body: {}) => {
  const result = await axiosInstance.put(
    URL.instructor.oneToOnes.updateFaceToFaceStudentAnswer(id),
    { ...body },
  );
  return result.data;
};

export const sendFaceToFaceInstructorFeedback = async (
  id: string,
  body: {},
) => {
  const result = await axiosInstance.put(
    URL.instructor.oneToOnes.sendFaceToFaceInstructorFeedback(id),
    { ...body },
  );
  return result.data;
};

export const getOrderedOneToOnes = async (values: {
  query: [];
  trackId: string;
}) => {
  const { trackId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.oneToOnes.getOrderedOneToOnes(trackId)
        + queryString,
  );

  return result.data;
};

export default {
  fetchAllOneToOne,
  fetchOneToOneById,
  startOneToOne,
  updateFaceToFaceStudentAnswer,
  sendFaceToFaceInstructorFeedback,
  getOrderedOneToOnes,
};
