export const calculateScore = (scores: [], questionsNumber: number): number => {
  const loopLength = scores.filter((el) => el !== undefined).length;
  const loopLengthFiltered = scores.filter((el) => el !== -1);
  let average = 0;
  let sum = 0;
  for (let i = 0; i < loopLength; i += 1) {
    if (loopLengthFiltered[i]) {
      sum += loopLengthFiltered[i];
    }
  }
  if (questionsNumber > 0) average = (sum / (questionsNumber * 2)) * 100;
  return Math.round(average);
};
export const getNextStudent = (
  automatedOneToOneList: { oneToOneAssignment: { id: string } }[],
  currentId?: string,
) => automatedOneToOneList.reduce((acc, el, index, arr) => {
  if (el.oneToOneAssignment.id === currentId) {
    if (arr[index + 1]) return arr[index + 1].oneToOneAssignment.id;
    return arr[0].oneToOneAssignment.id;
  }
  return acc;
}, '');
