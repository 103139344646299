import React from 'react';

type OneToOneIconProps = {
  className?: string;
};

const OneToOneIcon: React.FC<OneToOneIconProps> = ({ className }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.75 4.25C4.57031 4.25 5.25 4.92969 5.25 5.75V8C5.25 8.42188 4.89844 8.75 4.5 8.75V11.75C4.5 12.1719 4.14844 12.5 3.75 12.5H2.25C1.82812 12.5 1.5 12.1719 1.5 11.75V8.75C1.07812 8.75 0.75 8.42188 0.75 8V5.75C0.75 4.92969 1.40625 4.25 2.25 4.25H3.75ZM3 3.5C2.15625 3.5 1.5 2.84375 1.5 2C1.5 1.17969 2.15625 0.5 3 0.5C3.82031 0.5 4.5 1.17969 4.5 2C4.5 2.84375 3.82031 3.5 3 3.5ZM9 3.5C8.15625 3.5 7.5 2.84375 7.5 2C7.5 1.17969 8.15625 0.5 9 0.5C9.82031 0.5 10.5 1.17969 10.5 2C10.5 2.84375 9.82031 3.5 9 3.5ZM9.75 4.25C10.5703 4.25 11.25 4.92969 11.25 5.75V8C11.25 8.42188 10.8984 8.75 10.5 8.75V11.75C10.5 12.1719 10.1484 12.5 9.75 12.5H8.25C7.82812 12.5 7.5 12.1719 7.5 11.75V8.75C7.07812 8.75 6.75 8.42188 6.75 8V5.75C6.75 4.92969 7.40625 4.25 8.25 4.25H9.75Z"
      fill="#9747FF"
    />
  </svg>
);

export default OneToOneIcon;

OneToOneIcon.defaultProps = {
  className: '',
};
