import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const refreshAccessToken = async (body: {refreshToken: string}) => {
  const { refreshToken } = body;
  const result = await axiosInstance.post(
    URL.auth.refreshAccessToken,
    { refreshToken },
  );
  return result.data;
};

export const changePassword = async (body: {}) => {
  const result = await axiosInstance.post(
    URL.auth.changePassword,
    body,
  );
  return result.data;
};

export default {
  refreshAccessToken,
  changePassword,
};
