import React from 'react';

type LinkArrowIconProps = {
  className?: string;
};

const LinkArrowIcon: React.FC<LinkArrowIconProps> = ({ className }) => (
  <svg
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.75 0.75V5.125C6.75 5.47656 6.45703 5.75 6.125 5.75C5.77344 5.75 5.5 5.47656 5.5 5.125V2.27344L1.55469 6.19922C1.4375 6.31641 1.28125 6.375 1.125 6.375C0.949219 6.375 0.792969 6.31641 0.675781 6.19922C0.421875 5.96484 0.421875 5.55469 0.675781 5.32031L4.60156 1.375H1.75C1.39844 1.375 1.125 1.10156 1.125 0.75C1.125 0.417969 1.39844 0.125 1.75 0.125H6.125C6.45703 0.125 6.75 0.417969 6.75 0.75Z"
      fill="#176FFF"
    />
  </svg>
);

export default LinkArrowIcon;

LinkArrowIcon.defaultProps = {
  className: '',
};
