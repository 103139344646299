import React from 'react';

type UserCheckIconProps = {
  className?: string;
};
const UserCheckIcon: React.FC<UserCheckIconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M6.42188 7.5293C8.67188 7.5293 10.5 9.35742 10.5 11.6074C10.5 12.0527 10.125 12.4043 9.67969 12.4043H0.796875C0.351562 12.4043 0 12.0527 0 11.6074C0 9.35742 1.80469 7.5293 4.05469 7.5293H6.42188ZM5.25 6.4043C3.58594 6.4043 2.25 5.06836 2.25 3.4043C2.25 1.76367 3.58594 0.404297 5.25 0.404297C6.89062 0.404297 8.25 1.76367 8.25 3.4043C8.25 5.06836 6.89062 6.4043 5.25 6.4043ZM14.8125 3.56836C15.0469 3.7793 15.0469 4.13086 14.8359 4.36523L12.3984 6.99023C12.3047 7.10742 12.1406 7.1543 12 7.1543C11.8359 7.1543 11.6953 7.10742 11.6016 6.99023L10.2891 5.67773C10.0547 5.4668 10.0547 5.11523 10.2891 4.9043C10.5 4.66992 10.8516 4.66992 11.0625 4.9043L11.9766 5.79492L14.0156 3.5918C14.2266 3.35742 14.5781 3.35742 14.8125 3.56836Z"
      fill="white"
    />
  </svg>
);

export default UserCheckIcon;

UserCheckIcon.defaultProps = {
  className: '',
};
