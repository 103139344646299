import React from 'react';

type CheckpointIconProps = {
  className?: string;
};

const CheckpointIcon: React.FC<CheckpointIconProps> = ({ className }) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.125 9.875C7.125 8.57617 8.16406 7.5 9.5 7.5C10.7988 7.5 11.875 8.57617 11.875 9.875C11.875 11.2109 10.7988 12.25 9.5 12.25C8.16406 12.25 7.125 11.2109 7.125 9.875ZM9.5 10.4688C9.79688 10.4688 10.0938 10.209 10.0938 9.875C10.0938 9.57812 9.79688 9.28125 9.5 9.28125C9.16602 9.28125 8.90625 9.57812 8.90625 9.875C8.90625 10.209 9.16602 10.4688 9.5 10.4688ZM3.5625 9.875C3.5625 6.60938 6.19727 3.9375 9.5 3.9375C12.7656 3.9375 15.4375 6.60938 15.4375 9.875C15.4375 13.1777 12.7656 15.8125 9.5 15.8125C6.19727 15.8125 3.5625 13.1777 3.5625 9.875ZM9.5 14.0312C11.7637 14.0312 13.6562 12.1758 13.6562 9.875C13.6562 7.61133 11.7637 5.71875 9.5 5.71875C7.19922 5.71875 5.34375 7.61133 5.34375 9.875C5.34375 12.1758 7.19922 14.0312 9.5 14.0312ZM19 9.875C19 15.1445 14.7324 19.375 9.5 19.375C4.23047 19.375 0 15.1445 0 9.875C0 4.64258 4.23047 0.375 9.5 0.375C14.7324 0.375 19 4.64258 19 9.875ZM9.5 2.15625C5.23242 2.15625 1.78125 5.64453 1.78125 9.875C1.78125 14.1426 5.23242 17.5938 9.5 17.5938C13.7305 17.5938 17.2188 14.1426 17.2188 9.875C17.2188 5.64453 13.7305 2.15625 9.5 2.15625Z"
      fill="#176FFF"
    />
  </svg>
);

export default CheckpointIcon;

CheckpointIcon.defaultProps = {
  className: '',
};
