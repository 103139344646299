/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/AUTOMATEDONETOONELIST/';
export default {
  fetchAllAutomatedOneToOne: generateActionTypes(
    root,
    'FETCH_ALL_AUTOMATED_ONE_TO_ONE',
  ),
  fetchAllFaceToFaceOneToOne: generateActionTypes(
    root,
    'FETCH_ALL_FACE_TO_FACE_ONE_TO_ONE',
  ),
  fetchAllOneToOne: generateActionTypes(
    root,
    'FETCH_ALL_ONE_TO_ONE',
  ),
};
