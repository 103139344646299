import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchAutomatedOneToOneById = async (values: {
    query: [];
    oneToOneAssignmentId: string;
  }) => {
  const { query, oneToOneAssignmentId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.automatedOneToOnes.fetchAutomatedOneToOneById(oneToOneAssignmentId)
        + queryString,
  );
  return result.data;
};

export const updateStudentAnswer = async (id: string, body: {}) => {
  const result = await axiosInstance.put(
    URL.instructor.automatedOneToOnes.updateStudentAnswer(id),
    { ...body },
  );
  return result.data;
};
export const sendInstructorFeedback = async (id: string, body: {}) => {
  const result = await axiosInstance.put(
    URL.instructor.automatedOneToOnes.sendInstructorFeedback(id),
    { ...body },
  );
  return result.data;
};

export default {
  fetchAutomatedOneToOneById,
  updateStudentAnswer,
  sendInstructorFeedback,
};
