import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@gomycode/design-system';
import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styles from './FinalModal.module.css';

const FinalModal = () => {
  const { labPhaseAssignmentId } = useParams();

  const finalModal = useSelector(
    (state: {
      labPhases: {
        finalModal: boolean;
      };
    }) => state.labPhases.finalModal,
  );
  return (
    <Modal
      footer={false}
      width={735}
      closeIcon={(
        <Link to={`/instructor-labPhase-phases/${labPhaseAssignmentId}`}>
          <FontAwesomeIcon icon={faCircleXmark} />
        </Link>
      )}
      open={finalModal}
      style={{ textAlign: 'center' }}
    >
      <div className={styles.imageContainer}>
        <img
          src="https://gomycodelearn.blob.core.windows.net/assets/images/general/diploma-celebration.png"
          alt="celebration"
          className={styles.image}
        />
      </div>
      <h1 className={styles.title}>
        Your students will receive your Evaluation
      </h1>
      <p className={styles.description}>
        The student ‘s whole project is validated and approved. The final report
        will be generated shortly. you can access it from the student’s “All
        Phases” page.
      </p>
      <Link to={`/instructor-labPhase-phases/${labPhaseAssignmentId}`}>
        <Button className={styles.btn} priority="primary">
          All phases
        </Button>
      </Link>
    </Modal>
  );
};

export default FinalModal;
