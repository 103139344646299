import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/groups';

export function* fetchAllGroups() {
  try {
    const result: ReturnType<typeof api.fetchAllGroups> = yield call(api.fetchAllGroups);
    yield put({
      type: constants.fetchAllGroups.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllGroups.failure,
      e,
    });
  }
}

export function* fetchAllGroupsWatcher() {
  yield takeEvery(constants.fetchAllGroups.request, fetchAllGroups);
}

export function* fetchGroupById(action: {
  type: string;
  id: string
}) {
  try {
    const result: ReturnType<typeof api.fetchGroupById> = yield call(api.fetchGroupById, action.id);
    yield put({
      type: constants.fetchGroupById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchGroupById.failure,
      e,
    });
  }
}

export function* fetchGroupByIdWatcher() {
  yield takeEvery(constants.fetchGroupById.request, fetchGroupById);
}

export function* fetchAllActiveGroups(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllActiveGroups> = yield call(api.fetchAllActiveGroups, action.values);
    yield put({
      type: constants.fetchAllActiveGroups.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllActiveGroups.failure,
      e,
    });
  }
}

export function* fetchAllActiveGroupsWatcher() {
  yield takeEvery(constants.fetchAllActiveGroups.request, fetchAllActiveGroups);
}

export default function* groupsSaga() {
  yield all([
    fetchAllGroupsWatcher(),
    fetchGroupByIdWatcher(),
    fetchAllActiveGroupsWatcher(),
  ]);
}
