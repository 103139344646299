import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import * as studentApi from '../../shared/services/instructor/students';
import * as labPhaseApi from '../../shared/services/instructor/labPhases';
import * as statsApi from '../../shared/services/stats/students';
import * as rankedApi from '../../shared/services/ranked/rank';
import * as diplomaApi from '../../shared/services/store/diploma';
import * as studentAbsenceApi from '../../shared/services/capacity/studentAbsences';
import * as sessionInstancesApi from '../../shared/services/attendance/sessionInstances';
import constants from './constants';

export function* getAllStudents(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result : ReturnType<typeof studentApi.getAllStudents> = yield call(studentApi.getAllStudents, action.values);
    yield put({
      type: constants.getAllStudents.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getAllStudents.failure,
      e,
    });
  }
}
export function* getAllStudentsWatcher() {
  yield takeEvery(constants.getAllStudents.request, getAllStudents);
}

export function* getStudentsDetails(action: {
  type: string;
  values: {
    query: [];
  }
}) {
  try {
    const result: ReturnType<typeof studentApi.getAllStudents> = yield call(studentApi.getAllStudents, action.values);
    yield put({
      type: constants.getStudentsDetails.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentsDetails.failure,
      e,
    });
  }
}

export function* getStudentsDetailsWatcher() {
  yield takeEvery(constants.getStudentsDetails.request, getStudentsDetails);
}

export function* fetchStudentById(action: {
  type: string;
  values: {
    query: [];
    id: string;
  }
}) {
  try {
    const result: ReturnType<typeof studentApi.fetchStudentById> = yield call(studentApi.fetchStudentById, action.values);
    yield put({
      type: constants.getStudentById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentById.failure,
      e,
    });
  }
}

export function* fetchStudentByIdWatcher() {
  yield takeEvery(constants.getStudentById.request, fetchStudentById);
}

export function* fetchLabPhaseAssignmentByI(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof labPhaseApi.getLabPhaseAssignmentById> = yield call(labPhaseApi.getLabPhaseAssignmentById, action.id);
    yield put({
      type: constants.getLabPhaseAssignmentByID.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getLabPhaseAssignmentByID.failure,
      e,
    });
  }
}

export function* fetchLabPhaseAssignmentByIdWatcher() {
  yield takeEvery(constants.getLabPhaseAssignmentByID.request, fetchLabPhaseAssignmentByI);
}

export function* getStudentLabPhase(action: {
  type: string;
  values: {
    query: [];
  }
}) {
  try {
    const result: ReturnType<typeof labPhaseApi.fetchLabPhase> = yield call(labPhaseApi.fetchLabPhase, action.values);
    yield put({
      type: constants.fetchLabPhase.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchLabPhase.failure,
      e,
    });
  }
}

export function* getStudentLabPhaseWatcher() {
  yield takeEvery(constants.fetchLabPhase.request, getStudentLabPhase);
}

export function* fetchAllLabPhaseApplications(action: {
  type: string;
  values: {
    query: [];
  }
}) {
  try {
    const result: ReturnType<typeof labPhaseApi.fetchAllLabPhaseApplications> = yield call(labPhaseApi.fetchAllLabPhaseApplications, action.values);
    yield put({
      type: constants.fetchAllLabPhaseApplications.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllLabPhaseApplications.failure,
      e,
    });
  }
}

export function* fetchAllLabPhaseApplicationsWatcher() {
  yield takeEvery(constants.fetchAllLabPhaseApplications.request, fetchAllLabPhaseApplications);
}

export function* getStudentStats(action: {
  type: string;
  values: {
    query: [];
    id: string;
  }
}) {
  try {
    const result: ReturnType<typeof studentApi.getStudentStats> = yield call(studentApi.getStudentStats, action.values);
    yield put({
      type: constants.getStudentStats.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentStats.failure,
      e,
    });
  }
}

export function* getStudentStatsWatcher() {
  yield takeEvery(constants.getStudentStats.request, getStudentStats);
}

export function* getLabPhaseAssignment(action: {
  type: string;
  values: {
    query: [];
  }
}) {
  try {
    const result: ReturnType<typeof labPhaseApi.getLabPhaseAssignment> = yield call(labPhaseApi.getLabPhaseAssignment, action.values);
    yield put({
      type: constants.getLabPhaseAssignment.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getLabPhaseAssignment.failure,
      e,
    });
  }
}

export function* getLabPhaseAssignmentWatcher() {
  yield takeEvery(constants.getLabPhaseAssignment.request, getLabPhaseAssignment);
}

export function* getStudentAbsence(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof studentAbsenceApi.getStudentAbsence> = yield call(studentAbsenceApi.getStudentAbsence, action.id1, action.id2);
    yield put({
      type: constants.getStudentAbsence.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentAbsence.failure,
      e,
    });
  }
}

export function* getStudentAbsenceWatcher() {
  yield takeEvery(constants.getStudentAbsence.request, getStudentAbsence);
}

export function* getStudentAbsenceNew(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof studentAbsenceApi.getStudentAbsenceNew> = yield call(studentAbsenceApi.getStudentAbsenceNew, action.id1, action.id2);
    yield put({
      type: constants.getStudentAbsenceNew.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentAbsenceNew.failure,
      e,
    });
  }
}

export function* getStudentAbsenceNewWatcher() {
  yield takeEvery(constants.getStudentAbsenceNew.request, getStudentAbsenceNew);
}

export function* getStudentLearnings(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof statsApi.getStudentLearnings> = yield call(statsApi.getStudentLearnings, action.id1, action.id2);
    yield put({
      type: constants.getStudentLearnings.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentLearnings.failure,
      e,
    });
  }
}

export function* getStudentLearningsWatcher() {
  yield takeEvery(constants.getStudentLearnings.request, getStudentLearnings);
}

export function* getAllSessionInstance(action: {
  type: string;
  values: {
    query: [];
  }
}) {
  try {
    const result: ReturnType<typeof sessionInstancesApi.fetchAllSessionInstances> = yield call(sessionInstancesApi.fetchAllSessionInstances, action.values);
    yield put({
      type: constants.getAllSessionInstance.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getAllSessionInstance.failure,
      e,
    });
  }
}

export function* getAllSessionInstanceWatcher() {
  yield takeEvery(constants.getAllSessionInstance.request, getAllSessionInstance);
}

export function* getLeaderBoardStudents(action: {
  type: string;
  values: {
    query: [];
    trackId: string;
  }
}) {
  try {
    const result: ReturnType<typeof rankedApi.getLeaderBoardStudents> = yield call(rankedApi.getLeaderBoardStudents, action.values);
    yield put({
      type: constants.getLeaderBoardStudents.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getLeaderBoardStudents.failure,
      e,
    });
  }
}

export function* getLeaderBoardStudentsWatcher() {
  yield takeEvery(constants.getLeaderBoardStudents.request, getLeaderBoardStudents);
}

export function* resetState() {
  try {
    yield put({
      type: constants.resetState.success,
    });
  } catch (e) {
    yield put({
      type: constants.resetState.failure,
      e,
    });
  }
}

export function* resetStateWatcher() {
  yield takeEvery(constants.resetState.request, resetState);
}

export function* setPaginationIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setPaginationIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setPaginationIndex.failure,
      e,
    });
  }
}

export function* setPaginationIndexWatcher() {
  yield takeEvery(constants.setPaginationIndex.request, setPaginationIndex);
}

export function* fetchStudentDiplomaByTrackId(action: {
  type: string;
  values: {
    query: [];
    trackId: string;
    studentId: string;
  }
}) {
  try {
    const result: ReturnType<typeof diplomaApi.fetchStudentDiplomaByTrackId> = yield call(diplomaApi.fetchStudentDiplomaByTrackId, action.values);
    yield put({
      type: constants.fetchStudentDiplomaByTrackId.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchStudentDiplomaByTrackId.failure,
      e,
    });
  }
}

export function* fetchStudentDiplomaByTrackIdWatcher() {
  yield takeEvery(constants.fetchStudentDiplomaByTrackId.request, fetchStudentDiplomaByTrackId);
}

export function* fetchStudentsPerformanceById(action: {
  type: string;
  values: {
    query: [];
    studentId: string;
    trackId: string;
  }
}) {
  try {
    const result: ReturnType<typeof statsApi.fetchStudentsPerformanceById> = yield call(statsApi.fetchStudentsPerformanceById, action.values);
    yield put({
      type: constants.fetchStudentsPerformanceById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchStudentsPerformanceById.failure,
      e,
    });
  }
}

export function* fetchStudentsPerformanceByIdWatcher() {
  yield takeEvery(constants.fetchStudentsPerformanceById.request, fetchStudentsPerformanceById);
}

export default function* studentSaga() {
  yield all([
    getAllStudentsWatcher(),
    fetchStudentByIdWatcher(),
    getStudentLabPhaseWatcher(),
    getStudentStatsWatcher(),
    getLabPhaseAssignmentWatcher(),
    getStudentAbsenceWatcher(),
    getStudentLearningsWatcher(),
    getAllSessionInstanceWatcher(),
    getLeaderBoardStudentsWatcher(),
    resetStateWatcher(),
    fetchLabPhaseAssignmentByIdWatcher(),
    fetchStudentDiplomaByTrackIdWatcher(),
    getStudentAbsenceNewWatcher(),
    fetchStudentsPerformanceByIdWatcher(),
    fetchAllLabPhaseApplicationsWatcher(),
    setPaginationIndexWatcher(),
    getStudentsDetailsWatcher(),
  ]);
}
