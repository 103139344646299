import React from 'react';

type ArrowLeftIconProps = {
  className?: string;
};

const ArrowLeftIcon: React.FC<ArrowLeftIconProps> = ({ className }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12.2227 5.75C12.2227 6.13281 11.9219 6.43359 11.5664 6.43359H2.26953L5.90625 9.87891C6.17969 10.125 6.17969 10.5625 5.93359 10.8086C5.6875 11.082 5.27734 11.082 5.00391 10.8359L0.191406 6.24219C0.0546875 6.10547 0 5.94141 0 5.75C0 5.58594 0.0546875 5.42188 0.191406 5.28516L5.00391 0.691406C5.27734 0.445312 5.6875 0.445312 5.93359 0.71875C6.17969 0.964844 6.17969 1.40234 5.90625 1.64844L2.26953 5.09375H11.5664C11.9492 5.09375 12.2227 5.39453 12.2227 5.75Z"
      fill="#176FFF"
    />
  </svg>
);

export default ArrowLeftIcon;

ArrowLeftIcon.defaultProps = {
  className: '',
};
