import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/tracks';

export function* fetchAllTracks() {
  try {
    const result: ReturnType<typeof api.fetchAllTracks> = yield call(api.fetchAllTracks);
    yield put({
      type: constants.fetchTracks.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchTracks.failure,
      e,
    });
  }
}

export function* fetchAllTracksWatcher() {
  yield takeEvery(constants.fetchTracks.request, fetchAllTracks);
}

export default function* tracksSaga() {
  yield all([fetchAllTracksWatcher()]);
}
