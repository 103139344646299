/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/Profile/';
export default {
  getProfile: generateActionTypes(root, 'GET_PROFILE'),
  fetchDiscordURLs: generateActionTypes(root, 'FETCH_DISCORD_URL'),
  updateProfile: generateActionTypes(root, 'UPDATE_PROFILE'),
  toggleShowSuccessModal: generateActionTypes(
    root,
    'TOGGLE_SHOW_SUCCESS_MODAL',
  ),
  changePassword: generateActionTypes(root, 'CHANGE_PASSWORD'),
  getProfilesList: generateActionTypes(root, 'GET_PROFILES_LIST'),
  switchProfile: generateActionTypes(root, 'SWITCH_PROFILE'),
  switchProfileWithRedirection: generateActionTypes(root, 'SWITCH_PROFILE_WITH_REDIRECTION'),
  fetchInstructorDetails: generateActionTypes(root, 'GET_PROFILE_DETAILS'),
  getMainHsm: generateActionTypes(root, 'GET_MAIN_HSM'),

};
