import React from 'react';

import { Tooltip } from 'antd';
import { SelectInput } from '@gomycode/design-system';
import styles from './NPSCard.module.css';
import CircularProgressBarWithAvatar from '../CircularProgressBarWithAvatar';

import './override.css';
import InfoIcon from '../../../shared/Icons/InfoIcon';

type NPSPeriod = 'LAST 30 DAYS' | 'Overall';

interface INPSCardProps {
  nps: number;
  numberOfDetractors: number;
  numberOfFeedbacks: number;
  numberOfPromoters: number;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (param: NPSPeriod) => void;
  image: string;
}

const NPSCard: React.FC<INPSCardProps> = ({
  nps,
  numberOfDetractors,
  numberOfFeedbacks,
  numberOfPromoters,
  value,
  onChange,
  image,
}) => {
  const getPercentage = (number: number, total: number) => ((number / total) * 100).toFixed(0);

  const numberOfPassive = numberOfFeedbacks - (numberOfDetractors + numberOfPromoters);

  return (
    <div className={styles.NPSCard}>
      <div className={`${styles.header} header`}>
        <p className={styles.title}>Personal performance</p>
        <SelectInput
          className={styles.selectInput}
          placeholder="LAST 30 DAYS"
          value={value}
          onChange={onChange}
          data={[
            {
              value: 'LAST 30 DAYS',
              label: 'LAST 30 DAYS',
            },
            {
              value: 'Overall',
              label: 'Overall',
            },
          ]}
        />
      </div>
      {numberOfFeedbacks ? (
        <div className={styles.body}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <p className={styles.percentage}>
                {getPercentage(numberOfDetractors, numberOfFeedbacks)}
                %
              </p>
              <p className={styles.type}>Detractors</p>
            </div>
            <div className={styles.stat}>
              <p className={styles.percentage}>
                {getPercentage(numberOfPassive, numberOfFeedbacks)}
                %
              </p>
              <p className={styles.type}>Passive</p>
            </div>
            <div className={styles.stat}>
              <p className={styles.percentage}>
                {getPercentage(numberOfPromoters, numberOfFeedbacks)}
                %
              </p>
              <p className={styles.type}>Promoters</p>
            </div>
          </div>
          <CircularProgressBarWithAvatar
            strokeWidth={10}
            avatarSize={83}
            nps={nps}
            image={image}
          />
        </div>
      ) : (
        <div className={styles.noFeedback}>
          <img
            src="https://gomycodelearn.blob.core.windows.net/assets/images/general/no-data.svg"
            alt="no-data"
          />
          <p>There is no feedback submitted yet </p>
        </div>
      )}

      <Tooltip title="NPS ( Net Promoter Score), calculated after a survey asking your student to rate the  likelihood that they would recommend you">
        <div className={styles.footer}>
          <InfoIcon className={styles.infoIcon} />
          <p className={styles.moreInfo}>More info</p>
        </div>
      </Tooltip>
    </div>
  );
};

export default NPSCard;
