import {
  generateActionWithId,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  fetchAllGroups: generateEmptyAction(constants.fetchAllGroups.request),
  fetchGroupById: generateActionWithId(constants.fetchGroupById.request),
  fetchAllActiveGroups: generateActionWithMultipleValues(constants.fetchAllActiveGroups.request),
};

export default actions;
