import { Tooltip } from 'antd';

export const hideLongText = (text: string, maxLength = 20) => {
  const allText = <span className="hide-long-text">{text}</span>;
  if (text.length > maxLength) {
    return (
      <Tooltip placement="right" title={allText}>
        <span className="hide-long-text">
          {`${text.slice(
            0,
            maxLength,
          )}...`}
        </span>
      </Tooltip>
    );
  }

  return text;
};

export function nl2br(str: string, isXhtml?: boolean) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = isXhtml || typeof isXhtml === 'undefined' ? '<br />' : '<br>';
  const str2 = str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1 ${breakTag} $2`);
  return str2;
}
