/* eslint-disable @typescript-eslint/no-explicit-any */
export const encodeFieldsValues = (
  queryObj: Record<string, any>,
  nesting = '',
): string => Object.entries(queryObj)
  .map(([key, val]) => {
    if (Array.isArray(val) && val.length === 0) {
      return [nesting + key];
    }
    if (typeof val === 'object') {
      if (Object.keys(val).length === 0) {
        return [nesting + key];
      }
      return encodeFieldsValues(val, `${nesting}${key}.`);
    }
    return [nesting + key];
  })
  .join(',')
  .replace(/\.0/g, '[]');

export const encodeFields = (queryObj: {}, nesting = '') => ({
  key: 'fields',
  value: encodeFieldsValues(queryObj, nesting),
});
