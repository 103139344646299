import { InstructorDetails } from '@gomycode/types-utils/Types/types';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import BlockedCard from './shared/components/BlockedCard';

interface PrivateRouteProps {
  Component: ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ Component }) => {
  const instructorDetails = useSelector(
    (state: { profile: { instructorDetails: InstructorDetails } }) => state.profile.instructorDetails,
  );

  return !instructorDetails?.isTerminated ? <Component /> : <BlockedCard />;
};

export default PrivateRoute;
