import React, { useEffect, useState } from 'react';
import { Button } from '@gomycode/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from '@gomycode/types-utils/Types/types';
import styles from './BlockedCard.module.css';
import HSMContactModal from '../HSMContactModal';
import { getMainHsm } from '../../../store/profile/actions';
import BlockedIcon from '../../Icons/BlockedIcon';

const BlockedCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getMainHsm({
        query: [],
      }),
    );
  }, []);
  const mainHsm = useSelector(
    (state: { profile: { mainHsm: {
      email: string,
      fullName: string,
      picture: string,
      phoneNumber: string,
    } } }) => state.profile.mainHsm,
  );
  const location = useSelector(
    (state: { profile: { location: Location } }) => state.profile.location,
  );
  return (
    <div className={styles.blockedCard}>
      <h1 className={styles.title}>Learn/Dashboard</h1>
      <p className={styles.description}>{location?.name}</p>
      <div className={styles.container}>
        <div>
          <p className={styles.subTitle}>Your account has been blocked</p>
          <p className={styles.subDescription}>
            Your access has been suspended by your hackerspace manager
          </p>
          <Button
            onClick={showModal}
            priority="primary"
            className={styles.button}
          >
            Contact Hackerspace Manger
          </Button>
        </div>
        <BlockedIcon />
      </div>
      <HSMContactModal
        open={isModalOpen}
        handleClose={handleCancel}
        mainHsmInfos={mainHsm}
      />
    </div>
  );
};

export default BlockedCard;
