import React from 'react';

type RightArrowSvgProps = {
  className?: string;
};

const RightArrowSvg: React.FC<RightArrowSvgProps> = ({ className }) => (
  <svg
    className={className}
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="#333"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M8.55469 4.69922L5.42969 7.82422C5.3125 7.94141 5.15625 8 5 8C4.82422 8 4.66797 7.94141 4.55078 7.82422C4.29688 7.58984 4.29688 7.17969 4.55078 6.94531L6.60156 4.875H0.625C0.273438 4.875 0 4.60156 0 4.25C0 3.91797 0.273438 3.625 0.625 3.625H6.60156L4.55078 1.57422C4.29688 1.33984 4.29688 0.929688 4.55078 0.695312C4.78516 0.441406 5.19531 0.441406 5.42969 0.695312L8.55469 3.82031C8.80859 4.05469 8.80859 4.46484 8.55469 4.69922Z"
      fill="#333333"
    />
  </svg>
);

export default RightArrowSvg;

RightArrowSvg.defaultProps = {
  className: '',
};
