/* eslint-disable @typescript-eslint/no-explicit-any */
import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchCheckpointTopStudents = async (checkpointAssignmentId: string) => {
  const query = [{ key: 'limit', value: 3 }];
  const queryString = getQueryString(query as any);
  const result = await axiosInstance.get(
    URL.stats.checkpoints.getCheckpointTopStudents(checkpointAssignmentId)
        + queryString,
  );
  return result.data;
};

export default {
  fetchCheckpointTopStudents,
};
