import React from 'react';

type OneToOneIconProps = {
  className?: string;
};

const OneToOneIcon: React.FC<OneToOneIconProps> = ({ className }) => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.4375 9.875C5.80273 9.875 3.6875 7.75977 3.6875 5.125C3.6875 2.52734 5.80273 0.375 8.4375 0.375C11.0352 0.375 13.1875 2.52734 13.1875 5.125C13.1875 7.75977 11.0352 9.875 8.4375 9.875ZM10.293 11.6562C13.8555 11.6562 16.75 14.5508 16.75 18.1133C16.75 18.8184 16.1562 19.375 15.4512 19.375H1.38672C0.681641 19.375 0.125 18.8184 0.125 18.1133C0.125 14.5508 2.98242 11.6562 6.54492 11.6562H10.293ZM17.9004 12.25C21.2031 12.25 23.875 14.9219 23.875 18.1875C23.875 18.8555 23.3184 19.375 22.6875 19.375H17.5664C17.7891 19.0039 17.9375 18.5957 17.9375 18.1133C17.9375 15.7754 16.8613 13.6602 15.1543 12.25H17.9004ZM16.1562 9.875C14.9688 9.875 13.9297 9.42969 13.1875 8.65039C13.9297 7.68555 14.375 6.46094 14.375 5.125C14.375 4.16016 14.0781 3.23242 13.6699 2.41602C14.3379 1.89648 15.1914 1.5625 16.1562 1.5625C18.4199 1.5625 20.3125 3.45508 20.3125 5.71875C20.3125 8.01953 18.4199 9.875 16.1562 9.875Z"
      fill="#176FFF"
    />
  </svg>
);

export default OneToOneIcon;

OneToOneIcon.defaultProps = {
  className: '',
};
