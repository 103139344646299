import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import * as api from '../../shared/services/stats/instructors';
import constants from './constants';

export function* fetchInstructorStats() {
  try {
    const result: ReturnType<typeof api.fetchInstructorStats> = yield call(
      api.fetchInstructorStats,
    );
    yield put({
      type: constants.fetchInstructorStats.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchInstructorStats.failure,
      e,
    });
  }
}

export function* fetchInstructorStatsWatcher() {
  yield takeEvery(constants.fetchInstructorStats.request, fetchInstructorStats);
}

export default function* statsSaga() {
  yield all([fetchInstructorStatsWatcher()]);
}
