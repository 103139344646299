import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchAllGroups = async () => {
  const result = await axiosInstance.get(URL.instructor.groups.fetchAllGroups);

  return result.data;
};

export const fetchGroupById = async (id: string) => {
  const result = await axiosInstance.get(
    URL.instructor.groups.fetchGroupById(id),
  );
  return result.data;
};

export const fetchAllActiveGroups = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.groups.fetchAllActiveGroups + queryString,
  );

  return result.data;
};

export const getActiveStudents = async (values: {
  query: [];
  groupId: string;
}) => {
  const { query, groupId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.groups.getActiveStudents(groupId) + queryString,
  );
  return result.data;
};

export default {
  fetchAllGroups,
  fetchGroupById,
  fetchAllActiveGroups,
  getActiveStudents,
};
