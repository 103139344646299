import {
  generateActionWithBody,
  generateActionWithId,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  fetchAllSessionInstances: generateActionWithMultipleValues(
    constants.fetchAllSessionInstances.request,
  ),
  fetchDashboardSessionInstances: generateActionWithMultipleValues(
    constants.fetchDashboardSessionInstances.request,
  ),
  fetchAllSessionInstancesWithoutPagination: generateActionWithMultipleValues(
    constants.fetchAllSessionInstancesWithoutPagination.request,
  ),
  setPaginationIndex: generateActionWithBody(
    constants.setPaginationIndex.request,
  ),
  fetchSessionInstanceById: generateActionWithId(
    constants.fetchSessionInstanceById.request,
  ),
  markPresenceById: generateActionWithMultipleValues(
    constants.markPresenceById.request,
  ),
  showAbsenceModal: generateActionWithBody(constants.showAbsenceModal.request),
  completeSession: generateActionWithId(constants.completeSession.request),
  showCompleteSessionModal: generateActionWithBody(
    constants.showCompletedSessionModal.request,
  ),
  setSelectedStudentIndex: generateActionWithBody(
    constants.setSelectedStudentIndex.request,
  ),
  resetState: generateEmptyAction(constants.resetState.request),
  fetchPresenceStats: generateEmptyAction(constants.fetchPresenceStats.request),
  getStudentSessionInstances: generateActionWithMultipleValues(
    constants.getStudentSessionInstances.request,
  ),
  getStudentAverageAbsence: generateActionWithMultipleValues(
    constants.getStudentAverageAbsence.request,
  ),
};

export default actions;
