import { Reducer } from 'redux';
import momentTimezone from 'moment-timezone';
import { CastLocation, CastProfile, CastPayCheck } from '@gomycode/types-utils/Types/Casters';
import { PayCheck } from '@gomycode/types-utils/Types/types';
import constants from './constants';
import { EarningsState } from './types';

type PayCheckType = {
  payCheck: PayCheck;
};
// Type-safe initialState!
export const initialState: EarningsState = {
  errors: undefined,
  earningSession: [],
  payCheck: {
    instructor: CastProfile(),
    payCheck: CastPayCheck(),
    location: CastLocation(),
  },
  loading: false,
  earnings: [],
  location: CastLocation(),
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<EarningsState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchInstructorEarnings.request:
      return { ...state, loading: true };

    case constants.fetchInstructorEarnings.success: {
      return {
        ...state,

        loading: false,
        earnings: action.data.model.sort(
          (a: PayCheckType, b: PayCheckType) => momentTimezone(b?.payCheck?.createdAt).tz('UTC').valueOf()
            - momentTimezone(a?.payCheck?.createdAt).tz('UTC').valueOf(),
        ),
        location: action.data.model.location,
      };
    }
    case constants.fetchInstructorEarnings.failure: {
      return { ...state, loading: false, errors: [] };
    }
    case constants.fetchInstructorEarningSession.request:
      return { ...state, loading: true };

    case constants.fetchInstructorEarningSession.success: {
      return {
        ...state,

        loading: false,
        earningSession: action.data.model,
        location: action.data.model.location,
      };
    }
    case constants.fetchInstructorEarningSession.failure: {
      return { ...state, loading: false, errors: [] };
    }

    case constants.fetchInstructorPayCheckById.request:
      return { ...state, loading: true };

    case constants.fetchInstructorPayCheckById.success: {
      return {
        ...state,

        loading: false,
        payCheck: action.data.model,
        location: action.data.model.location,
      };
    }
    case constants.fetchInstructorPayCheckById.failure: {
      return { ...state, loading: false, errors: [] };
    }

    default: {
      return state;
    }
  }
};

export { reducer as earningsReducer };
