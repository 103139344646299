import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/sessionInstance/';

const constants = {
  fetchAllSessionInstances: generateActionTypes(
    root,
    'FETCH_ALL_SESSION_INSTANCES',
  ),
  fetchDashboardSessionInstances: generateActionTypes(
    root,
    'FETCH_DASHBOARD_SESSION_INSTANCES',
  ),
  fetchAllSessionInstancesWithoutPagination: generateActionTypes(
    root,
    'FETCH_ALL_SESSION_INSTANCES_WITHOUT_PAGINATION',
  ),
  setPaginationIndex: generateActionTypes(root, 'SET_PAGINATION_INDEX'),
  fetchSessionInstanceById: generateActionTypes(
    root,
    'FETCH_SESSION_INSTANCE_BY_ID',
  ),
  markPresenceById: generateActionTypes(root, 'MARK_PRESENCE_BY_ID'),
  showAbsenceModal: generateActionTypes(root, 'SHOW_ABSENCE_MODAL'),
  completeSession: generateActionTypes(root, 'COMPLETE_SESSION'),
  showCompletedSessionModal: generateActionTypes(
    root,
    'SHOW_COMPLETED_SESSION_MODAL',
  ),
  setSelectedStudentIndex: generateActionTypes(
    root,
    'SET_SELECTED_STUDENT_INDEX',
  ),
  resetState: generateActionTypes(root, 'RESET_STATE'),
  fetchPresenceStats: generateActionTypes(root, 'FETCH_PRESENCE_STATS'),
  getStudentSessionInstances: generateActionTypes(
    root,
    'GET_STUDENT_SESSION_INSTANCES',
  ),
  getStudentAverageAbsence: generateActionTypes(
    root,
    'GET_STUDENT_AVERAGE_ABSENCE',
  ),
};

export default constants;
