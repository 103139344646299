import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Progress } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';
import axiosInstance from '../../../shared/utils/axios-config';

import styles from './GuildCard.module.css';
import { hideLongText } from '../../../shared/utils/stringHelper';
import {
  Group,
  SessionInstanceApiReponseItem,
} from '../../../store/sessionInstance/types';
import URL from '../../../shared/services/endpoints';
import { ActiveGroupsApiResponse } from '../../../store/groups/types';

interface IGuildCardProps {
  data: ActiveGroupsApiResponse;
  sessionInstances: SessionInstanceApiReponseItem[];
}

const GuildCard: React.FC<IGuildCardProps> = ({ data, sessionInstances }) => {
  const [groupInfo, setGroupInfo] = useState<Group>();

  const { refetch: getGroupInfo } = useQuery(
    `group-info-${data.group.id}`,
    () => axiosInstance.get(
      URL.instructor.groups.fetchGroupByIdActive(data.group.id),
    ),
    {
      onSuccess: (response) => {
        setGroupInfo(response.data.model[0]);
      },
    },
  );

  useEffect(() => {
    getGroupInfo();
  }, []);

  const renderPercentage = () => {
    if (
      Math.round(
        (data.groupRecord.numberOfCompletedSkills
          / (data.opening.numberOfSubscribedStudents * data.track.skillsCount))
          * 100,
      ) > 100
    ) {
      return 100;
    } if (
      Number.isNaN(
        Math.round(
          (data.groupRecord.numberOfCompletedSkills
            / (data.opening.numberOfSubscribedStudents
              * data.track.skillsCount))
            * 100,
        ),
      )
    ) return 0;
    return Math.round(
      (data.groupRecord.numberOfCompletedSkills
          / (data.opening.numberOfSubscribedStudents * data.track.skillsCount))
          * 100,
    );
  };

  /** Group expected progress logic */

  const startDate = data.opening && data.opening.startDate;
  const dueDate = data.opening && data.opening.dueDate;

  const subscription = data.opening && moment(startDate);

  const expiration = data.opening && moment(dueDate);

  const trackDaysCount = data.opening && expiration.diff(subscription, 'days') + 2;
  const spentDaysCount = data.opening && moment().diff(moment(startDate), 'days') + 2;

  const skillduration = data.opening && trackDaysCount / data.track.skillsCount;

  const expected = data.opening && spentDaysCount / skillduration;

  // // Percentage expected progress
  const percentageExpectedProgress = data.opening
    ? Math.round((expected * 100) / data.track.skillsCount)
    : 80;

  const renderCurrentColor = () => {
    if (renderPercentage() >= percentageExpectedProgress) {
      return '#4CCD59';
    }
    if (
      percentageExpectedProgress - renderPercentage() < 20
      && percentageExpectedProgress - renderPercentage() !== 0
    ) {
      return '#176FFF';
    }
    if (
      percentageExpectedProgress - renderPercentage() > 20
      && percentageExpectedProgress - renderPercentage() <= 40
    ) {
      return '#FDA04B';
    }
    if (percentageExpectedProgress - renderPercentage() > 40) {
      return '#E55353';
    }

    return '#E55353';
  };

  /** ******************************* */

  return (
    <div className={styles.guildCard}>
      <div className={styles.header}>
        <img src={data.track.image} alt="track" className={styles.image} />
        <div className={styles.guildInfo}>
          <p className={styles.session}>
            Session
            {' '}
            {sessionInstances ? sessionInstances?.length : 0}
            /
            {data?.opening?.numberOfSessions}
          </p>
          <p className={styles.guildName}>{data.group.name}</p>
          <p className={styles.trackName}>
            {hideLongText(data.track.name, 23)}
          </p>
        </div>
      </div>
      <div className={styles.progress}>
        <p className={styles.progressTitle}>Current Learning Progress</p>
        <Progress
          showInfo={false}
          percent={renderPercentage()}
          strokeColor={renderCurrentColor()}
        />
        <div className={styles.metrics}>
          <p className={styles.skillsCount}>
            {data.groupRecord.numberOfCompletedSkills}
            {' '}
            of
            {' '}
            {data.track.skillsCount * data.opening.numberOfSubscribedStudents}
            {' '}
            Skills
          </p>
          <p className={styles.percentage}>
            {renderPercentage().toFixed(0)}
            %
          </p>
        </div>
      </div>
      <Divider />
      <div className={styles.avatarGroup}>
        <Avatar.Group maxCount={2}>
          {groupInfo?.students?.map((student) => (
            <Avatar src={student.picture} size={27} />
          ))}
        </Avatar.Group>
      </div>
    </div>
  );
};

export default GuildCard;
