import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/checkpoints';
import * as statsApi from '../../shared/services/stats/checkpoints';

export function* getUnlockedCheckpoint(action: {
  type: string;
  values: {
    query: [];
  }
  }) {
  try {
    const result: ReturnType<typeof api.getAllCheckpoints> = yield call(api.getAllCheckpoints, action.values);
    yield put({
      type: constants.getAllCheckpoints.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getAllCheckpoints.failure,
      e,
    });
  }
}

export function* getUnclosedCheckpointsWatcher() {
  yield takeEvery(constants.getUnclosedCheckpoints.request, getUnlockedCheckpoint);
}

export function* getClosedCheckpoints(action: {
  type: string;
  values: {
    query: [];
  }
  }) {
  try {
    const result: ReturnType<typeof api.getAllCheckpoints> = yield call(api.getAllCheckpoints, action.values);
    yield put({
      type: constants.getAllCheckpoints.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getAllCheckpoints.failure,
      e,
    });
  }
}

export function* getClosedCheckpointsWatcher() {
  yield takeEvery(constants.getClosedCheckpoints.request, getClosedCheckpoints);
}

export function* getUnlockedCheckpoints(action: {
  type: string;
  values: {
    query: [];
  }
  }) {
  try {
    const result: ReturnType<typeof api.getAllCheckpoints> = yield call(api.getAllCheckpoints, action.values);
    yield put({
      type: constants.getAllCheckpoints.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getAllCheckpoints.failure,
      e,
    });
  }
}

export function* getUnlockedCheckpointsWatcher() {
  yield takeEvery(constants.getUnlockedCheckpoints.request, getUnlockedCheckpoints);
}

export function* getAllCheckpoints(action: {
  type: string;
  values: {
    query: [];
  }
  }) {
  try {
    const result: ReturnType<typeof api.getAllCheckpoints> = yield call(api.getAllCheckpoints, action.values);
    yield put({
      type: constants.getAllCheckpoints.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getAllCheckpoints.failure,
      e,
    });
  }
}

export function* getAllCheckpointsWatcher() {
  yield takeEvery(constants.getAllCheckpoints.request, getAllCheckpoints);
}

export function* getCheckpointById(action: {
  type: string;
  id: string;
  }) {
  try {
    const result: ReturnType<typeof api.getCheckpointById> = yield call(api.getCheckpointById, action.id);
    yield put({
      type: constants.getCheckpointById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getCheckpointById.failure,
      e,
    });
  }
}

export function* getCheckpointByIdWatcher() {
  yield takeEvery(constants.getCheckpointById.request, getCheckpointById);
}

export function* addReview(action: {
  type: string;
  id: string;
  body: {};
  }) {
  try {
    const result: ReturnType<typeof api.addReview> = yield call(api.addReview, action.id, action.body);
    yield put({
      type: constants.addReview.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.addReview.failure,
      e,
    });
  }
}

export function* addReviewWatcher() {
  yield takeEvery(constants.addReview.request, addReview);
}

export function* approveCheckpoint(action: {
  type: string;
  id: string;
  body: {};
  }) {
  try {
    const result: ReturnType<typeof api.approveCheckpoint> = yield call(api.approveCheckpoint, action.id, action.body);
    yield put({
      type: constants.approveCheckpoint.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.approveCheckpoint.failure,
      e,
    });
  }
}

export function* approveCheckpointWatcher() {
  yield takeEvery(constants.approveCheckpoint.request, approveCheckpoint);
}

export function* updateRateStudentTabsStatus(action: {
  type: string;
  body: number
  }) {
  try {
    yield put({
      type: constants.updateRateStudentTabStatus.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.updateRateStudentTabStatus.failure,
      e,
    });
  }
}

export function* updateRateStudentTabStatusWatcher() {
  yield takeEvery(constants.updateRateStudentTabStatus.request, updateRateStudentTabsStatus);
}

export function* fetchCheckpointTopStudents(action: {
  type: string;
  id: string;
  }) {
  try {
    const result: ReturnType<typeof statsApi.fetchCheckpointTopStudents> = yield call(statsApi.fetchCheckpointTopStudents, action.id);
    yield put({
      type: constants.fetchCheckpointTopStudents.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchCheckpointTopStudents.failure,
      e,
    });
  }
}

export function* fetchCheckpointTopStudentsWatcher() {
  yield takeEvery(constants.fetchCheckpointTopStudents.request, fetchCheckpointTopStudents);
}

export function* showValidateCheckpointModal(action: {
  type: string;
  data: boolean;
  }) {
  try {
    yield put({
      type: constants.showValidateCheckpointModal.success,
      data: action.data,
    });
  } catch (e) {
    yield put({
      type: constants.showValidateCheckpointModal.failure,
      e,
    });
  }
}

export function* showValidateCheckpointModalWatcher() {
  yield takeEvery(constants.showValidateCheckpointModal.request, showValidateCheckpointModal);
}

export function* showDeadlineModal(action: {
  type: string;
  data: boolean;
  }) {
  try {
    yield put({
      type: constants.showDeadlineModal.success,
      data: action.data,
    });
  } catch (e) {
    yield put({
      type: constants.showDeadlineModal.failure,
      e,
    });
  }
}

export function* showDeadlineModalWatcher() {
  yield takeEvery(constants.showDeadlineModal.request, showDeadlineModal);
}

export default function* checkpointsSaga() {
  yield all([
    getUnclosedCheckpointsWatcher(),
    getClosedCheckpointsWatcher(),
    getUnlockedCheckpointsWatcher(),
    getAllCheckpointsWatcher(),
    getCheckpointByIdWatcher(),
    fetchCheckpointTopStudentsWatcher(),
    updateRateStudentTabStatusWatcher(),
    addReviewWatcher(),
    approveCheckpointWatcher(),
    showValidateCheckpointModalWatcher(),
    showDeadlineModalWatcher(),
  ]);
}
