/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

export const requestHeader = (options?: {}) => ({
  headers: {
    Authorization: `Bearer ${localStorage?.getItem('token')}`,
    ...options,
    'Accept-Language': 'en-US',
    'Access-Control-Allow-Origin': '*',
    'X-Timezone': `${moment().utcOffset()}`,
    'X-Client-App': 'instructor',
    Origin: window.location.origin,
  },
});

export const getToken = () => localStorage.getItem('token');
export const avatarSkeleton = (
  loading: boolean,
  type: string,
  active = true,
) => ({
  className: `x-${type}-avatar`,
  active,
  avatar: { shape: 'circle' },
  title: false,
  paragraph: false,
  loading,
});

export const decodeToken = () => {
  const token = localStorage.getItem('token');

  if (token) return jwt_decode(token);
  return null;
};

export const getRoles = () => {
  const data: any = decodeToken();

  const roles = data
    && data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

  if (roles && typeof roles === 'string') {
    return [roles];
  }
  return roles;
};
export const roles = {
  sales: 'SALES_AGENT',
  instructor: 'INSTRUCTOR',
  operation_manager: 'OPERATION_MANAGER',
  assistant_operation_manager: 'ASSISTANT_OPERATION_MANAGER',
  central_operation_manager: 'COM',
  salesAgent: 'SALES_AGENT',
  student: 'STUDENT',
};

export const isOperationManager = (apiRoles: string[]) => apiRoles.includes(roles.operation_manager);
export const isCentralOperationManager = (apiRoles: string[]) => apiRoles.includes(roles.central_operation_manager);

export const isAssistantOperationManager = (apiRoles: string[]) => apiRoles.includes(roles.assistant_operation_manager);

export const isSalesAgent = (apiRoles: string[]) => apiRoles?.includes(roles.salesAgent);
export const isOnlyAssistantOperationManager = (apiRoles: string[]) => isAssistantOperationManager(apiRoles)
  && !isOperationManager(apiRoles)
  && !isCentralOperationManager(apiRoles);
export const isManager = (apiRoles: string[]) => isAssistantOperationManager(apiRoles)
  || isOperationManager(apiRoles)
  || isCentralOperationManager(apiRoles);
export const isInstructor = (apiRoles: string[]) => apiRoles?.includes(roles.instructor);

export const isOnlyInstructor = (apiRoles: string[]) => !isSalesAgent(apiRoles) && isInstructor(apiRoles) && !isManager(apiRoles);
export const isStudent = (apiRoles: string[]) => apiRoles?.includes(roles.student);

export const isOnlyStudent = (apiRoles: string[]) => isStudent(apiRoles) && !isInstructor(apiRoles) && !isManager(apiRoles);
