import React from 'react';
import { Modal } from 'antd';
import styles from './HSMContactModal.module.css';

interface IHSMContactModalProps {
    open: boolean;
    handleClose: () => void;
    mainHsmInfos: {
      email: string;
      fullName: string;
      picture: string;
      phoneNumber: string;
    };
}

const HSMContactModal: React.FC<IHSMContactModalProps> = ({ open, handleClose, mainHsmInfos }) => (
  <Modal
    className={styles.modal}
    open={open}
    closable
    onCancel={handleClose}
    footer={false}
    width={639}
    centered
  >
    <h1 className={styles.title}>HSM Contact</h1>
    <div className={styles.footerCard}>
      <div>
        <p className={styles.label}>Phone</p>
        <p className={styles.data}>{mainHsmInfos?.phoneNumber}</p>
      </div>
      <div>
        <p className={styles.label}>Email</p>
        <p className={styles.data}>{mainHsmInfos?.email}</p>
      </div>
    </div>
  </Modal>
);

export default HSMContactModal;
