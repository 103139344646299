import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/LAB_PHASE/';

const constants = {
  getLabPhases: generateActionTypes(root, 'GET_ALL_LAB_PHASES'),
  fetchLabPhaseStudents: generateActionTypes(root, 'FETCH_LAB_PHASE_STUDENTS'),
  fetchLabPhaseById: generateActionTypes(root, 'FETCH_LAB_PHASE_BY_ID'),
  fetchTopThreeStudents: generateActionTypes(
    root,
    'FETCH_LAB_PHASE_TOP_STUDENTS',
  ),
  fetchLabPhaseStats: generateActionTypes(root, 'FETCH_LAB_PHASE_STATS'),
  isRefuseModalOpen: generateActionTypes(root, 'OPEN_REFUSE_MODAL'),
  setIsRefuseModalClose: generateActionTypes(root, 'CLOSE_REFUSE_MODAL'),
  isApprovedModalOpen: generateActionTypes(root, 'OPEN_APPROVED_MODAL'),
  setIsApprovedModalClose: generateActionTypes(root, 'CLOSE_APPROVED_MODAL'),
  getLabPhaseAssignmentsByID: generateActionTypes(
    root,
    'FETCH_LAB_PHASE_ASSIGNMENT_BY_ID',
  ),
  fetchLabPhaseAssignmentById: generateActionTypes(
    root,

    'FETCH_STUDENT_LAB_PHASE_ASSIGNMENT_BY_ID',
  ),
  acceptRequest: generateActionTypes(root, 'ACCEPT_THE_REQUEST'),
  rejectRequest: generateActionTypes(root, 'REJECT_THE_REQUEST'),
  fetchAllLabPhaseAssignments: generateActionTypes(
    root,
    'FETCH_ALL_LAB_PHASE_ASSIGNMENTS',
  ),
  fetchPhaseAssignmentById: generateActionTypes(
    root,
    'FETCH_PHASE_ASSIGMENT_BY_ID',
  ),
  approveLabPhase: generateActionTypes(root, 'APPROVE_LABPHASE_ASSIGNEMNT'),
  openFinalModal: generateActionTypes(root, 'OPEN_FINAL_MODAL'),
  fetchReviews: generateActionTypes(root, 'FETCH_REVIEWS'),
  rejectPhase: generateActionTypes(root, 'REJECT_PHASE'),
  setShowRejectionModal: generateActionTypes(root, 'SET_SHOW_REJECTION_MODAL'),
  setApprovedModalClose: generateActionTypes(root, 'SET_SHOW_APPROVED_MODAL'),
  approvePhase: generateActionTypes(root, 'APPROVE_PHASE'),
};

export default constants;
