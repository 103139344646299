import React from 'react';

type RightAngleIconProps = {
  className?: string;
  onClick?: () => void;
};

const RightAngleIcon: React.FC<RightAngleIconProps> = ({
  className,
  onClick,
}) => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      className={className}
      d="M1.125 12.875C0.878906 12.875 0.660156 12.793 0.496094 12.6289C0.140625 12.3008 0.140625 11.7266 0.496094 11.3984L5.11719 6.75L0.496094 2.12891C0.140625 1.80078 0.140625 1.22656 0.496094 0.898438C0.824219 0.542969 1.39844 0.542969 1.72656 0.898438L6.97656 6.14844C7.33203 6.47656 7.33203 7.05078 6.97656 7.37891L1.72656 12.6289C1.5625 12.793 1.34375 12.875 1.125 12.875Z"
      fill="#176FFF"
    />
  </svg>
);

export default RightAngleIcon;

RightAngleIcon.defaultProps = {
  className: '',
  onClick: undefined,
};
