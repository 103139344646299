import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/oneToOnes';

export function* fetchOneToOneById(action: {
  type: string;
  values: {
    query: [];
    oneToOneAssignmentId: string;
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchOneToOneById> = yield call(api.fetchOneToOneById, action.values);
    yield put({
      type: constants.fetchOneToOneById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchOneToOneById.failure,
      e,
    });
  }
}

export function* fetchOneToOneByIdWatcher() {
  yield takeEvery(constants.fetchOneToOneById.request, fetchOneToOneById);
}

export function* startOnetoOne(action: {
  type: string;
  id: string
}) {
  try {
    const result: ReturnType<typeof api.startOneToOne> = yield call(api.startOneToOne, action.id);
    yield put({
      type: constants.startOneToOne.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.startOneToOne.failure,
      e,
    });
  }
}

export function* startOneToOneWatcher() {
  yield takeEvery(constants.startOneToOne.request, startOnetoOne);
}

export function* setQuestionIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setQuestionIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setQuestionIndex.failure,
      e,
    });
  }
}

export function* setQuestionIndexWatcher() {
  yield takeEvery(constants.setQuestionIndex.request, setQuestionIndex);
}

export function* updateStudentAnswer(action: {
  type: string;
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof api.updateFaceToFaceStudentAnswer> = yield call(api.updateFaceToFaceStudentAnswer, action.id, action.body);
    yield put({
      type: constants.updateStudentAnswer.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.updateStudentAnswer.failure,
      e,
    });
  }
}

export function* updateStudentAnswerWatcher() {
  yield takeEvery(constants.updateStudentAnswer.request, updateStudentAnswer);
}

export function* setShowTimeIsUpModal(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.setShowTimeIsUpModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowTimeIsUpModal.failure,
      e,
    });
  }
}

export function* setShowTimeIsUpModalWatcher() {
  yield takeEvery(constants.setShowTimeIsUpModal.request, setShowTimeIsUpModal);
}

export function* setShowInstructorModal(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.setShowInstructorModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowInstructorModal.failure,
      e,
    });
  }
}

export function* setShowInstructorModalWatcher() {
  yield takeEvery(constants.setShowInstructorModal.request, setShowInstructorModal);
}

export function* handleBackFromFeedback(action: {
  type: string;
  body: number
}) {
  try {
    yield put({
      type: constants.handleBackFromFeedback.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.handleBackFromFeedback.failure,
      e,
    });
  }
}

export function* handleBackFromFeedbackWatcher() {
  yield takeEvery(constants.handleBackFromFeedback.request, handleBackFromFeedback);
}

export function* resetOneToOneSettings() {
  try {
    yield put({
      type: constants.resetOneToOneSettings.success,
    });
  } catch (e) {
    yield put({
      type: constants.resetOneToOneSettings.failure,
      e,
    });
  }
}

export function* resetOneToOneSettingsWatcher() {
  yield takeEvery(constants.resetOneToOneSettings.request, resetOneToOneSettings);
}

export function* sendInstructorFeedback(action: {
  type: string;
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof api.sendFaceToFaceInstructorFeedback> = yield call(api.sendFaceToFaceInstructorFeedback, action.id, action.body);
    yield put({
      type: constants.sendInstructorFeedback.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.sendInstructorFeedback.failure,
      e,
    });
  }
}

export function* sendInstructorFeedbackWatcher() {
  yield takeEvery(constants.sendInstructorFeedback.request, sendInstructorFeedback);
}

export function* setShowModal(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.setShowModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowModal.failure,
      e,
    });
  }
}

export function* setShowModalWatcher() {
  yield takeEvery(constants.setShowModal.request, setShowModal);
}

export function* setShowInstructorFeedback(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.setShowInstructorFeedback.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowInstructorFeedback.failure,
      e,
    });
  }
}

export function* setShowInstructorFeedbackWatcher() {
  yield takeEvery(constants.setShowInstructorFeedback.request, setShowInstructorFeedback);
}

export default function* faceToFaceOneToOneSaga() {
  yield all([
    fetchOneToOneByIdWatcher(),
    startOneToOneWatcher(),
    setQuestionIndexWatcher(),
    updateStudentAnswerWatcher(),
    setShowTimeIsUpModalWatcher(),
    setShowInstructorModalWatcher(),
    handleBackFromFeedbackWatcher(),
    resetOneToOneSettingsWatcher(),
    sendInstructorFeedbackWatcher(),
    setShowModalWatcher(),
    setShowInstructorFeedbackWatcher(),
  ]);
}
