import React from 'react';

type WorkshopIconProps = {
  className?: string;
};

const WorkshopIcon: React.FC<WorkshopIconProps> = ({ className }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.875 3.5C0.65625 3.5 0.5 3.34375 0.5 3.125V0.875C0.5 0.40625 0.890625 0 1.375 0H8.625C9.09375 0 9.5 0.40625 9.5 0.875V3.125C9.5 3.34375 9.32812 3.5 9.125 3.5C8.90625 3.5 8.75 3.34375 8.75 3.125V0.875C8.75 0.8125 8.6875 0.75 8.625 0.75H1.375C1.29688 0.75 1.25 0.8125 1.25 0.875V3.125C1.25 3.34375 1.07812 3.5 0.875 3.5ZM8.5 4C9.04688 4.01562 9.48438 4.45312 9.48438 5C9.48438 5.5625 9.04688 6 8.5 6C7.9375 6 7.5 5.5625 7.5 5C7.5 4.45312 7.9375 4.01562 8.5 4ZM8.5 5.25C8.625 5.25 8.73438 5.14062 8.73438 5C8.73438 4.875 8.625 4.76562 8.5 4.76562C8.35938 4.76562 8.25 4.875 8.25 5C8.25 5.14062 8.35938 5.25 8.5 5.25ZM5.35938 6.5C5.98438 6.5 6.5 7.03125 6.5 7.67188C6.5 7.85938 6.34375 8 6.17188 8H3.8125C3.64062 8 3.5 7.85938 3.5 7.67188C3.5 7.03125 4 6.5 4.625 6.5H5.35938ZM8.85938 6.5C9.48438 6.5 10 7.03125 10 7.67188C10 7.85938 9.84375 8 9.67188 8H7.3125C7.14062 8 7 7.85938 7 7.67188C7 7.03125 7.5 6.5 8.125 6.5H8.85938ZM4 5C4 4.45312 4.4375 4.01562 5 4C5.54688 4.01562 5.98438 4.45312 5.98438 5C5.98438 5.5625 5.54688 6 4.98438 6C4.4375 6 4 5.5625 4 5ZM5.23438 5C5.23438 4.875 5.125 4.76562 5 4.76562C4.85938 4.76562 4.75 4.875 4.75 5C4.75 5.14062 4.85938 5.25 5 5.25C5.125 5.25 5.23438 5.14062 5.23438 5ZM1.85938 6.5C2.48438 6.5 3 7.03125 3 7.67188C3 7.85938 2.84375 8 2.67188 8H0.3125C0.140625 8 0 7.85938 0 7.67188C0 7.03125 0.5 6.5 1.125 6.5H1.85938ZM0.5 5C0.5 4.45312 0.9375 4.01562 1.5 4C2.04688 4.01562 2.48438 4.45312 2.48438 5C2.48438 5.5625 2.04688 6 1.5 6C0.9375 6 0.5 5.5625 0.5 5ZM1.73438 5C1.73438 4.875 1.625 4.76562 1.5 4.76562C1.35938 4.76562 1.25 4.875 1.25 5C1.25 5.14062 1.35938 5.25 1.5 5.25C1.625 5.25 1.73438 5.14062 1.73438 5Z"
      fill="#828A92"
    />
  </svg>
);

export default WorkshopIcon;

WorkshopIcon.defaultProps = {
  className: '',
};
