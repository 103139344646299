/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skill } from '@gomycode/types-utils/Types/types';
import shuffle from 'lodash/shuffle';

export const ASSESSMENT_TYPE = {
  quiz: 0,
  reorder: 1,
  fillInTheBlanks: 2,
  codeCompiling: 3,
  matchingAssessment: 4,
};
export const ASSESSMENT_TYPE_NAME = {
  0: 'Quiz',
  1: 'Reorder',
  2: 'Fill in the blanks',
  3: 'Code execution',
  4: 'Matching',
};
export const FILL_IN_THE_BLANKS_TYPE = {
  blank: 0,
  placeholder: 1,
  break: 2,
  fakeBlank: 3,
};

const extractAssessment = (assessmentId: string, resources: any) => {
  const assessment = resources[assessmentId];
  let statements = null;
  let blankElementsList = null;
  let correctElements = null;

  switch (assessment.type) {
    case ASSESSMENT_TYPE.matchingAssessment:
      return {
        assessmentType: assessment.type,
        blocks: assessment.blocks.map((blockId: string) => ({
          id: blockId,
          content: resources[blockId],
        })),
        mainQuestion: assessment.mainQuestion,
      };
    case ASSESSMENT_TYPE.quiz:
      return {
        assessmentType: assessment.type,
        questions: assessment.questions.map((questionId: string) => ({
          id: questionId,
          choices: resources[questionId].choices.map(
            (choiceId: string) => resources[choiceId],
          ),
          text: resources[questionId].text,
          questionType: resources[questionId].questionType,
          placeholderLanguage: resources[resources[questionId].languageId].text,
          code: resources[resources[questionId].codeId].text,
        })),
      };

    case ASSESSMENT_TYPE.reorder:
      statements = assessment.statements.map(
        (statementId: string) => resources[statementId],
      );
      return {
        assessmentType: assessment.type,
        question: resources[assessment.questionId].text,
        statements,
        shuffleStatements: shuffle(statements),
      };

    case ASSESSMENT_TYPE.fillInTheBlanks:
      correctElements = assessment.elements.map(
        (elementId: string) => resources[elementId],
      );
      blankElementsList = correctElements.filter(
        (statement: { id: string; text: string; type: number }) => statement.type === FILL_IN_THE_BLANKS_TYPE.blank,
      );
      return {
        assessmentType: ASSESSMENT_TYPE.fillInTheBlanks,
        elements: correctElements.map(
          (data: { id: string; text: string; type: number }) => {
            if (data.type === FILL_IN_THE_BLANKS_TYPE.blank) return { type: FILL_IN_THE_BLANKS_TYPE.blank };
            return data;
          },
        ),
        correctElements,
        blanksItem: [...blankElementsList],
        shuffleBlanksItem: shuffle([...blankElementsList]),
      };

    case ASSESSMENT_TYPE.codeCompiling:
      return {
        assessmentType: ASSESSMENT_TYPE.codeCompiling,
        question: resources[assessment.questionId],
        language: resources[assessment.languageId],
        solution: resources[assessment.solutionId],
        placeholder: resources[assessment.placeholderId],
        testCases: assessment.testCases.map(
          (testCaseId: string) => resources[testCaseId],
        ),
      };

    default:
      return assessment;
  }
};

const extractLearningPages = (learningStyles: string[], resources: any) => Object.fromEntries(
  learningStyles.map((learningStyleId: string) => [
    learningStyleId,
    resources[learningStyleId].pages.map(
      (pageId: string) => resources[pageId].content,
    ),
  ]),
);

export const parseSkill = (skill: Skill) => {
  const parsedSkill = {
    learning: {},
    assessment: {},
    keywords: [],
    id: '',
    name: '',
  };

  if (skill && skill.resources) {
    const {
      learningStyles, assessments, resources, keywords, id, name,
    } = skill;
    parsedSkill.id = id;
    parsedSkill.name = name;
    const parsedResources = JSON.parse(resources);

    if (learningStyles) {
      const parsedLearningStyles = JSON.parse(learningStyles);
      parsedSkill.learning = extractLearningPages(
        parsedLearningStyles,
        parsedResources,
      );
    }
    if (assessments) {
      const assessmentIds = JSON.parse(assessments);
      if (assessmentIds.length > 0) {
        const assessmentIndex = assessmentIds[0] ? 0 : 0;
        parsedSkill.assessment = extractAssessment(
          assessmentIds[assessmentIndex],
          parsedResources,
        );
      }
    }
    if (keywords) {
      const parsedKeywords = JSON.parse(keywords);
      parsedSkill.keywords = parsedKeywords.map(
        (keywordId: string) => parsedResources[keywordId],
      );
    }
  }
  return parsedSkill;
};

export const getCurrentLearningStyleId = (skill: Skill) => {
  const skillLearningStylesIds = Object.keys(skill.learning);

  return skillLearningStylesIds[0];
};
