import { Link } from 'react-router-dom';
import styles from './TrackCard.module.css';
import LeftArrow from '../../../shared/Icons/RightAngleIcon';
import { hideLongText } from '../../../shared/constants';

type Props = {
  image: string;
  id: string;
  title: string;
};

const TrackCard = ({ image, title, id }: Props) => (
  <div className={styles.trackCard} key={id}>
    <div className={styles.box}>
      <img className={styles.cardImg} src={image} alt="" />
    </div>
    <h2 className={styles.title}>{title && hideLongText(title, 25)}</h2>
    <div className={styles.line}> </div>
    <div className={styles.card_footer}>
      <h6 className={styles.link}>
        <Link to={`/store-overview/${id}/node/${undefined}/kind/${undefined}`}>Track Preview</Link>
        <LeftArrow />
      </h6>
    </div>
  </div>
);

export default TrackCard;
