import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/online/';

const constants = {
  fetchActiveOnlineSessions: generateActionTypes(
    root,
    'FETCH_ACTIVE_ONLINE_SESSIONS',
  ),
  fetchActiveOnlineSessionsV2: generateActionTypes(
    root,
    'FETCH_ACTIVE_ONLINE_SESSIONS_V2',
  ),
  fetchAllOnlineSessions: generateActionTypes(
    root,
    'FETCH_ALL_ONLINE_SESSIONS',
  ),
  setPaginationIndex: generateActionTypes(root, 'SET_PAGINATION_INDEX'),
  fetchOnlineSessionById: generateActionTypes(
    root,
    'FETCH_ONLINE_SESSION_BY_ID',
  ),
  fetchAllOnlineSessionsWithoutPagination: generateActionTypes(
    root,
    'FETCH_ALL_ONLINE_SESSIONS_WITHOUT_PAGINATION',
  ),
  showResourcesModal: generateActionTypes(root, 'SHOW_RESOURCES_MODAL'),
  createOnlineSessionResource: generateActionTypes(
    root,
    ' CREATE_ONLINE_SESSION_RESOURCE',
  ),
  showNotesModal: generateActionTypes(root, 'SHOW_NOTES_MODAL'),
  updateOnlineSessionById: generateActionTypes(
    root,
    'UPDATE_ONLINE_SESSION_BY_ID',
  ),
};

export default constants;
