import React from 'react';

type ClockSvgProps = {
  className?: string;
};

const ClockSvg: React.FC<ClockSvgProps> = ({ className }) => (
  <svg
    className={className}
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5428 22.75C5.4412 22.75 0.542763 17.8516 0.542763 11.75C0.542763 5.69141 5.4412 0.75 11.5428 0.75C17.6014 0.75 22.5428 5.69141 22.5428 11.75C22.5428 17.8516 17.6014 22.75 11.5428 22.75ZM10.5115 11.75C10.5115 12.0938 10.6834 12.4375 10.9412 12.6094L15.0662 15.3594C15.5389 15.7031 16.1834 15.5742 16.4842 15.1016C16.8279 14.6289 16.699 13.9844 16.2264 13.6406L12.574 11.2344V5.90625C12.574 5.34766 12.1014 4.875 11.4998 4.875C10.9412 4.875 10.4685 5.34766 10.4685 5.90625L10.5115 11.75Z"
      fill="#4CCD59"
    />
  </svg>
);

export default ClockSvg;

ClockSvg.defaultProps = {
  className: '',
};
