import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/attendance/sessionInstances';

export function* fetchAllSessionInstances(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllSessionInstances> = yield call(
      api.fetchAllSessionInstances,
      action.values,
    );
    yield put({
      type: constants.fetchAllSessionInstances.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllSessionInstances.failure,
      e,
    });
  }
}

export function* fetchAllSessionInstancesWatcher() {
  yield takeEvery(constants.fetchAllSessionInstances.request, fetchAllSessionInstances);
}

export function* fetchDashboardSessionInstances(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllSessionInstances> = yield call(
      api.fetchAllSessionInstances,
      action.values,
    );
    yield put({
      type: constants.fetchDashboardSessionInstances.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchDashboardSessionInstances.failure,
      e,
    });
  }
}

export function* fetchDashboardSessionInstancesWatcher() {
  yield takeEvery(
    constants.fetchDashboardSessionInstances.request,
    fetchDashboardSessionInstances,
  );
}

export function* fetchAllSessionInstancesWithoutPagination(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllSessionInstances> = yield call(
      api.fetchAllSessionInstances,
      action.values,
    );
    yield put({
      type: constants.fetchAllSessionInstancesWithoutPagination.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllSessionInstancesWithoutPagination.failure,
      e,
    });
  }
}

export function* fetchAllSessionInstancesWithoutPaginationWatcher() {
  yield takeEvery(
    constants.fetchAllSessionInstancesWithoutPagination.request,
    fetchAllSessionInstancesWithoutPagination,
  );
}

export function* setPaginationIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setPaginationIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setPaginationIndex.failure,
      e,
    });
  }
}

export function* setPaginationIndexWatcher() {
  yield takeEvery(constants.setPaginationIndex.request, setPaginationIndex);
}

export function* resetState() {
  try {
    yield put({
      type: constants.resetState.success,
    });
  } catch (e) {
    yield put({
      type: constants.resetState.failure,
      e,
    });
  }
}

export function* resetStateWatcher() {
  yield takeEvery(constants.resetState.request, resetState);
}

export function* fetchSessionInstanceById(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.fetchSessionInstanceById> = yield call(
      api.fetchSessionInstanceById,
      action.id,
    );
    yield put({
      type: constants.fetchSessionInstanceById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchSessionInstanceById.failure,
      e,
    });
  }
}

export function* fetchSessionInstanceByIdWatcher() {
  yield takeEvery(constants.fetchSessionInstanceById.request, fetchSessionInstanceById);
}

export function* markAbsenceById(action: {
  type: string;
  values: {
    studentId: string;
    sessionInstanceId: string;
    body: {
      presenceStatus: number;
      note: string;
      absentAt: string;
    };
  };
}) {
  try {
    const result: ReturnType<typeof api.markPresenceById> = yield call(
      api.markPresenceById,
      action.values,
    );
    yield put({
      type: constants.markPresenceById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.markPresenceById.failure,
      e,
    });
  }
}

export function* markAbsenceByIdWatcher() {
  yield takeEvery(constants.markPresenceById.request, markAbsenceById);
}

export function* showAbsenceModal(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.showAbsenceModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.showAbsenceModal.failure,
      e,
    });
  }
}

export function* showAbsenceModalWatcher() {
  yield takeEvery(constants.showAbsenceModal.request, showAbsenceModal);
}

export function* showCompleteSessionModal(action: {
  type: string;
  body: boolean
}) {
  try {
    yield put({
      type: constants.showCompletedSessionModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.showCompletedSessionModal.failure,
      e,
    });
  }
}

export function* showCompleteSessionModalWatcher() {
  yield takeEvery(constants.showCompletedSessionModal.request, showCompleteSessionModal);
}

export function* completeSession(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.completeSession> = yield call(
      api.completeSession,
      action.id,
    );
    yield put({
      type: constants.completeSession.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.completeSession.failure,
      e,
    });
  }
}

export function* completeSessionWatcher() {
  yield takeEvery(constants.completeSession.request, completeSession);
}

export function* setSelectedStudentIndex(action: {
  type: string;
  body: number;
}) {
  try {
    yield put({
      type: constants.setSelectedStudentIndex.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setSelectedStudentIndex.failure,
      e,
    });
  }
}

export function* setSelectedStudentIndexWatcher() {
  yield takeEvery(constants.setSelectedStudentIndex.request, setSelectedStudentIndex);
}

export function* fetchPresenceStats() {
  try {
    const result: ReturnType<typeof api.fetchPresenceStats> = yield call(
      api.fetchPresenceStats,
    );
    yield put({
      type: constants.fetchPresenceStats.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchPresenceStats.failure,
      e,
    });
  }
}

export function* fetchPresenceStatsWatcher() {
  yield takeEvery(constants.fetchPresenceStats.request, fetchPresenceStats);
}

export function* getStudentSessionInstances(action: {
  type: string;
  values: {
    query: [];
    studentId: string;
    groupId: string;
  };
}) {
  try {
    const result: ReturnType<typeof api.getStudentSessionInstances> = yield call(
      api.getStudentSessionInstances,
      action.values,
    );
    yield put({
      type: constants.getStudentSessionInstances.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentSessionInstances.failure,
      e,
    });
  }
}

export function* getStudentSessionInstancesWatcher() {
  yield takeEvery(constants.getStudentSessionInstances.request, getStudentSessionInstances);
}

export function* getStudentAverageAbsence(action: {
  type: string;
  values: {
    query: [];
    studentId: string;
    groupId: string;
  };
}) {
  try {
    const result: ReturnType<typeof api.getStudentAverageAbsence> = yield call(
      api.getStudentAverageAbsence,
      action.values,
    );
    yield put({
      type: constants.getStudentAverageAbsence.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getStudentAverageAbsence.failure,
      e,
    });
  }
}

export function* getStudentAverageAbsenceWatcher() {
  yield takeEvery(constants.getStudentAverageAbsence.request, getStudentAverageAbsence);
}

export default function* sessionInstancesSaga() {
  yield all([
    fetchAllSessionInstancesWatcher(),
    fetchDashboardSessionInstancesWatcher(),
    fetchSessionInstanceByIdWatcher(),
    markAbsenceByIdWatcher(),
    showAbsenceModalWatcher(),
    completeSessionWatcher(),
    setSelectedStudentIndexWatcher(),
    fetchAllSessionInstancesWithoutPaginationWatcher(),
    resetStateWatcher(),
    fetchPresenceStatsWatcher(),
    getStudentSessionInstancesWatcher(),
    getStudentAverageAbsenceWatcher(),
    setPaginationIndexWatcher(),
  ]);
}
