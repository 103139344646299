/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import {
  fetchSkeleton,
  openFeedbackModal,
  setCurriculumOpen,
  setNavigationIndex,
} from '../../../store/trackPreview/actions';
import CheckpointIcon from '../../Icons/OverallViewIcons/Checkpoint';
import OneToOneIcon from '../../Icons/OverallViewIcons/OneToOne';
import BookIcon from '../../Icons/TrackOverviewIcons/Book';
import BookMarkIcon from '../../Icons/TrackOverviewIcons/BookMark';
import AnglesLeftIcon from '../../Icons/TrackOverviewIcons/AnglesLeft';
import FlagIcon from '../../Icons/TrackOverviewIcons/Flag';
import OverviewIcon from '../../Icons/TrackOverviewIcons/Overview';
import WorkshopIcon from '../../Icons/TrackOverviewIcons/Workshop';
import styles from './NavigationMenu.module.css';
import AnglesRightIcon from '../../Icons/TrackOverviewIcons/AnglesRight';
import { ExtendedNode } from '../../../store/trackPreview/types';

export const navigationKeys = {
  overview: 0,
  course: 1,
  saved: 2,
  onetoone: 3,
  checkpoint: 4,
  workshop: 5,
  reportContent: 6,
  resources: 7,
};
type Props = {
  showOverview?: boolean;
};

const NavigationMenu: React.FC<Props> = ({ showOverview }) => {
  const dispatch = useDispatch();
  const { trackId, nodeId } = useParams();
  const navigationIndex = useSelector(
    (state: { trackPreview: { navigationIndex: number } }) => state.trackPreview.navigationIndex,
  );
  useEffect(() => {
    dispatch(fetchSkeleton({ query: [], trackId }));
  }, [trackId, nodeId, dispatch]);
  const skeleton = useSelector(
    (state: {
      trackPreview: {
        skeleton: ExtendedNode;
      };
    }) => state.trackPreview.skeleton,
  );
  const curriculumState = useSelector(
    (state: {
      trackPreview: {
        curriculumState: boolean;
      };
    }) => state.trackPreview.curriculumState,
  );

  if (!skeleton?.children) return <Skeleton />;
  return (
    <div className={styles.navigationMenu}>
      {(navigationIndex === navigationKeys.course
        || navigationIndex === navigationKeys.resources) && (
        <div
          onClick={() => dispatch(setCurriculumOpen(!curriculumState))}
          className={
            !curriculumState ? styles.curriculumItemOpen : styles.curriculumItem
          }
        >
          {!curriculumState ? <AnglesLeftIcon /> : <AnglesRightIcon />}
        </div>
      )}
      {showOverview && (
        <Link to={`/track-overview/${trackId}`}>
          <div
            onClick={() => dispatch(setNavigationIndex(navigationKeys.overview))}
            className={
              navigationKeys.overview === navigationIndex
                ? styles.active
                : styles.item
            }
          >
            <OverviewIcon className={styles.itemIcon} />
            Overview
          </div>
        </Link>
      )}
      <Link
        to={`/track-overview/${trackId}/node/${skeleton?.children[0]?.children[0]?.id}/kind/${skeleton?.children[0]?.children[0]?.kind}`}
      >
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.course))}
          className={
            navigationKeys.course === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <BookIcon className={styles.itemIcon} />
          Course
        </div>
      </Link>
      <Link to={`/saved-tracks/${trackId}`}>
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.saved))}
          className={
            navigationKeys.saved === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <BookMarkIcon className={styles.itemIcon} />
          Saved
        </div>
      </Link>
      <Link
        to={`/track-resources/${trackId}/node/${skeleton?.children[0]?.children[0]?.id}/kind/${skeleton?.children[0]?.children[0]?.kind}`}
      >
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.resources))}
          className={
            navigationKeys.resources === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <FontAwesomeIcon
            className={styles.itemIcon}
            icon={faWandMagicSparkles}
          />
          Resources
        </div>
      </Link>
      <Link to={`/one-to-ones-tracks/${trackId}`}>
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.onetoone))}
          className={
            navigationKeys.onetoone === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <OneToOneIcon className={styles.itemIcon} />
          One to One
        </div>
      </Link>
      <Link to={`/checkpoints-tracks/${trackId}`}>
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.checkpoint))}
          className={
            navigationKeys.checkpoint === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <CheckpointIcon className={styles.itemIcon} />
          Checkpoint
        </div>
      </Link>
      <Link to={`/workshops-tracks/${trackId}`}>
        <div
          onClick={() => dispatch(setNavigationIndex(navigationKeys.workshop))}
          className={
            navigationKeys.workshop === navigationIndex
              ? styles.active
              : styles.item
          }
        >
          <WorkshopIcon className={styles.itemIcon} />
          Workshop
        </div>
      </Link>
      {navigationIndex === navigationKeys.course && (
        <div
          onClick={() => dispatch(openFeedbackModal(true))}
          className={styles.reportIssue}
        >
          <FlagIcon className={styles.reportIssueIcon} />
          Report Content
        </div>
      )}
    </div>
  );
};
NavigationMenu.defaultProps = {
  showOverview: true,
};
export default NavigationMenu;
