/*
 *
 * Global reducer
 *
 */
import { Reducer } from 'redux';
import { transformInstructorStudentsDataTable } from '../../shared/utils/helper';
import constants from './constants';
import { initialStateTypes } from './types';

export const initialState: initialStateTypes = {
  list: [],
  student: {},
  stats: {},
  diploma: {},
  learnings: [],
  topStudents: [],
  labPhaseAssignment: [],
  oneLabPhaseAssignment: {},
  studentAbsence: [],
  studentAbsenceNew: [],
  sessionInstances: [],
  studentLearningProgress: [],
  fetchLabPhaseAssignmentLoading: false,
  fetchOneLabPhaseAssignmentLoading: false,
  fetchLearningsLoading: false,
  getStudentAbsenceLoading: false,
  getStudentAbsenceNewLoading: false,
  fetchStudentStatsLoading: false,
  labPhase: [],
  fetchLabPhaseLoading: false,
  fetchStudentDiplomaByTrackIdLoading: false,
  fetchStudentsPerformanceByIdLoading: false,
  fetchStudentByIdLoading: false,
  fetchingStudentsListLoading: false,
  getAllSessionInstanceLoading: false,
  getLeaderBoardStudentsLoading: false,
  fetchingStudentStatsErrors: [],
  fetchingStudentsListErrors: [],
  fetchingLearningsErrors: [],
  fetchingStudentsByIdErrors: [],
  fetchingLabPhaseErrors: [],
  fetchLabPhaseAssignmentErrors: [],
  getStudentAbsenceErrors: [],
  getStudentAbsenceNewErrors: [],
  getAllSessionInstanceErrors: [],
  getLeaderBoardStudentsErrors: [],
  fetchOneLabPhaseAssignmentErrors: [],
  fetchStudentDiplomaByTrackIdErrors: [],
  fetchStudentsPerformanceByIdErrors: [],
  applications: [],
  fetchAllLabPhaseApplicationsLoading: false,
  paginationIndex: 0,
  hasMore: false,
  studentDetails: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<initialStateTypes> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getAllStudents.request:
    case constants.getStudentsDetails.request:
      return {
        ...state,
        fetchingStudentsListLoading: true,
        fetchingStudentListErrors: [],
      };

    case constants.getStudentsDetails.success:
      return {
        ...state,
        studentDetails: transformInstructorStudentsDataTable(action.data.model),
        fetchingStudentsListLoading: false,

      };
    case constants.getAllStudents.success:
      if (state.paginationIndex === 0) {
        return {
          ...state,
          loading: false,
          list: transformInstructorStudentsDataTable(action.data.model),
          paginationIndex: state.paginationIndex + 1,
          hasMore: action.data.model.length === 12,
          fetchingStudentsListLoading: false,
        };
      }
      return {
        ...state,
        list: [
          ...state.list,
          ...transformInstructorStudentsDataTable(action.data.model),
        ],
        loading: false,
        paginationIndex: state.paginationIndex + 1,
        hasMore: action.data.model.length === 12,
        fetchingStudentsListLoading: false,
      };
    case constants.setPaginationIndex.success:
      return {
        ...state,
        paginationIndex: action.data,
      };
    case constants.getAllStudents.failure:
    case constants.getStudentsDetails.failure:
      return {
        ...state,
        fetchingStudentListErrors: action.arrayErrors,
        fetchingStudentsListLoading: false,
      };

    case constants.getStudentById.request:
      return {
        ...state,
        fetchStudentByIdLoading: true,
        fetchingStudentsByIdErrors: [],
      };
    case constants.getStudentById.success:
      return {
        ...state,
        student: action.data.model,
        fetchStudentByIdLoading: false,
      };
    case constants.getStudentById.failure:
      return {
        ...state,
        fetchingStudentsByIdErrors: action.arrayErrors,
        fetchStudentByIdLoading: false,
      };
    case constants.fetchLabPhase.request:
      return {
        ...state,
        fetchLabPhaseLoading: true,
        fetchingLabPhaseErrors: [],
      };
    case constants.fetchLabPhase.success:
      return {
        ...state,
        labPhase: action.data.model,
        fetchLabPhaseLoading: false,
      };
    case constants.fetchLabPhase.failure:
      return {
        ...state,
        fetchingStudentsByIdErrors: action.arrayErrors,
        fetchStudentByIdLoading: false,
      };
    case constants.getStudentStats.request:
      return {
        ...state,
        fetchStudentStatsLoading: true,
        fetchingStudentStatsErrors: [],
      };
    case constants.getStudentStats.success:
      return {
        ...state,
        stats: action.data.model,
        fetchStudentStatsLoading: false,
      };
    case constants.getStudentStats.failure:
      return {
        ...state,
        fetchingStudentStatsErrors: action.arrayErrors,
        fetchStudentStatsLoading: false,
      };
    case constants.getLabPhaseAssignment.request:
      return {
        ...state,
        fetchLabPhaseAssignmentLoading: true,
        fetchLabPhaseAssignmentErrors: [],
      };
    case constants.getLabPhaseAssignment.success:
      return {
        ...state,
        labPhaseAssignment: action.data.model,
        fetchLabPhaseAssignmentLoading: false,
      };
    case constants.getLabPhaseAssignment.failure:
      return {
        ...state,
        fetchLabPhaseAssignmentErrors: action.arrayErrors,
        fetchLabPhaseAssignmentLoading: false,
      };
    case constants.getStudentAbsence.request:
      return {
        ...state,
        getStudentAbsenceLoading: true,
        getStudentAbsenceErrors: [],
      };
    case constants.getStudentAbsence.success:
      return {
        ...state,
        studentAbsence: action.data.model,
        getStudentAbsenceLoading: false,
      };
    case constants.getStudentAbsence.failure:
      return {
        ...state,
        getStudentAbsenceErrors: action.arrayErrors,
        getStudentAbsenceLoading: false,
      };
    case constants.getStudentLearnings.request:
      return {
        ...state,
        fetchLearningsLoading: true,
      };
    case constants.getStudentLearnings.success:
      return {
        ...state,
        learnings: action.data.model,
        fetchLearningsLoading: false,
      };
    case constants.getStudentLearnings.failure:
      return {
        ...state,
        fetchLearningsLoading: false,
        fetchingLearningsErrors: [],
      };

    case constants.getAllSessionInstance.request:
      return {
        ...state,
        getAllSessionInstanceLoading: true,
        getAllSessionInstanceErrors: [],
      };
    case constants.getAllSessionInstance.success:
      return {
        ...state,
        sessionInstances: action.data.model,
        getAllSessionInstanceLoading: false,
      };
    case constants.getAllSessionInstance.failure:
      return {
        ...state,
        getAllSessionInstanceErrors: action.arrayErrors,
        getAllSessionInstanceLoading: false,
      };
    case constants.getLabPhaseAssignmentByID.request:
      return {
        ...state,
        fetchOneLabPhaseAssignmentLoading: true,
        fetchOneLabPhaseAssignmentErrors: [],
      };
    case constants.getLabPhaseAssignmentByID.success:
      return {
        ...state,
        oneLabPhaseAssignment: action.data.model,
        fetchOneLabPhaseAssignmentLoading: false,
      };
    case constants.getLabPhaseAssignmentByID.failure:
      return {
        ...state,
        fetchOneLabPhaseAssignmentErrors: action.arrayErrors,
        fetchOneLabPhaseAssignmentLoading: false,
      };
    case constants.getLeaderBoardStudents.request:
      return {
        ...state,
        getLeaderBoardStudentsLoading: true,
        getLeaderBoardStudentsErrors: [],
      };
    case constants.getLeaderBoardStudents.success:
      return {
        ...state,
        topStudents: action.data.model,
        getLeaderBoardStudentsLoading: false,
      };
    case constants.getLeaderBoardStudents.failure:
      return {
        ...state,
        getLeaderBoardStudentsErrors: action.arrayErrors,
        getLeaderBoardStudentsLoading: false,
      };

    case constants.fetchStudentDiplomaByTrackId.request:
      return {
        ...state,
        fetchStudentDiplomaByTrackIdLoading: true,
        fetchStudentDiplomaByTrackIdErrors: [],
      };
    case constants.fetchStudentDiplomaByTrackId.success:
      return {
        ...state,
        diploma: action.data.model,
        fetchStudentDiplomaByTrackIdLoading: false,
      };
    case constants.fetchStudentDiplomaByTrackId.failure:
      return {
        ...state,
        fetchStudentDiplomaByTrackIdErrors: action.arrayErrors,
        fetchStudentDiplomaByTrackIdLoading: false,
      };
    case constants.getStudentAbsenceNew.request:
      return {
        ...state,
        getStudentAbsenceNewLoading: true,
        getStudentAbsenceNewErrors: [],
      };
    case constants.getStudentAbsenceNew.success:
      return {
        ...state,
        studentAbsenceNew: action.data.model,
        getStudentAbsenceNewLoading: false,
      };
    case constants.getStudentAbsenceNew.failure:
      return {
        ...state,
        getStudentAbsenceNewErrors: action.arrayErrors,
        getStudentAbsenceNewLoading: false,
      };

    case constants.fetchStudentsPerformanceById.request:
      return {
        ...state,
        fetchStudentsPerformanceByIdLoading: true,
        fetchStudentsPerformanceByIdErrors: [],
      };
    case constants.fetchStudentsPerformanceById.success:
      return {
        ...state,
        studentLearningProgress: action.data.model,
        fetchStudentsPerformanceByIdLoading: false,
      };
    case constants.fetchStudentsPerformanceById.failure:
      return {
        ...state,
        fetchStudentsPerformanceByIdErrors: action.arrayErrors,
        fetchStudentsPerformanceByIdLoading: false,
      };

    case constants.fetchAllLabPhaseApplications.request:
      return {
        ...state,
        fetchAllLabPhaseApplicationsLoading: true,
      };
    case constants.fetchAllLabPhaseApplications.success:
      return {
        ...state,
        fetchAllLabPhaseApplicationsLoading: false,
        applications: action.data.model,
      };

    case constants.resetState.success:
      return {
        ...state,

        student: {},
        stats: {},
        learnings: [],
        topStudents: [],
        labPhaseAssignment: [],
        studentAbsence: [],
        sessionInstances: [],
        studentLearningProgress: [],
        diploma: {},
      };
    default:
      return state;
  }
};

export { reducer as studentReducer };
