import React from 'react';

type ListRadioIconProps = {
  className?: string;
};

const ListRadioIcon: React.FC<ListRadioIconProps> = ({ className }) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 1.75C0 0.929688 0.65625 0.25 1.5 0.25C2.32031 0.25 3 0.929688 3 1.75C3 2.59375 2.32031 3.25 1.5 3.25C0.65625 3.25 0 2.59375 0 1.75ZM11.25 1C11.6484 1 12 1.35156 12 1.75C12 2.17188 11.6484 2.5 11.25 2.5H4.5C4.07812 2.5 3.75 2.17188 3.75 1.75C3.75 1.35156 4.07812 1 4.5 1H11.25ZM11.25 4.75C11.6484 4.75 12 5.10156 12 5.5C12 5.92188 11.6484 6.25 11.25 6.25H4.5C4.07812 6.25 3.75 5.92188 3.75 5.5C3.75 5.10156 4.07812 4.75 4.5 4.75H11.25ZM11.25 8.5C11.6484 8.5 12 8.85156 12 9.25C12 9.67188 11.6484 10 11.25 10H4.5C4.07812 10 3.75 9.67188 3.75 9.25C3.75 8.85156 4.07812 8.5 4.5 8.5H11.25ZM3 9.25C3 10.0938 2.32031 10.75 1.5 10.75C0.65625 10.75 0 10.0938 0 9.25C0 8.42969 0.65625 7.75 1.5 7.75C2.32031 7.75 3 8.42969 3 9.25ZM1.5 8.6875C1.17188 8.6875 0.9375 8.94531 0.9375 9.25C0.9375 9.57812 1.17188 9.8125 1.5 9.8125C1.80469 9.8125 2.0625 9.57812 2.0625 9.25C2.0625 8.94531 1.80469 8.6875 1.5 8.6875ZM0 5.5C0 4.67969 0.65625 4 1.5 4C2.32031 4 3 4.67969 3 5.5C3 6.34375 2.32031 7 1.5 7C0.65625 7 0 6.34375 0 5.5ZM1.5 6.0625C1.80469 6.0625 2.0625 5.82812 2.0625 5.5C2.0625 5.19531 1.80469 4.9375 1.5 4.9375C1.17188 4.9375 0.9375 5.19531 0.9375 5.5C0.9375 5.82812 1.17188 6.0625 1.5 6.0625Z"
      fill="#828A92"
    />
  </svg>
);

export default ListRadioIcon;

ListRadioIcon.defaultProps = {
  className: '',
};
