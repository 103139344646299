import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchAllLabPhases = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchAllLabPhases + queryString,
  );
  return result.data;
};
export const approvePhase = async (phaseAssignmentId: string, body: {}) => {
  const result = await axiosInstance.post(
    URL.instructor.labPhases.approvePhase(phaseAssignmentId),
    body,
  );

  return result.data;
};

export const fetchLabPhaseStudents = async (values: {
    query: [];
    labPhaseId: string;
  }) => {
  const { query, labPhaseId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchTopStudents(labPhaseId) + queryString,
  );

  return result.data;
};

export const fetchLabPhaseById = async (id: string) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchLabPhaseById(id),
  );
  return result.data;
};

export const fetchLabPhaseStats = async () => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchLabPhaseStats,
  );
  return result.data;
};

export const getLabPhaseAssignmentsByID = async (
  labPhaseId: string,
  studentId: string,
) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getLabPhaseAssignmentsByID(labPhaseId, studentId),
  );
  return result.data;
};

export const approveRequest = async (
  labPhaseId: string,
  studentId: string,
  body: {},
) => {
  const result = await axiosInstance.post(
    URL.instructor.labPhases.approveRequest(labPhaseId, studentId),
    body,
  );
  return result.data;
};

export const rejectRequest = async (
  labPhaseId: string,
  studentId: string,
  body: {},
) => {
  const result = await axiosInstance.post(
    URL.instructor.labPhases.rejectRequest(labPhaseId, studentId),
    { ...body },
  );

  return result.data;
};

export const fetchLabPhaseAssignmentById = async (
  labPhaseAssignmentId: string,
) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getStudentLabPhaseAssignmentsBy(labPhaseAssignmentId),
  );

  return result.data;
};
export const fetchLabPhaseAssignments = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getAllLabPhaseAssignments + queryString,
  );
  return result.data;
};
export const fetchPhaseAssignmentById = async (
  phaseId: string,
  trackId: string,
) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchPhaseAssignmentById(phaseId, trackId),
  );
  return result.data;
};

export const fetchReviews = async (phaseAssignmentId: string) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getReviewsByAssignmentId(phaseAssignmentId),
  );

  return result.data;
};
export const rejectPhase = async (phaseAssignmentId: string, body: {}) => {
  const result = await axiosInstance.post(
    URL.instructor.labPhases.addReview(phaseAssignmentId),
    body,
  );

  return result.data;
};

export const approveLabPhase = async (assignmentId: string, body: {}) => {
  const result = await axiosInstance.post(
    URL.instructor.labPhases.approveLabPhase(assignmentId),
    body,
  );

  return result.data;
};

export const fetchLabPhase = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getLabPhaseAssignments + queryString,
  );

  return result.data;
};

export const getLabPhaseAssignment = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getLabPhaseAssignment
        + queryString,
  );
  return result.data;
};

export const getLabPhaseAssignmentById = async (id: string) => {
  const result = await axiosInstance.get(
    URL.instructor.labPhases.getLabPhaseAssignmentByID(id),
  );

  return result.data;
};

export const fetchAllLabPhaseApplications = async (value: { query: [] }) => {
  const { query } = value;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.labPhases.fetchAlllabphaseApplications + queryString,
  );
  return result.data;
};

export default {
  fetchAllLabPhases,
  approvePhase,
  fetchLabPhaseStudents,
  fetchLabPhaseById,
  fetchLabPhaseStats,
  getLabPhaseAssignmentsByID,
  approveRequest,
  rejectRequest,
  fetchLabPhaseAssignmentById,
  fetchLabPhaseAssignments,
  fetchPhaseAssignmentById,
  fetchReviews,
  rejectPhase,
  approveLabPhase,
  fetchLabPhase,
  getLabPhaseAssignment,
  getLabPhaseAssignmentById,
  fetchAllLabPhaseApplications,
};
