import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/labPhases';

export function* fetchAllLabPhases(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllLabPhases> = yield call(
      api.fetchAllLabPhases,
      action.values,
    );
    yield put({
      type: constants.getLabPhases.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getLabPhases.failure,
      e,
    });
  }
}

export function* fetchAllLabPhasesWatcher() {
  yield takeEvery(constants.getLabPhases.request, fetchAllLabPhases);
}
export function* fetchLabPhaseStudents(action: {
  type: string;
  values: {
    query: [];
    labPhaseId: string;
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseStudents> = yield call(
      api.fetchLabPhaseStudents,
      action.values,
    );
    yield put({
      type: constants.fetchLabPhaseStudents.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchLabPhaseStudents.failure,
      e,
    });
  }
}
export function* fetchLabPhaseStudentsWatcher() {
  yield takeEvery(constants.fetchLabPhaseStudents.request, fetchLabPhaseStudents);
}
export function* fetchLabPhaseTopStudents(action: {
  type: string;
  values: {
    query: [];
    labPhaseId: string;
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseStudents> = yield call(
      api.fetchLabPhaseStudents,
      action.values,
    );
    yield put({
      type: constants.fetchTopThreeStudents.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchTopThreeStudents.failure,
      e,
    });
  }
}

export function* fetchLabPhaseTopStudentsWatcher() {
  yield takeEvery(constants.fetchTopThreeStudents.request, fetchLabPhaseTopStudents);
}
export function* fetchLabPhaseByIdByInstructor(action: {
  type: string;
  values: string;
}) {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseById> = yield call(
      api.fetchLabPhaseById,
      action.values,
    );
    yield put({
      type: constants.fetchLabPhaseById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchLabPhaseById.failure,
      e,
    });
  }
}

export function* fetchLabPhaseByIdByInstructorWatcher() {
  yield takeEvery(constants.fetchLabPhaseById.request, fetchLabPhaseByIdByInstructor);
}

export function* getLabPhaseAssignmentsByID(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof api.getLabPhaseAssignmentsByID> = yield call(
      api.getLabPhaseAssignmentsByID,
      action.id1,
      action.id2,
    );
    yield put({
      type: constants.getLabPhaseAssignmentsByID.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getLabPhaseAssignmentsByID.failure,
      e,
    });
  }
}

export function* getLabPhaseAssignmentsByIDWatcher() {
  yield takeEvery(constants.getLabPhaseAssignmentsByID.request, getLabPhaseAssignmentsByID);
}

export function* fetchLabPhaseStats() {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseStats> = yield call(
      api.fetchLabPhaseStats,
    );
    yield put({
      type: constants.fetchLabPhaseStats.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchLabPhaseStats.failure,
      e,
    });
  }
}

export function* fetchLabPhaseStatsWatcher() {
  yield takeEvery(constants.fetchLabPhaseStats.request, fetchLabPhaseStats);
}

export function* openAcceptModal() {
  try {
    yield put({
      type: constants.isApprovedModalOpen.success,
      data: true,
    });
  } catch (e) {
    yield put({
      type: constants.isApprovedModalOpen.failure,
      e,
    });
  }
}

export function* openAcceptModalWatcher() {
  yield takeEvery(constants.isApprovedModalOpen.request, openAcceptModal);
}

export function* closeAcceptModal() {
  try {
    yield put({
      type: constants.setIsRefuseModalClose.success,
      data: false,
    });
  } catch (e) {
    yield put({
      type: constants.setIsRefuseModalClose.failure,
      e,
    });
  }
}

export function* closeAcceptModalWatcher() {
  yield takeEvery(constants.setIsRefuseModalClose.request, closeAcceptModal);
}

export function* setIsRefuseModalOpen() {
  try {
    yield put({
      type: constants.isRefuseModalOpen.success,
      data: true,
    });
  } catch (e) {
    yield put({
      type: constants.isRefuseModalOpen.failure,
      e,
    });
  }
}

export function* setIsRefuseModalOpenWatcher() {
  yield takeEvery(constants.isRefuseModalOpen.request, setIsRefuseModalOpen);
}

export function* openApprovedModal() {
  try {
    yield put({
      type: constants.isApprovedModalOpen.success,
      data: true,
    });
  } catch (e) {
    yield put({
      type: constants.isApprovedModalOpen.failure,
      e,
    });
  }
}

export function* openApprovedModalWatcher() {
  yield takeEvery(constants.isApprovedModalOpen.request, openApprovedModal);
}

export function* closeApprovedModal() {
  try {
    yield put({
      type: constants.setIsApprovedModalClose.success,
      data: false,
    });
  } catch (e) {
    yield put({
      type: constants.setIsApprovedModalClose.failure,
      e,
    });
  }
}

export function* closeApprovedModalWatcher() {
  yield takeEvery(constants.setIsApprovedModalClose.request, closeApprovedModal);
}

export function* acceptRequest(action: {
  type: string;
  id1: string;
  id2: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof api.approveRequest> = yield call(
      api.approveRequest,
      action.id1,
      action.id2,
      action.body,
    );
    yield put({
      type: constants.acceptRequest.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.acceptRequest.failure,
      e,
    });
  }
}

export function* acceptRequestWatcher() {
  yield takeEvery(constants.acceptRequest.request, acceptRequest);
}

export function* rejectRequest(action: {
  type: string;
  id1: string;
  id2: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.rejectRequest> = yield call(
      api.rejectRequest,
      action.id1,
      action.id2,
      action.values,
    );
    yield put({
      type: constants.rejectRequest.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.rejectRequest.failure,
      e,
    });
  }
}

export function* rejectRequestWatcher() {
  yield takeEvery(constants.rejectRequest.request, rejectRequest);
}

export function* fetchLabPhaseAssignmentById(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseAssignmentById> = yield call(
      api.fetchLabPhaseAssignmentById,
      action.id,
    );
    yield put({
      type: constants.fetchLabPhaseAssignmentById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchLabPhaseAssignmentById.failure,
      e,
    });
  }
}

export function* fetchLabPhaseAssignmentByIdWatcher() {
  yield takeEvery(constants.fetchLabPhaseAssignmentById.request, fetchLabPhaseAssignmentById);
}

export function* fetchAllLabPhaseAssignments(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchLabPhaseAssignments> = yield call(
      api.fetchLabPhaseAssignments,
      action.values,
    );
    yield put({
      type: constants.fetchAllLabPhaseAssignments.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllLabPhaseAssignments.failure,
      e,
    });
  }
}

export function* fetchAllLabPhaseAssignmentsWatcher() {
  yield takeEvery(constants.fetchAllLabPhaseAssignments.request, fetchAllLabPhaseAssignments);
}

export function* fetchPhaseAssignmentById(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof api.fetchPhaseAssignmentById> = yield call(
      api.fetchPhaseAssignmentById,
      action.id1,
      action.id2,
    );
    yield put({
      type: constants.fetchPhaseAssignmentById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchPhaseAssignmentById.failure,
      e,
    });
  }
}

export function* fetchPhaseAssignmentByIdWatcher() {
  yield takeEvery(constants.fetchPhaseAssignmentById.request, fetchPhaseAssignmentById);
}

export function* approveLabPhase(action: {
  type: string;
  id: string;
  body: {}
}) {
  try {
    const result: ReturnType<typeof api.approveLabPhase> = yield call(
      api.approveLabPhase,
      action.id,
      action.body,
    );
    yield put({
      type: constants.approveLabPhase.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.approveLabPhase.failure,
      e,
    });
  }
}

export function* approveLabPhaseWatcher() {
  yield takeEvery(constants.approveLabPhase.request, approveLabPhase);
}

export function* openFinalModal() {
  try {
    yield put({
      type: constants.openFinalModal.success,
      data: true,
    });
  } catch (e) {
    yield put({
      type: constants.openFinalModal.failure,
    });
  }
}

export function* openFinalModalWatcher() {
  yield takeEvery(constants.openFinalModal.request, openFinalModal);
}

export function* fetchReviews(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.fetchReviews> = yield call(
      api.fetchReviews,
      action.id,
    );
    yield put({
      type: constants.fetchReviews.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchReviews.failure,
      e,
    });
  }
}

export function* fetchReviewsWatcher() {
  yield takeEvery(constants.fetchReviews.request, fetchReviews);
}

export function* rejectPhase(action: {
  type: string;
  id: string,
  body: {}
}) {
  try {
    const result: ReturnType<typeof api.rejectPhase> = yield call(
      api.rejectPhase,
      action.id,
      action.body,
    );
    yield put({
      type: constants.rejectPhase.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.rejectPhase.failure,
      e,
    });
  }
}

export function* rejectPhaseWatcher() {
  yield takeEvery(constants.rejectPhase.request, rejectPhase);
}

export function* setShowRejectionModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setShowRejectionModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setShowRejectionModal.failure,
      e,
    });
  }
}

export function* setShowRejectionModalWatcher() {
  yield takeEvery(constants.setShowRejectionModal.request, setShowRejectionModal);
}

export function* setApprovedModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.setApprovedModalClose.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.setApprovedModalClose.failure,
      e,
    });
  }
}

export function* setApprovedModalWatcher() {
  yield takeEvery(constants.setApprovedModalClose.request, setApprovedModal);
}

export function* approvePhase(action: {
  type: string;
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof api.approvePhase> = yield call(
      api.approvePhase,
      action.id,
      action.body,
    );
    yield put({
      type: constants.approvePhase.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.approvePhase.failure,
      e,
    });
  }
}

export function* approvePhaseWatcher() {
  yield takeEvery(constants.approvePhase.request, approvePhase);
}

export default function* labPhasesSaga() {
  yield all([
    fetchAllLabPhasesWatcher(),
    fetchLabPhaseStudentsWatcher(),
    fetchLabPhaseByIdByInstructorWatcher(),
    fetchLabPhaseTopStudentsWatcher(),
    fetchLabPhaseStatsWatcher(),
    openAcceptModalWatcher(),
    closeAcceptModalWatcher(),
    openApprovedModalWatcher(),
    closeApprovedModalWatcher(),
    getLabPhaseAssignmentsByIDWatcher(),
    acceptRequestWatcher(),
    fetchLabPhaseAssignmentByIdWatcher(),
    rejectRequestWatcher(),
    fetchAllLabPhaseAssignmentsWatcher(),
    fetchPhaseAssignmentByIdWatcher(),
    approveLabPhaseWatcher(),
    openFinalModalWatcher(),
    fetchPhaseAssignmentByIdWatcher(),
    fetchReviewsWatcher(),
    rejectPhaseWatcher(),
    approvePhaseWatcher(),
    setApprovedModalWatcher(),
    setIsRefuseModalOpenWatcher(),
  ]);
}
