/* eslint-disable no-param-reassign */
/*
 *
 * Global reducer
 *
 */
import { Reducer } from 'redux';
import constants from './constants';
import { globalInitialStateTypes } from './types';

export const initialState: globalInitialStateTypes = {
  data: [],
  mustRefreshAccessToken: false,
  refreshTokenLoading: false,
  refreshTokenErrors: [],
};

// eslint-disable-next-line default-param-last, consistent-return
const reducer: Reducer<globalInitialStateTypes | undefined> = (state = initialState, action) => {
  switch (action?.type) {
    case constants.refreshAccessToken.request:
      return {
        ...state,
        mustRefreshAccessToken: false,
        refreshTokenLoading: true,
        refreshTokenErrors: [],
      };
    case constants.refreshAccessToken.success:
        state!.refreshTokenLoading = false;
      localStorage.setItem('token', action?.data.model.accessToken);
      localStorage.setItem('expires', action?.data.model.expires);
      window.location.reload();
      break;
    case constants.refreshAccessToken.failure:
      localStorage.removeItem('token');
      state!.refreshTokenLoading = false;
      state!.refreshTokenErrors = action?.arrayErrors;
      break;
    case constants.runRefreshToken.success: {
      return {
        ...state,
        mustRefreshAccessToken: true,
        refreshTokenLoading: false,
        refreshTokenErrors: [],
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as globalReducer };
