import React from 'react';
import GmcLoader from '../../Icons/GmcLoader';
import styles from './loader.module.css';

interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => (
  <div className={styles.centerWrapper}>
    <div className={styles.loader}>
      <GmcLoader />
    </div>
  </div>
);

export default Loader;
