/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/FACETOFACEONETOONEDETAILS/';
export default {
  fetchOneToOneById: generateActionTypes(root, 'FETCH_ONE_TO_ONE_BY_ID'),
  startOneToOne: generateActionTypes(root, 'START_ONE_TO_ONE'),
  setQuestionIndex: generateActionTypes(root, 'SET_QUESTION_INDEX'),
  updateStudentAnswer: generateActionTypes(root, 'UPDATE_STUDENT_ANSWER'),
  setShowTimeIsUpModal: generateActionTypes(root, 'SET_SHOW_TIME_IS_UP_MODAL'),
  setShowInstructorModal: generateActionTypes(
    root,
    'SET_SHOW_INSTRUCTOR_MODAL',
  ),
  handleBackFromFeedback: generateActionTypes(
    root,
    'HANDLE_BACK_FROM_FEEDBACK',
  ),
  resetOneToOneSettings: generateActionTypes(root, 'REST_ONE_TO_ONE_SETTINGS'),
  sendInstructorFeedback: generateActionTypes(root, 'SEND_INSTRUCTOR_FEEDBACK'),
  setShowModal: generateActionTypes(root, 'SET_SHOW_MODAL'),

  setShowInstructorFeedback: generateActionTypes(
    root,
    'SET_SHOW_INSTRUCTOR_FEEDBACK',
  ),
};
