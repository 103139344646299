import React from 'react';

import { Avatar, Progress } from 'antd';
import styles from './CircularProgressBarWithAvatar.module.css';

interface ICircularProgressBarWithAvatarProps {
  nps: number;
  strokeWidth: number;
  avatarSize: number;
  image: string
}

const CircularProgressBarWithAvatar: React.FC<
  ICircularProgressBarWithAvatarProps
> = ({
  nps, strokeWidth, avatarSize, image,
}) => {
  const getStrokeColor = (_nps: number) => {
    if (_nps < 40 || Number.isNaN(_nps)) return { color: '#F14C4C' };
    if (_nps > 39 && _nps < 60) return { color: '#F2D21F' };
    if (_nps > 59) return { color: '#4CCD59' };
    return { color: '#4CCD59' };
  };
  const getPercentageClassName = (_nps: number) => {
    if (_nps < 40 || Number.isNaN(_nps)) return styles.red;
    if (_nps > 39 && _nps < 60) return styles.yellow;
    if (_nps > 59) return styles.green;
    return styles.green;
  };
  return (
    <div className={styles.circularProgressBarWithAvatar}>
      <Progress
        percent={nps}
        strokeColor={getStrokeColor(nps)}
        strokeWidth={strokeWidth}
        // eslint-disable-next-line react/no-unstable-nested-components
        format={() => <Avatar src={image} size={avatarSize} />}
        type="dashboard"
        gapPosition="bottom"
      />
      <div className={styles.score}>
        <p className={`${styles.currentScore} ${getPercentageClassName(nps)}`}>
          {nps.toFixed(0)}
&nbsp;
        </p>
        {' '}
        <p className={styles.totalScore}> / 100</p>
      </div>
    </div>
  );
};

export default CircularProgressBarWithAvatar;
