import { Profile } from '@gomycode/types-utils/Types/types';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getProfileByToken = async () => {
  const result = await axiosInstance.get(
    URL.account.profiles.profileHandler,
  );
  return result.data;
};

export const updateProfile = async (body: Profile) => {
  const result = await axiosInstance.put(
    URL.account.profiles.profileHandler,
    { ...body },
  );
  return result.data;
};

export const getProfilesList = async (email: string) => {
  const result = await axiosInstance.get(
    URL.account.profiles.getProfiles(email),
  );
  return result.data;
};

export const switchProfile = async (profileId: string) => {
  const result = await axiosInstance.put(
    URL.account.profiles.switchProfile(profileId),
  );
  return result.data;
};

export default {
  getProfileByToken,
  updateProfile,
};
