import React from 'react';

type AnglesLeftIconProps = {
  className?: string;
};

const AnglesLeftIcon: React.FC<AnglesLeftIconProps> = ({ className }) => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.40625 7L7.6875 11.3125C8.09375 11.6875 8.09375 12.3438 7.6875 12.7188C7.5 12.9062 7.25 13 7 13C6.71875 13 6.46875 12.9062 6.28125 12.7188L1.28125 7.71875C0.875 7.34375 0.875 6.6875 1.28125 6.3125L6.28125 1.3125C6.65625 0.90625 7.3125 0.90625 7.6875 1.3125C8.09375 1.6875 8.09375 2.34375 7.6875 2.71875L3.40625 7ZM9.40625 7L13.6875 11.3125C14.0938 11.6875 14.0938 12.3438 13.6875 12.7188C13.5 12.9062 13.25 13 13 13C12.7188 13 12.4688 12.9062 12.2812 12.7188L7.28125 7.71875C6.875 7.34375 6.875 6.6875 7.28125 6.3125L12.2812 1.3125C12.6562 0.90625 13.3125 0.90625 13.6875 1.3125C14.0938 1.6875 14.0938 2.34375 13.6875 2.71875L9.40625 7Z"
      fill="#176FFF"
    />
  </svg>
);

export default AnglesLeftIcon;

AnglesLeftIcon.defaultProps = {
  className: '',
};
