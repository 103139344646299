import { Reducer } from 'redux';
import { CastSkill } from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { getCurrentLearningStyleId, parseSkill } from './helper';
import { ITEM_KIND, TrackPreviewState } from './types';

const initialState: TrackPreviewState = {
  navigationIndex: 0,
  activeStudents: [],
  fetchSkeletonLoading: false,
  getActiveStudentsLoading: false,
  fetchSkeletonErrors: [],
  getActiveStudentsErrors: [],
  skeleton: {
    id: '',
    name: '',
    description: '',
    estimatedDuration: 0,
    kind: 0,
    state: 0,
    flags: 0,
    children: [],
    students: [],
  },
  allActiveStudents: [],
  skill: {},
  event: { workshop: {}, oneToOne: {}, checkpoint: {} },
  parsedSkill: CastSkill(),
  getItemErrors: [],
  getItemLoading: false,
  currentLearningStyleId: '',
  patchBookMarkErrors: [],
  patchBookMarkLoading: false,
  showBookMarkAlert: false,
  getBookMarkedNodesLoading: false,
  getBookMarkedNodesErrors: [],
  bookMarkedNodes: [],
  curriculumState: false,
  feedbackModal: false,
  postSkillFeedbackLoading: false,
  postSkillFeedbackErrors: [],
  showSuccessModal: false,
  patchResourcesLoading: false,
  patchResourcesErrors: [],
  getResourcesLoading: false,
  getResourcesErrors: [],
  instructorResources: [],
  shouldRefetshResource: false,
  addModal: false,
  editModal: false,
  failedNotification: {},
  instructorResourcesBySuperSkill: [],
  getTrackStatsLoading: false,
  getTrackStatsErrors: [],
  trackStats: {
    skills: 0,
    learningStyles: 0,
    assessments: 0,
    quizzes: 0,
    reorderChallenges: 0,
    fillInTheBlanks: 0,
    codeCompilings: 0,
    checkpoints: 0,
  },
  refetchBookMarked: false,
  unsavedModal: false,
  getOrderedCheckpointsLoading: false,
  getOrderedCheckpointsErrors: [],
  getOrderedWorkshopsLoading: false,
  getOrderedWorkshopsErrors: [],
  getOrderedOneToOnesLoading: false,
  getOrderedOneToOnesErrors: [],
  orderedCheckpoints: [],
  orderedOneToOnes: [],
  orderedWorshops: [],
  paginationIndex: 0,
  hasMore: false,
  failedNotificationResource: {},
  showCurriculum: true,
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<TrackPreviewState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.setNavigationIndex.success:
      return {
        ...state,
        navigationIndex: action.data,
      };
    case constants.fetchSkeleton.request:
      return {
        ...state,
        fetchSkeletonLoading: true,
      };

    case constants.fetchSkeleton.success:
      return {
        ...state,
        fetchSkeletonLoading: false,
        skeleton: action.data.model,
        showBookMarkAlert: false,
      };
    case constants.fetchSkeleton.failure:
      return {
        ...state,
        fetchSkeletonLoading: false,
        fetchSkeletonErrors: action.objectErrors,
      };

    case constants.getActiveStudents.request:
      return {
        ...state,
        getActiveStudentsLoading: true,
      };

    case constants.getActiveStudents.success:
      return {
        ...state,
        getActiveStudentsLoading: false,

        activeStudents: [...state.activeStudents, ...action.data.model],
      };
    case constants.getActiveStudents.failure:
      return {
        ...state,
        getActiveStudentsLoading: false,
        getActiveStudentsErrors: action.objectErrors,
      };

    case constants.getItem.request:
      return {
        ...state,
        getItemErrors: [],
        skill: {},
        event: { workshop: {}, oneToOne: {}, checkpoint: {} },
        getItemLoading: true,
      };

    case constants.getItem.success:
      switch (action.request.kind) {
        case ITEM_KIND.WORKSHOP.index:
          return {
            ...state,
            event: { ...state.event, workshop: action.data.model },
          };

        case ITEM_KIND.CHECKPOINT.index:
          return {
            ...state,
            event: { ...state.event, checkpoint: action.data.model },
          };
        case ITEM_KIND.ONE_TO_ONE.index:
          return {
            ...state,
            event: { ...state.event, oneToOne: action.data.model },
          };
        default:
          return {
            ...state,
            parsedSkill: parseSkill(action.data.model.skill),
            skill: parseSkill(action.data.model.skill),
            currentLearningStyleId: getCurrentLearningStyleId(
              state.parsedSkill,
            ),
            currentPageIndex: 0,
          };
      }

    case constants.getItem.failure:
      return {
        ...state,
        getItemErrors: action.arrayErrors,
        getItemLoading: false,
      };

    case constants.patchBookMark.request:
      return {
        ...state,
        patchBookMarkErrors: [],
        patchBookMarkLoading: true,
        showBookMarkAlert: false,
        refetchBookMarked: false,
      };
    case constants.patchBookMark.success:
      return {
        ...state,
        patchBookMarkErrors: [],
        patchBookMarkLoading: false,
        showBookMarkAlert: !!action.data.model.savedNodes.find(
          (el: string) => el.substring(0, el.indexOf('|')) === action.request.nodeId,
        ),
        refetchBookMarked: true,
        unsavedModal: false,
      };
    case constants.patchBookMark.failure:
      return {
        ...state,
        patchBookMarkErrors: action.arrayErrors,
        patchBookMarkLoading: false,
      };

    case constants.getBookMarkedNodes.request:
      return {
        ...state,
        getBookMarkedNodesErrors: [],
        getBookMarkedNodesLoading: true,
      };
    case constants.getBookMarkedNodes.success:
      return {
        ...state,
        getBookMarkedNodesErrors: [],
        getBookMarkedNodesLoading: false,
        bookMarkedNodes: action.data.model,
      };
    case constants.getBookMarkedNodes.failure:
      return {
        ...state,
        getBookMarkedNodesErrors: action.arrayErrors,
        getBookMarkedNodesLoading: false,
      };
    case constants.setCurriculumOpen.success:
      return {
        ...state,

        curriculumState: action.data,
      };

    case constants.openFeedbackModal.success:
      return {
        ...state,
        feedbackModal: action.data,
        showSuccessModal: false,
      };
    case constants.postSkillFeedback.request:
      return {
        ...state,
        postSkillFeedbackLoading: true,
      };
    case constants.postSkillFeedback.success:
      return {
        ...state,
        postSkillFeedbackLoading: false,
        showSuccessModal: true,
      };
    case constants.postSkillFeedback.failure:
      return {
        ...state,
        postSkillFeedbackLoading: false,
        postSkillFeedbackErrors: action.arrayErrors,
      };

    case constants.getResources.request:
      return {
        ...state,
        getResourcesLoading: true,

        getResourcesErrors: [],
      };
    case constants.getResources.success:
      return {
        ...state,
        getResourcesLoading: false,
        getResourcesErrors: [],

        instructorResourcesBySuperSkill: action.data.model
          ? action.data.model
          : [],
        instructorResources: action.data.model
          ? action.data.model?.filter(
            (el: { skillId: string }) => el.skillId === action.request.nodeId,
          )
          : [],
      };
    case constants.getResources.failure:
      return {
        ...state,
        getResourcesLoading: false,
        getResourcesErrors: action.arrayErrors,
      };

    case constants.patchResourcesFromTrack.request:
    case constants.patchResources.request:
      return {
        ...state,
        patchResourcesLoading: true,
        patchResourcesErrors: [],
        shouldRefetshResource: false,
      };

    case constants.patchResourcesFromTrack.success:
    case constants.patchResources.success:
      return {
        ...state,
        patchResourcesLoading: false,
        patchResourcesErrors: [],
        shouldRefetshResource: true,
        addModal: false,
        editModal: false,
      };
    case constants.patchResourcesFromTrack.failure:
      return {
        ...state,
        patchResourcesLoading: false,
        patchResourcesErrors: action.arrayErrors,

        failedNotificationResource: {
          show: true,
          message: action.e.response.data.model.other[0],
        },
      };
    case constants.patchResources.failure:
      return {
        ...state,
        patchResourcesLoading: false,
        patchResourcesErrors: action.arrayErrors,
        failedNotification: {
          show: true,
          message: action.e.response.data.model.other[0],
        },
      };
    case constants.setAddModalOpen.success:
      return {
        ...state,
        addModal: action.data,
      };
    case constants.setEditModalOpen.success:
      return {
        ...state,
        editModal: action.data,
      };

    case constants.getTrackStats.request:
      return {
        ...state,
        getTrackStatsLoading: true,
      };
    case constants.getTrackStats.success:
      return {
        ...state,
        getTrackStatsLoading: false,
        trackStats: action.data.model,
      };
    case constants.getTrackStats.failure:
      return {
        ...state,
        getTrackStatsLoading: false,
        getTrackStatsErrors: action.arrayErrors,
      };
    case constants.setUnsavedModalOpen.success:
      return {
        ...state,
        unsavedModal: action.data,
      };

    case constants.getOrderedOneToOnes.request:
      return {
        ...state,
        getOrderedOneToOnesLoading: true,
      };

    case constants.getOrderedOneToOnes.success:
      return {
        ...state,
        getOrderedOneToOnesLoading: false,
        orderedOneToOnes: action.data.model,
      };

    case constants.getOrderedOneToOnes.failure:
      return {
        ...state,
        getOrderedOneToOnesLoading: false,
        getOrderedOneToOnesErrors: action.arrayErrors,
      };

    case constants.getOrderedCheckpoints.request:
      return {
        ...state,
        getOrderedCheckpointsLoading: true,
      };

    case constants.getOrderedCheckpoints.success:
      return {
        ...state,
        getOrderedCheckpointsLoading: false,
        orderedCheckpoints: action.data.model,
      };

    case constants.getOrderedCheckpoints.failure:
      return {
        ...state,
        getOrderedCheckpointsLoading: false,
        getOrderedCheckpointsErrors: action.arrayErrors,
      };

    case constants.getOrderedWorkshops.request:
      return {
        ...state,
        getOrderedWorkshopsLoading: true,
      };

    case constants.getOrderedWorkshops.success:
      return {
        ...state,
        getOrderedWorkshopsLoading: false,
        orderedWorshops: action.data.model,
      };

    case constants.getOrderedWorkshops.failure:
      return {
        ...state,
        getOrderedWorkshopsLoading: false,
        getOrderedWorkshopsErrors: action.arrayErrors,
      };

    case constants.resetNotification.success:
      return {
        ...state,
        failedNotification: { ...state.failedNotification, show: false },
        patchResourcesErrors: [],
      };

    case constants.setShowCurriculum.success:
      return {
        ...state,
        showCurriculum: action.data,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as trackPreviewReducer };
