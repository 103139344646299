import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/pay/paycheck';

export function* fetchInstructorEarnings(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.getInstructorEarnings> = yield call(api.getInstructorEarnings, action.id);
    yield put({
      type: constants.fetchInstructorEarnings.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchInstructorEarnings.failure,
      e,
    });
  }
}

export function* fetchInstructorEarningsWatcher() {
  yield takeEvery(constants.fetchInstructorEarnings.request, fetchInstructorEarnings);
}

export function* fetchInstructorPayCheck(action: {
  type: string;
  id: string;
}) {
  try {
    const result: ReturnType<typeof api.getPayCheckById> = yield call(api.getPayCheckById, action.id);
    yield put({
      type: constants.fetchInstructorPayCheckById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchInstructorPayCheckById.failure,
      e,
    });
  }
}

export function* fetchInstructorPayCheckWatcher() {
  yield takeEvery(constants.fetchInstructorPayCheckById.request, fetchInstructorPayCheck);
}

export function* fetchInstructorEarningSession(action: {
  type: string;
  id1: string;
  id2: string;
}) {
  try {
    const result: ReturnType<typeof api.getEarningsSession> = yield call(api.getEarningsSession, action.id1, action.id2);
    yield put({
      type: constants.fetchInstructorEarningSession.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchInstructorEarningSession.failure,
      e,
    });
  }
}

export function* fetchInstructorEarningSessionWatcher() {
  yield takeEvery(constants.fetchInstructorEarningSession.request, fetchInstructorEarningSession);
}

export default function* EarningsSaga() {
  yield all([
    fetchInstructorEarningsWatcher(),
    fetchInstructorEarningSessionWatcher(),
    fetchInstructorPayCheckWatcher(),
  ]);
}
