import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Mixpanel from 'mixpanel-browser';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';
import configureStore from './configureStore';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { registerErrorInterceptors } from './shared/utils/error-interceptors';
import { AuthProvider } from './AuthProvider';
import { AuthErrorProvider } from './AuthErrorProvider';
import URLActionInterceptor from './URLActionInterceptor';

// We use hash history because this example is going to be hosted statically.
// Normally you would use browser history.
const history = createBrowserHistory();
Mixpanel.init(process.env.REACT_APP_mixpanel__Key || '', {
  api_host: 'https://api.mixpanel.com',
});

registerErrorInterceptors();

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(history, initialState);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
if (process.env.REACT_APP_Sentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_Sentry,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://gomycode-m6.sentry.io'],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
root.render(
  <BrowserRouter>
    <AuthErrorProvider>
      <AuthProvider>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <URLActionInterceptor>
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: 'Inter',
                  },
                }}
              >
                <App />
              </ConfigProvider>
            </URLActionInterceptor>
          </QueryClientProvider>
        </Provider>
      </AuthProvider>
    </AuthErrorProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
