/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearFilters,
  encodeArchival,
  encodeLimit,
  encodeSkip,
  encodeUnattachedFilter,
  setCustomfilter,
} from '@gomycode/design-system';
import mixpanel from 'mixpanel-browser';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  Location, PresenceStats, Profile,
} from '@gomycode/types-utils/Types/types';
import npsActions from '../../store/NPS/actions';
import styles from './Dashboard.module.css';
import NPSCard from './NPSCard';
import { NPSState } from '../../store/NPS/types';
import statsActions from '../../store/stats/actions';
import ResponsiveNPSCard from './ResponsiveNPSCard';
import GraduatedStudentsCard from './GraduatedStudentsCard';
import TotalStudentsCard from './TotalStudentsCard';
import TrackCard from './TrackCard';
import GuildCard from './GuildCard';
import ResponsiveTrackCard from './ResponsiveTrackCard';
import TasksCard from './TasksCard';
import StudentsListCard from './StudentsListCard';
import OnlineSessionCard from './OnlineSessionCard';
import groupsActions from '../../store/groups/actions';
import sessionInstancesActions from '../../store/sessionInstance/actions';
import onlineActions from '../../store/online/actions';
import { getAllStudents, setPaginationIndex } from '../../store/Student/actions';
import { SessionInstanceApiReponseItem } from '../../store/sessionInstance/types';
import {
  encodeSessionInstancesFields,
  fetchOnlineSessionFields,
  fetchStudents,
  mergeSessionInstancesByGroup,
} from './helpers';
import { fetchDiscordURLs } from '../../store/profile/actions';
import { encodeFields } from '../../shared/utils/queryHelper';
import { OnlineSessions } from '../../store/online/types';
import ResponsiveOnlineCard from './ResponsiveOnlineCard';
import ResponsiveTasksCard from './ResponsiveTasksCard';
import AveragePresenceCard from './AveragePresenceCard';
import TotalGuildsCard from './TotalGuildsCard';
import { ActiveGroupsApiResponse, DashboardCustomGuildsMap } from '../../store/groups/types';
import { InstructorStatsApiResponse } from '../../store/stats/types';
import { StudentListApiResponse } from '../../store/Student/types';
import SlackImage from '../../shared/images/Slack.png';

type NPSPeriod = 'LAST 30 DAYS' | 'Overall';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedNPSPeriod, setSelectedNPSPeriod] = useState<NPSPeriod>('LAST 30 DAYS');

  const date = new Date();

  const thisdate = date.toISOString().split('T')[0];
  const dateMinus = (n: number) => {
    const today = new Date();
    today.setDate(today.getDate() - n);

    return today.toISOString().split('T')[0];
  };
  useEffect(() => {
    mixpanel.track('INSTRUCTOR_APP_Dashboard_rendered');
  }, []);

  useEffect(() => {
    dispatch(statsActions.fetchInstructorStats());
    dispatch(
      sessionInstancesActions.fetchPresenceStats(),
    );
    dispatch(
      sessionInstancesActions.fetchDashboardSessionInstances({
        query: [encodeFields(encodeSessionInstancesFields)],
      }),
    );
    dispatch(groupsActions.fetchAllActiveGroups({ query: [encodeArchival(false)] }));
    dispatch(
      onlineActions.fetchActiveOnlineSessionsV2({
        query: [encodeFields(fetchOnlineSessionFields)],
      }),
    );
    dispatch(fetchDiscordURLs());
  }, [dispatch]);

  const profile = useSelector(
    (state: { profile: { profile: Profile } }) => state.profile.profile,
  );

  const groups = useSelector(
    (state: { groups: { activeGroups: ActiveGroupsApiResponse[] } }) => state.groups.activeGroups,
  );

  const sessionInstances = useSelector(
    (state: {
      sessionInstances: {
        dashboardSessionInstances: SessionInstanceApiReponseItem[];
      };
    }) => state.sessionInstances.dashboardSessionInstances,
  );

  const presenceStats = useSelector(
    (state: {
      sessionInstances: {
        presenceStats: PresenceStats;
      };
    }) => state.sessionInstances.presenceStats,
  );

  /**
   * @Info Computing the instructor's average presence
   */
  const instructorTotalSessions = presenceStats.numberOfAbsentInstructors + presenceStats.numberOfPresentInstructors;
  const percentage = instructorTotalSessions ? `${((presenceStats.numberOfPresentInstructors / instructorTotalSessions) * 100).toFixed(0)}%` : 'N/A';
  /** *********** */

  const onlineSessions = useSelector(
    (state: { online: { onlineSessionsV2: OnlineSessions[] } }) => state.online.onlineSessionsV2,
  );

  const onlineSession = onlineSessions ? onlineSessions[0] : undefined;

  const location = useSelector(
    (state: { profile: { location: Location } }) => state.profile.location,
  );

  const transformedSessionInstances = mergeSessionInstancesByGroup(sessionInstances);

  const instructor = useSelector((state: {stats: {instructor: InstructorStatsApiResponse[]}}) => state.stats.instructor);

  const trackRecords = instructor
    .map((el) => el.instructorTrackRecord)
    .filter(
      (obj, index: number, self) => index === self.findIndex((item: { id: string }) => item.id === obj.id),
    );
  const activeStudents = groups.reduce(
    (a: number, record) => a + record.studentCount,
    0,
  );
  const graduatedStudents = trackRecords.reduce(
    (a: number, record) => a + record.numberOfGraduates,
    0,
  );
  const nps = useSelector((state: { nps: NPSState }) => state.nps);

  /*
   Tracks and guilds listing logic
   */

  const Guilds: DashboardCustomGuildsMap = {};
  groups.forEach((entry) => {
    if (Guilds[entry.track.id]) {
      Guilds[entry.track.id].studentCount = entry.studentCount + Guilds[entry.track.id].studentCount;
      Guilds[entry.track.id].records = [
        ...Guilds[entry.track.id].records,
        {
          ...entry.groupRecord,
          ...entry.group,
          ...entry.opening,
          opening: entry.opening,
          skillsCount: entry.track.skillsCount,
        },
      ];
    } else {
      Guilds[entry.track.id] = {
        track: { ...entry.track },
        opening: { ...entry.opening },
        studentCount: entry.studentCount,
        records: [
          {
            ...entry.groupRecord,
            ...entry.group,
            ...entry.opening,
            skillsCount: entry.track.skillsCount,
            opening: entry.opening,
          },
        ],
      };
    }
  });

  /** ************** */

  const allStudents = useSelector((state: {student: { list: StudentListApiResponse[]}}) => state.student.list);

  useEffect(() => {
    dispatch(setPaginationIndex(0));
    dispatch(
      getAllStudents({
        query: [encodeLimit(12),
          encodeSkip(0),
          encodeArchival(false),
          encodeFields(fetchStudents),
          { key: 'expirationDate', value: moment().toISOString(), operator: 'gt' },
        ],

      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (window.innerWidth < 992) {
      dispatch(
        npsActions.getNPS({
          query: [
            encodeUnattachedFilter(
              'createdAt',
              `${dateMinus(30)},${thisdate}`,
              'between',
            ),
          ],
        }),
      );
      return;
    }
    if (selectedNPSPeriod === 'LAST 30 DAYS') {
      dispatch(
        npsActions.getNPS({
          query: [
            encodeUnattachedFilter(
              'createdAt',
              `${dateMinus(30)},${thisdate}`,
              'between',
            ),
          ],
        }),
      );
    } else if (selectedNPSPeriod === 'Overall') {
      dispatch(npsActions.getNPS({ query: [] }));
    }
  }, [dispatch, thisdate, selectedNPSPeriod]);

  return (
    <div className={styles.dashboard}>
      <Helmet>
        <title>Dashboard</title>
        <meta name="Dashboard" content="Dashboard" />
      </Helmet>
      {/* Laptotp dashboard layout */}
      <Col xs={0} sm={0} md={0} lg={{ span: 23 }}>
        <div className={styles.slackCard}>
          <div className={styles.rightSide}>
            <img src={SlackImage} alt="slack logo" />
            <div className={styles.texts}>
              <p className={styles.joinSlack}>Join Us on Slack</p>
              <p className={styles.slackDescription}>Better GOMYCODE Teaching Experience!</p>
            </div>
          </div>
          <div>
            <a href="http://join.slack.gomycode.co" target="_blank" rel="noreferrer">
              <Button className={styles.joinSlackButton}>
                Join
              </Button>
            </a>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={0}>
        <div className={styles.slackCardReposnive}>
          <div className={styles.rightSideResponsive}>
            <img src={SlackImage} alt="slack logo" className={styles.slackImageResponsive} />
            <div className={styles.textsResponsive}>
              <p className={styles.joinSlackResponsive}>Join Us on Slack</p>
              <p className={styles.slackDescriptionResponsive}>Better GOMYCODE Teaching Experience!</p>
            </div>
          </div>
          <Button className={styles.joinSlackButtonResponsive}>
            <a href="http://join.slack.gomycode.co" target="_blank" rel="noreferrer">
              Join
            </a>
          </Button>
        </div>
      </Col>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <p className={styles.welcome}>
              Welcome
              {' '}
              <span className={styles.firstName}>{profile.firstName}</span>
            </p>
            <p className={styles.location}>{location.name}</p>
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={{ span: 15 }}>
          <Row gutter={[15, 22]}>
            <Col lg={12}>
              <NPSCard
                nps={nps.nps}
                numberOfDetractors={nps.numberOfDetractors}
                numberOfFeedbacks={nps.numberOfFeedbacks}
                numberOfPromoters={nps.numberOfPromoters}
                value={selectedNPSPeriod}
                onChange={setSelectedNPSPeriod}
                image={profile.picture}
              />
            </Col>
            <Col lg={12}>
              <Row gutter={[15, 22]}>
                <Col lg={12}>
                  <GraduatedStudentsCard studentNumber={graduatedStudents} />
                </Col>
                <Col lg={12}>
                  <button
                    onClick={() => {
                      clearFilters('studentsList');
                      setCustomfilter('studentsList', 'studentsList.Status', {
                        value: '0',
                        label: 'Subscribed Active',
                        id: 'studentsList.SubscribedActive',
                        queryKey: 'studentStatus',
                        operator: 'eq',
                        parentId: 'studentsList.Status',
                        filterType: 'multiToast',
                      });
                      setCustomfilter('studentsList', 'studentsList.Status', {
                        value: '2',
                        label: 'Enrolled(Active)',
                        id: 'studentsList.Enrolled',
                        queryKey: 'studentStatus',
                        operator: 'eq',
                        parentId: 'studentsList.Status',
                        filterType: 'multiToast',
                      });
                      setCustomfilter('studentsList', 'studentsList.Status', {
                        value: '4',
                        label: 'Graduated Active',
                        id: 'studentsList.GraduatedActive',
                        queryKey: 'studentStatus',
                        operator: 'eq',
                        parentId: 'studentsList.Status',
                        filterType: 'multiToast',
                      });
                    }}
                    type="button"
                    className={styles.btnWrapper}
                  >
                    <TotalStudentsCard studentNumber={activeStudents} />
                  </button>
                </Col>
                <Col lg={12}>
                  <TotalGuildsCard numberOfGuilds={groups.length} />
                </Col>
                <Col lg={12}>
                  <AveragePresenceCard averagePresence={percentage} />
                </Col>
              </Row>
            </Col>
            {Object.keys(Guilds).map((key: string) => (
              <Col span={24}>
                <TrackCard showAction data={Guilds[key]} />
              </Col>
            ))}
            <Col span={24}>
              <Row gutter={[15, 22]}>
                {groups.map((el) => (
                  <Col span={8}>
                    <GuildCard
                      key={el.group.id}
                      sessionInstances={
                          transformedSessionInstances[el.group.id]
                        }
                      data={el}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={0} sm={0} lg={{ span: 7, offset: 1 }}>
          <Row gutter={[15, 22]}>
            {onlineSession && (
              <Col span={24}>
                <OnlineSessionCard
                  onlineSession={onlineSession}
                />
              </Col>
            )}
            <Col span={24}>
              <TasksCard />
            </Col>
            <Col span={24}>
              <StudentsListCard allStudents={allStudents} />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Responsive dashboard layout */}
      <Row gutter={[15, 22]}>
        <Col xs={24} sm={24} md={24} lg={0}>
          <ResponsiveOnlineCard
            numberOfNonReportedSessions={
              sessionInstances.filter((el) => el.sessionInstance.status === 6 || el.sessionInstance.status === 2)
                .length
            }
            onlineSession={onlineSession}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={0}>
          <ResponsiveNPSCard
            nps={nps.nps}
            numberOfDetractors={nps.numberOfDetractors}
            numberOfFeedbacks={nps.numberOfFeedbacks}
            numberOfPromoters={nps.numberOfPromoters}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={0}>
          <ResponsiveTasksCard />
        </Col>
        {/* <Col xs={24} sm={24} md={24} lg={0}>
          <EarningCard />
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={0}>
          {Object.keys(Guilds).map((key: string) => (
            <Col span={24}>
              <ResponsiveTrackCard data={Guilds[key]} />
            </Col>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
