import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchWorkshopPreviewMode = async (
  trackId: string,
  workshopId: string,
) => {
  const result = await axiosInstance.get(
    URL.learn.workshops.fetchWorkshopPreviewMode(trackId, workshopId),
  );
  return result.data;
};

export default {
  fetchWorkshopPreviewMode,
};
