/* eslint-disable import/prefer-default-export */
import { ReactElement, useEffect, useState } from 'react';
import Loader from './shared/components/Loader';
import { getRoles, isOnlyInstructor } from './shared/utils/requestHeader';
import { getCurrentQueryStringParameter } from './shared/utils/helper';

export const AuthProvider = (props: { children: ReactElement; }) => {
  const [isAppReady, setIsAppReady] = useState(false);

  const expires = getCurrentQueryStringParameter('expires');
  const accessToken = getCurrentQueryStringParameter('access_token');

  const token = localStorage.getItem('token');
  const roles = getRoles();
  const currentUrl = window.location.origin + window.location.pathname;

  useEffect(() => {
    if (
      !!accessToken
      && !!expires
    ) {
      localStorage.setItem('token', accessToken);
      localStorage.setItem('expires', expires);
      window.location.href = currentUrl;
    }
    if (
      !localStorage.getItem('token')
      || localStorage.getItem('token') === 'undefined'
    ) {
      const redirectionUrl = process.env.REACT_APP_AUTH_METHOD === 'KEYCLOAK'
        ? `${process.env.REACT_APP_KEYCLOAK_URL}/external/gomycode?redirectUrl=${currentUrl}`
        : `${process.env.REACT_APP_AUTH_APP_URL}?logout=true`;
      // Comment this code to test locally without redirection
      window.location.href = redirectionUrl;
    }
  }, [accessToken, expires]);

  useEffect(() => {
    if (isAppReady) {
      if (!isOnlyInstructor(roles)) {
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_PORTAL_APP_URL}?access_token=${encodeURIComponent(accessToken || '')}&expires=${encodeURIComponent(expires || '')}`;
      }
    }
  }, [isAppReady, roles]);

  useEffect(() => {
    if (token && roles?.length) {
      setIsAppReady(true);
    }
  }, [token, roles]);

  return isAppReady ? (
    // eslint-disable-next-line react/destructuring-assignment
    props.children
  ) : (<Loader />);
};
