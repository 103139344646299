import { Reducer } from 'redux';
import constants from './constants';
import { GroupsState } from './types';

const initialState: GroupsState = {
  loading: false,
  groups: [],
  groupsWithStudents: {},
  activeGroups: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<GroupsState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchAllGroups.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchAllGroups.success:
      return {
        ...state,
        loading: false,
        groups: action.data.model,
      };

    case constants.fetchGroupById.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchGroupById.success:
      return {
        ...state,
        loading: false,
        groupsWithStudents: {
          ...state.groupsWithStudents,
          [action.id]: action.data.model[0],
        },
      };

    case constants.fetchAllActiveGroups.request:
      return {
        ...state,
        loading: true,
      };

    case constants.fetchAllActiveGroups.success:
      return {
        ...state,
        loading: false,
        activeGroups: action.data.model,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as groupsReducer };
