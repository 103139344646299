import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Toast } from '@gomycode/design-system';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from '@gomycode/types-utils/Types/types';
import styles from './ResponsiveOnlineCard.module.css';
import { OnlineSessions } from '../../../store/online/types';
import RightAngleIcon from '../../../shared/Icons/RightAngleIcon';
import Image from './onlineResponsiveCard.png';
import onlineSessionImage from '../OnlineSessionCard/onlineSessionCard.png';
import sessionInstancesActions from '../../../store/sessionInstance/actions';

interface IResponsiveOnlineCardProps {
  onlineSession: OnlineSessions | undefined;
  numberOfNonReportedSessions: number;
}

const ResponsiveOnlineCard: React.FC<IResponsiveOnlineCardProps> = ({
  onlineSession,
  numberOfNonReportedSessions,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(false);
  useEffect(() => {
    if (onlineSession) {
      setIsOnline(true);
    }
  }, []);
  const dispatch = useDispatch();
  const location = useSelector(
    (state: { profile: { location: Location } }) => state.profile.location,
  );

  if (numberOfNonReportedSessions === 0) {
    return <div />;
  }

  if (location.isOnlineEnabled && !onlineSession) {
    return <div />;
  }
  return (
    <div
      onClick={() => dispatch(sessionInstancesActions.resetState())}
      className={`${styles.responsiveAttendanceCard} ${
        isOnline ? styles.online : styles.attendance
      }`}
    >
      <div
        className={`${
          isOnline ? styles.onlineUpperSection : styles.attendanceUpperSection
        }`}
      >
        <div className={styles.leftSection}>
          {isOnline && (
            <p className={styles.sessions}>
              SESSION
              {' '}
              {onlineSession?.onlineSession.sessionNumber}
              /
              {onlineSession?.opening.numberOfSessions}
            </p>
          )}
          {isOnline && (
            <p className={styles.trackName}>{onlineSession?.track?.name}</p>
          )}
          {isOnline && (
            <p className={styles.time}>
              {`${moment(
                onlineSession?.onlineSession.startDate,
              ).format('hh:mm A')} — ${moment(
                onlineSession?.onlineSession.dueDate,
              ).format('hh:mm A')}`}
            </p>
          )}
          {!isOnline && (
            <p className={styles.pendingSessions}>Pending Sessions</p>
          )}
          {!isOnline && (
            <p className={styles.unreportedSessions}>
              {numberOfNonReportedSessions}
              {' '}
              Sessions
            </p>
          )}
        </div>
        {isOnline ? (
          <img className={styles.image} src={Image} alt="background" />
        ) : (
          <img
            className={styles.image}
            src={onlineSessionImage}
            alt="background"
          />
        )}
      </div>
      <div className={styles.lowerSection}>
        <div>
          {isOnline ? (
            <p className={styles.now}>NOW</p>
          ) : (
            <Toast
              className={styles.notReportedToast}
              withBorder={false}
              type="failure"
              title="NOT REPORTED"
            />
          )}
        </div>
        <div className={styles.linkWrapper}>
          {isOnline ? (
            <Link
              className={`${isOnline ? styles.onlineLink : styles.link}`}
              to={`/online-sessions/${onlineSession?.onlineSession.id}/active`}
            >
              Join session
            </Link>
          ) : (
            <Link
              className={`${isOnline ? styles.onlineLink : styles.link}`}
              to="/attendance"
            >
              Attendance
            </Link>
          )}
          <RightAngleIcon
            className={`${isOnline ? styles.icon : styles.notOnlineIcon}`}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsiveOnlineCard;
