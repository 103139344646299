import React from 'react';

type FlagIconProps = {
  className?: string;
};

const FlagIcon: React.FC<FlagIconProps> = ({ className }) => (
  <svg
    width="46"
    height="47"
    viewBox="0 0 46 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M17.75 29.8125V17.125C17.75 16.6602 17.3398 16.25 16.875 16.25C16.3828 16.25 16 16.6602 16 17.125V29.8125C16 30.0586 16.1914 30.25 16.4375 30.25H17.3125C17.5312 30.25 17.75 30.0586 17.75 29.8125ZM29.0156 16.25C29.5352 16.25 30 16.5781 30 17.0977V25.3555C30 25.6836 29.7812 25.9844 29.3984 26.1211C28.2227 26.5859 27.2383 26.75 26.3633 26.75C24.3398 26.75 22.9453 25.9023 20.9219 25.9023C20.2383 25.9023 19.5 26.0117 18.625 26.2852V16.6875C19.582 16.3867 20.375 16.2773 21.0586 16.2773C22.9453 16.2773 23.9023 17.1523 25.543 17.1523C26.3086 17.1523 27.2383 16.9609 28.4688 16.3867C28.6602 16.3047 28.8242 16.25 29.0156 16.25Z"
      fill="#176FFF"
    />
  </svg>
);

export default FlagIcon;

FlagIcon.defaultProps = {
  className: '',
};
