import axios from 'axios';
import { logout } from './helper';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LEARN_ENDPOINT,
  headers: {
    'Content-type': 'application/json',
    'Cache-Control': 'no-cache',
    'X-Client-App': 'student',
    Pragma: 'no-cache',
    Expires: '1',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      logout();
    }
    return Promise.reject(error.response.data.model);
  },

);

export default axiosInstance;
