/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Input, Radio } from 'antd';
import { isNaN, isNull } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './RatingComponent.module.css';
import { hideLongText } from '../../../shared/constants';

interface RatingComponentProps {
  criteria: {
    title: string;
    score: number | null;
    feedback: string;
  };
  onChange: (criteria: {
    title: string;
    score: number | null;
    feedback: string;
  }) => void;
}

const RatingComponent: React.FC<RatingComponentProps> = ({
  criteria,
  onChange,
}) => {
  const [showSecondaryInputs, setShowSecondaryInputs] = useState<
    boolean | null
  >(null);
  const [scoreValue, setScoreValue] = useState<number | null>(
    criteria.score || null,
  );
  const [noFeedbackFilled, setNoFeedbackFilled] = useState<boolean>(false);
  const [yesFeedbackFilled, setYesFeedbackFilled] = useState<boolean>(false);
  const [scoreFilled, setScoreFilled] = useState<boolean>(false);

  useEffect(() => {
    if (!criteria.feedback) {
      setNoFeedbackFilled(false);
    } else setNoFeedbackFilled(true);
  }, [criteria.feedback]);

  useEffect(() => {
    if (!criteria.feedback) {
      setYesFeedbackFilled(false);
    } else setYesFeedbackFilled(true);
  }, [criteria.feedback]);

  useEffect(() => {
    if (!scoreValue) {
      setScoreFilled(false);
    } else setScoreFilled(true);
  }, [scoreValue]);

  const onChangeScoreValue = (value: string) => {
    if (value === '') {
      setScoreValue(null);
      return;
    }
    const intValue = Number(value);
    if (!isNaN(intValue)) {
      if (intValue > 0 && intValue < 6) {
        setScoreValue(intValue);
        onChange({ ...criteria, score: intValue * 2 });
      }
    }
  };
  const showSecondaryInputsFunction = (showInputsSecondary: boolean | null) => {
    if (!isNull(showSecondaryInputs)) {
      if (!showInputsSecondary) {
        return (
          <div>
            <div className={styles.criteriaInput}>
              <Input
                placeholder="1-5"
                className={styles.score}
                value={scoreValue !== null ? scoreValue.toString() : ''}
                onChange={(e) => onChangeScoreValue(e.target.value)}
              />

              <Input
                placeholder="Feedback"
                className={styles.reason}
                onChange={(e) => {
                  onChange({ ...criteria, feedback: e.target.value });
                }}
                value={criteria.feedback}
              />
            </div>
            {!scoreFilled || !yesFeedbackFilled ? (
              <p className={styles.required}>Required Field</p>
            ) : (
              <p className={styles.filled}>
                {' '}
                Filled
                {' '}
                <FontAwesomeIcon icon={faCheck} />
                {' '}
              </p>
            )}
          </div>
        );
      } if (showInputsSecondary) {
        return (
          <div>
            <Input
              placeholder="Feedback"
              className={styles.reason}
              onChange={(e) => {
                onChange({ ...criteria, feedback: e.target.value });
              }}
              value={criteria.feedback}
            />
            {!noFeedbackFilled ? (
              <p className={styles.required}>Required Field</p>
            ) : (
              <p className={styles.filled}>
                {' '}
                Filled
                {' '}
                <FontAwesomeIcon icon={faCheck} />
                {' '}
              </p>
            )}
          </div>
        );
      }
    }
    return '';
  };

  return (
    <div className={styles.ratingComponent}>
      <div className={styles.primaryInputs}>
        <p className={styles.title}>
          {criteria?.title && hideLongText(criteria?.title, 100)}
        </p>

        <Radio.Group
          onChange={(e) => {
            setShowSecondaryInputs(e.target.value);
            if (e.target.value) {
              setScoreValue(null);
              onChange({ ...criteria, score: 0 });
            } else {
              onChange({ ...criteria, score: null });
            }
          }}
        >
          <Radio value={false}>Yes</Radio>
          <Radio value>No</Radio>
        </Radio.Group>
      </div>

      {showSecondaryInputsFunction(showSecondaryInputs)}
    </div>
  );
};

export default RatingComponent;
