import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { encodeUnattachedFilter } from '@gomycode/design-system';
import styles from './TasksCard.module.css';
import TaskCard from './TaskCard';
import checkPointActions from '../../../store/checkpoint/actions';
import { fetchAllAutomatedOneToOne, fetchAllFaceToFaceOneToOne } from '../../../store/oneToOneList/actions';
import {
  fetchAllLabPhaseApplications,
  getLabPhaseAssignment,
} from '../../../store/Student/actions';
import { encodeFields } from '../../../shared/utils/queryHelper';
import { fetchAllLabPhaseApplicationsFields } from './helpers';
import { Application, LabPhaseAssignmentsApiResponse } from '../../../store/Student/types';
import { CheckpointApiResponse } from '../../../store/checkpoint/types';
import { OneToOneApiResponse } from '../../../store/oneToOneList/types';

const TasksCard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      checkPointActions.getAllCheckpoints({
        query: [encodeUnattachedFilter('status', '2', 'eq')],
      }),
    );
    dispatch(
      fetchAllAutomatedOneToOne({
        query: [encodeUnattachedFilter('status', '2', 'eq')],
      }),
    );
    dispatch(
      fetchAllFaceToFaceOneToOne({
        query: [encodeUnattachedFilter('status', '2', 'eq')],
      }),
    );
    dispatch(
      getLabPhaseAssignment({
        query: [],
      }),
    );
    dispatch(
      fetchAllLabPhaseApplications({
        query: [
          encodeUnattachedFilter('status', '0', 'eq'),
          encodeFields(fetchAllLabPhaseApplicationsFields),
        ],
      }),
    );
  }, []);

  const oneToOnesList = useSelector(
    (state: {
      onetoonelist: {
        OneToOnes: OneToOneApiResponse[];
      };
    }) => state.onetoonelist.OneToOnes,
  );

  const automatedOneToOnesList = useSelector(
    (state: {
      onetoonelist: {
        automatedOneToOnes: OneToOneApiResponse[];
      };
    }) => state.onetoonelist.automatedOneToOnes,
  );

  const checkpointsList = useSelector(
    (state: {
      checkpoints: {
        allCheckpoints: CheckpointApiResponse[];
      };
    }) => state.checkpoints.allCheckpoints,
  );

  const phasesList = useSelector(
    (state: {
      student: {
        labPhaseAssignment: LabPhaseAssignmentsApiResponse[];
      };
    }) => state.student.labPhaseAssignment,
  );

  const applicationsList = useSelector(
    (state: {
      student: {
        applications: Application[];
      };
    }) => state.student.applications,
  );

  const filteredPhasesList = phasesList.filter((phase) => phase.currentPhase.status === 2);

  const taskNumber = oneToOnesList.length
    + automatedOneToOnesList.length
    + checkpointsList.length
    + filteredPhasesList.length
    + applicationsList.length;

  return (
    <div className={styles.tasksCard}>
      <div className={styles.header}>
        <p className={styles.title}>Tasks</p>
        <p className={styles.total}>
          {`Total ${taskNumber}`}
        </p>
      </div>
      <div className={styles.tasksContainer}>
        {oneToOnesList.map((oto) => <TaskCard entity={oto} type="oneToOne" />)}
        {automatedOneToOnesList.map((oto) => <TaskCard entity={oto} type="automatedOneToOne" />)}
        {checkpointsList.map((cp) => <TaskCard entity={cp} type="checkpoint" />)}

        {filteredPhasesList.map((lp) => <TaskCard entity={lp} type="labPhase" />)}

        {applicationsList.map((application) => <TaskCard entity={application} type="application" />)}
      </div>
    </div>
  );
};

export default TasksCard;
