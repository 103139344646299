import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchDiscordURLs = async () => {
  const result = await axiosInstance.get(
    URL.messaging.discordServers.getDiscordServersToJoin,
  );

  return result.data;
};

export default {
  fetchDiscordURLs,
};
