import React from 'react';

type Props = {
  className?: string;
};
const OnlineSessionsIcon = ({ className }: Props) => (
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5 3.25V13.25H2.5V2.625C2.5 1.60938 3.32031 0.75 4.375 0.75H20.625C21.6406 0.75 22.5 1.60938 22.5 2.625V13.25H20V3.25H5ZM24.375 14.5C24.6875 14.5 25 14.8125 25 15.125V15.75C25 17.1172 23.8672 18.25 22.5 18.25H2.5C1.09375 18.25 0 17.1172 0 15.75V15.125C0 14.7734 0.273438 14.5 0.625 14.5H24.375Z"
      fill="#176FFF"
    />
  </svg>
);

export default OnlineSessionsIcon;

OnlineSessionsIcon.defaultProps = {
  className: '',
};
