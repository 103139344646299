import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { Button, UserCard } from '@gomycode/design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ArrowLeftIcon from '../../shared/Icons/ArrowLeft';
import FinalModal from './FinalModal';
import RatingComponent from './RatingComponent';
import styles from './LabPhaseFinalApproval.module.css';
import actions from '../../store/LabPhase/actions';
import Loader from '../../shared/components/Loader';

const LabPhaseFinalApproval = () => {
  const { labPhaseAssignmentId } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    if (labPhaseAssignmentId) {
      dispatch(actions.fetchLabPhaseAssignmentById(labPhaseAssignmentId));
    }
  }, [dispatch, labPhaseAssignmentId]);
  const labPhaseAssignment = useSelector(
    (state: {
      labPhases: {
        studentLabPhaseAssignment: {
          student: { fullName: string; picture: string };
          labPhaseAssignment: {
            overallCriteriaList: string;
            projectTitle: string;
          };
        };
      };
    }) => state.labPhases.studentLabPhaseAssignment,
  );
  const [overallCriteria, setOverallCriteria] = useState<
    {
      title: string;
      score: number | null;
      feedback: string;
    }[]
  >([]);
  const [feedback, setFeedback] = useState<string>();

  const [canSubmit, setCanSubmit] = useState(false);
  const validateFields = () => {
    let result = true;
    if (!feedback) {
      setCanSubmit(false);
      return;
    }

    overallCriteria?.forEach((criteria) => {
      if (
        (criteria?.score && criteria?.score < 0)
        || (criteria?.score && criteria?.score > 10)
        || !criteria.feedback
        || !isNumber(criteria.score)
      ) {
        result = false;
      }
    });

    setCanSubmit(result);
  };

  useEffect(() => {
    validateFields();
  }, [overallCriteria, feedback]);

  useEffect(() => {
    if (labPhaseAssignment?.labPhaseAssignment) {
      setOverallCriteria(
        JSON.parse(labPhaseAssignment.labPhaseAssignment.overallCriteriaList),
      );
    }
  }, [labPhaseAssignment?.labPhaseAssignment]);

  const onCriteriaChange = (
    criteria: {
      title: string;
      score: number | null;
      feedback: string;
    },
    index: number,
  ) => {
    const newCriterias = [...overallCriteria];
    newCriterias[index] = { ...criteria };
    setOverallCriteria(newCriterias);
  };
  const HandleSubmit = () => {
    if (labPhaseAssignmentId) {
      dispatch(
        actions.approveLabPhase(labPhaseAssignmentId, {
          overallCriteria,
          instructorFeedback: feedback,
        }),
      );
    }
  };

  if (!labPhaseAssignmentId || !labPhaseAssignment?.labPhaseAssignment) return <Loader />;

  return (
    <div className={styles.labPhaseContainer}>
      <Helmet>
        <title>Labphase </title>
        <meta name="Labphase " content="Labphase" />
      </Helmet>
      <FinalModal />
      <Link to={`/instructor-labPhase-phases/${labPhaseAssignmentId}`}>
        <div className={styles.backBtn}>
          <ArrowLeftIcon />
          <p>All Phases</p>
        </div>
      </Link>
      <p className={styles.personalizedTitle}>
        Lab Phase /
        {' '}
        {labPhaseAssignment?.labPhaseAssignment?.projectTitle}
      </p>

      <div className={styles.labPhaseFinalApproval}>
        <p className={styles.title}>Phase Evaluation</p>
        <p className={styles.description}>
          Once a phase is validated, nor student or instructor is able to edit
          it.
        </p>
        <div className={styles.subDescription}>
          <p>Presentation Criteria</p>
        </div>
        <div className={styles.QuestionContainer}>
          {overallCriteria?.map(
            (
              criteria: {
                title: string;
                score: number | null;
                feedback: string;
              },
              i: number,
            ) => (
              <RatingComponent
                onChange={(e) => onCriteriaChange(e, i)}
                criteria={criteria}
              />
            ),
          )}
        </div>
        <div className="instructor-lp-feedback">
          <p className={styles.subDescription}>
            leave a general feedback for your students *
          </p>
          <div className="feedback-section">
            <textarea
              className={styles.textArea}
              placeholder="Your General Feedback Here"
              onChange={(e) => setFeedback(e.target.value)}
              value={feedback}
            />
          </div>
          {!feedback ? (
            <p className={styles.required}>Required Field</p>
          ) : (
            <p className={styles.filled}>
              {' '}
              Filled
              {' '}
              <FontAwesomeIcon icon={faCheck} />
            </p>
          )}
        </div>
        <div className={styles.approvedFooter}>
          <UserCard
            image={labPhaseAssignment?.student?.picture}
            name={labPhaseAssignment?.student?.fullName}
            description="STUDENT"
          />

          <Button
            priority="primary"
            onClick={HandleSubmit}
            disabled={!canSubmit}
            className={styles.feedbackBtn}
          >
            Approve
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LabPhaseFinalApproval;
