import {
  generateActionWithBody,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

export const setNavigationIndex = generateActionWithBody(
  constants.setNavigationIndex.request,
);

export const fetchSkeleton = generateActionWithMultipleValues(
  constants.fetchSkeleton.request,
);
export const getActiveStudents = generateActionWithMultipleValues(
  constants.getActiveStudents.request,
);

export const getItem = generateActionWithMultipleValues(
  constants.getItem.request,
);
export const patchBookMark = generateActionWithMultipleValues(
  constants.patchBookMark.request,
);
export const getBookMarkedNodes = generateActionWithMultipleValues(
  constants.getBookMarkedNodes.request,
);

export const setCurriculumOpen = generateActionWithBody(
  constants.setCurriculumOpen.request,
);
export const openFeedbackModal = generateActionWithBody(
  constants.openFeedbackModal.request,
);
export const postSkillFeedback = generateActionWithBody(
  constants.postSkillFeedback.request,
);

export const getResources = generateActionWithMultipleValues(
  constants.getResources.request,
);

export const patchResources = generateActionWithMultipleValues(
  constants.patchResources.request,
);
export const setAddModalOpen = generateActionWithBody(
  constants.setAddModalOpen.request,
);
export const setEditModalOpen = generateActionWithBody(
  constants.setEditModalOpen.request,
);
export const getTrackStats = generateActionWithMultipleValues(
  constants.getTrackStats.request,
);
export const setUnsavedModalOpen = generateActionWithBody(
  constants.setUnsavedModalOpen.request,
);
export const getOrderedOneToOnes = generateActionWithMultipleValues(
  constants.getOrderedOneToOnes.request,
);
export const getOrderedCheckpoints = generateActionWithMultipleValues(
  constants.getOrderedCheckpoints.request,
);

export const getOrderedWorkshops = generateActionWithMultipleValues(
  constants.getOrderedWorkshops.request,
);
export const resetNotification = generateEmptyAction(
  constants.resetNotification.request,
);
export const patchResourcesFromTrack = generateActionWithMultipleValues(
  constants.patchResourcesFromTrack.request,
);

export const setShowCurriculum = generateActionWithBody(
  constants.setShowCurriculum.request,
);
