import React from 'react';

type CheckpointIconProps = {
  className?: string;
};

const CheckpointIcon: React.FC<CheckpointIconProps> = ({ className }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className="svg-light-mode"
      d="M8.75 10.5C8.75 9.83594 9.29688 9.25 10 9.25C10.6641 9.25 11.25 9.83594 11.25 10.5C11.25 11.2031 10.6641 11.75 10 11.75C9.29688 11.75 8.75 11.2031 8.75 10.5ZM10 0.5C10.6641 0.5 11.25 1.08594 11.25 1.75V2.17969C14.8828 2.72656 17.7734 5.61719 18.3203 9.25H18.75C19.4141 9.25 20 9.83594 20 10.5C20 11.2031 19.4141 11.75 18.75 11.75H18.3203C17.7734 15.4219 14.8828 18.3125 11.25 18.8594V19.25C11.25 19.9531 10.6641 20.5 10 20.5C9.29688 20.5 8.75 19.9531 8.75 19.25V18.8594C5.07812 18.3125 2.1875 15.4219 1.64062 11.75H1.25C0.546875 11.75 0 11.2031 0 10.5C0 9.83594 0.546875 9.25 1.25 9.25H1.64062C2.1875 5.61719 5.07812 2.72656 8.75 2.17969V1.75C8.75 1.08594 9.29688 0.5 10 0.5ZM8.75 16.3203V15.5C8.75 14.8359 9.29688 14.25 10 14.25C10.6641 14.25 11.25 14.8359 11.25 15.5V16.3203C13.5156 15.8516 15.3125 14.0547 15.7812 11.75H15C14.2969 11.75 13.75 11.2031 13.75 10.5C13.75 9.83594 14.2969 9.25 15 9.25H15.7812C15.3125 6.98438 13.5156 5.1875 11.25 4.71875V5.5C11.25 6.20312 10.6641 6.75 10 6.75C9.29688 6.75 8.75 6.20312 8.75 5.5V4.71875C6.44531 5.1875 4.64844 6.98438 4.17969 9.25H5C5.66406 9.25 6.25 9.83594 6.25 10.5C6.25 11.2031 5.66406 11.75 5 11.75H4.17969C4.64844 14.0547 6.44531 15.8516 8.75 16.3203Z"
      fill="#176FFF"
    />
  </svg>
);

export default CheckpointIcon;

CheckpointIcon.defaultProps = {
  className: '',
};
