import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const postSkillFeedback = async (body: {}) => {
  const result = await axiosInstance.post(
    URL.capacity.allocations.postSkillFeedback,
    body,
  );
  return result.data;
};

export default {
  postSkillFeedback,
};
