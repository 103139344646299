import { Reducer } from 'redux';
import { calculateScore } from '../../pages/AutomatedOneToOneDetails/QuestionsAndResponsesContainer/helper';
import constants from './constants';
import { NOT_STARTED } from './helper';
import { oneToOneState } from './types';

// Type-safe initialState!
export const initialState: oneToOneState = {
  fetchOneToOneByIdLoading: false,
  oneToOne: {},
  studentScores: [],
  fetchOneToOneByIdErrors: [],
  isCorrected: [],
  realTimeScore: 0,
  totalAnswrers: 0,
  correctAnswers: 0,
  questionIndex: 1,
  showStartOneToOne: false,
  startOneToOneLoading: false,
  startOneToOneErrors: [],
  updateStudentAnswerLoading: false,
  updateStudentAnswerErrors: [],
  showInstructorFeedback: false,
  showTimeIsUpModal: false,
  sendInstructorFeedbackLoading: false,
  sendInstructorFeedbackErrors: [],
  showModal: false,
  sendFeedbackDisabled: false,
  shouldRefetsh: false,
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<oneToOneState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchOneToOneById.request:
      return { ...state, fetchOneToOneByIdLoading: true, shouldRefetsh: false };

    case constants.fetchOneToOneById.success: {
      return {
        ...state,
        fetchOneToOneByIdLoading: false,
        oneToOne: action.data.model,
        studentScores:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
          ),

        isCorrected: action.data.model.oneToOneAssignment.subjectQuestions.map(
          (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
        ),
        realTimeScore: calculateScore(
          action.data.model.oneToOneAssignment.studentAnswersScore,
          action.data.model.oneToOneAssignment.subjectQuestions.length,
        ),
        correctAnswers:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el === 2,
          ).length,
        totalAnswrers:
          action.data.model.oneToOneAssignment.studentAnswers.length,
        showStartOneToOne:
          NOT_STARTED === action.data.model.oneToOneAssignment.startDate,
        showInstructorFeedback:
          state.questionIndex
          > action.data.model.oneToOneAssignment.subjectQuestions.length - 1,
        sendFeedbackDisabled:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el !== -1,
          ).length
          === action.data.model.oneToOneAssignment.subjectQuestions.length,
      };
    }
    case constants.fetchOneToOneById.failure:
      return {
        ...state,
        fetchOneToOneByIdLoading: false,
        fetchOneToOneByIdErrors: action.objectErrors,
      };
      // start One to One

    case constants.startOneToOne.request:
      return {
        ...state,
        startOneToOneLoading: true,
      };
    case constants.startOneToOne.success:
      return {
        ...state,
        startOneToOneLoading: false,
        showStartOneToOne:
          NOT_STARTED === action.data.model.oneToOneAssignment.startDate,
        shouldRefetsh: true,
      };
    case constants.startOneToOne.failure:
      return {
        ...state,
        startOneToOneLoading: false,
        startOneToOneErrors: action.ObjectErrors,
      };
    case constants.setQuestionIndex.success:
      return {
        ...state,
        updateStudentAnswerErrors: [],
        questionIndex: action.data,
      };

    case constants.updateStudentAnswer.request:
      return { ...state, updateStudentAnswerLoading: true };
    case constants.updateStudentAnswer.success:
      return {
        ...state,
        updateStudentAnswerLoading: false,
        questionIndex: state.questionIndex + 1,
        showInstructorFeedback:
          state.questionIndex
          > action.data.model.oneToOneAssignment.subjectQuestions.length - 1,
        studentScores:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
          ),

        isCorrected: action.data.model.oneToOneAssignment.subjectQuestions.map(
          (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
        ),
        realTimeScore: calculateScore(
          action.data.model.oneToOneAssignment.studentAnswersScore,
          action.data.model.oneToOneAssignment.subjectQuestions.length,
        ),
        correctAnswers:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el === 2,
          ).length,
        sendFeedbackDisabled:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el !== -1,
          ).length
          === action.data.model.oneToOneAssignment.subjectQuestions.length,
      };

    case constants.updateStudentAnswer.failure:
      return {
        ...state,
        updateStudentAnswerLoading: false,
        updateStudentAnswerErrors: action.objectErrors,
      };
    case constants.setShowTimeIsUpModal.success:
      return {
        ...state,
        showTimeIsUpModal: action.data,
      };
    case constants.handleBackFromFeedback.success:
      return {
        ...state,
        updateStudentAnswerErrors: [],
        questionIndex: action.data,
        showInstructorFeedback: false,
      };
    case constants.setShowInstructorModal.success:
      return {
        ...state,
        showInstructorFeedback: action.data,
      };
    case constants.resetOneToOneSettings.success:
      return {
        ...state,
        showTimeIsUpModal: false,
        questionIndex: 1,
        showInstructorFeedback: false,
        showStartOneToOne: false,
        showModal: false,
      };
    case constants.sendInstructorFeedback.request:
      return { ...state, sendInstructorFeedbackLoading: true };
    case constants.sendInstructorFeedback.success:
      return {
        ...state,
        sendInstructorFeedbackLoading: false,
        showModal: true,
      };
    case constants.sendInstructorFeedback.failure:
      return {
        ...state,
        sendInstructorFeedbackLoading: false,
        sendInstructorFeedbackErrors: action.objectErrors,
      };
    case constants.setShowInstructorFeedback.success:
      return {
        ...state,

        showInstructorFeedback: action.data,
      };
    case constants.setShowModal.success:
      return {
        ...state,
        showModal: action.data,
      };
    default: {
      return state;
    }
  }
};

export { reducer as OneToOneReducer };
