import React from 'react';

type BlockedIconProps = {
  className?: string;
};

const BlockedIcon: React.FC<BlockedIconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="145"
    viewBox="0 0 114 145"
    fill="none"
    className={className}
  >
    <path
      d="M108.065 134.512L105.028 132.75L97.8457 127.648L100.875 129.41L108.065 134.512Z"
      fill="#E92E2D"
    />
    <path
      d="M93.8863 112.862L101.077 117.963L108.569 104.766L113.393 107.392L105.574 121.152L113.075 126.471L108.066 134.517L100.875 129.416L93.3745 142.614L88.5586 139.987L96.3782 126.228L88.8774 120.9L93.8863 112.862Z"
      fill="#F54945"
    />
    <path
      d="M35.9329 50.8516L36.0923 107.41L6.17309 124.685L6.01367 68.1185L35.9329 50.8516Z"
      fill="#176FFF"
    />
    <path
      d="M92.1452 36.9297L92.1956 57.301L44.8751 84.6193L44.8164 64.248L92.1452 36.9297Z"
      fill="#176FFF"
    />
    <path
      d="M0.109268 66.1426C0.107265 65.4618 0.469632 64.832 1.05918 64.4916L93.3592 11.1961C94.6238 10.4659 96.2052 11.3759 96.2093 12.8361L96.3893 76.6631C96.3912 77.3438 96.0288 77.9735 95.4393 78.3139L3.14709 131.601C1.88257 132.332 0.301358 131.422 0.297062 129.962L0.109268 66.1426ZM35.1387 108.079C35.7902 107.703 36.1907 107.007 36.1886 106.255L36.0422 54.2996C36.0376 52.6856 34.2898 51.6799 32.8921 52.4869L6.96639 67.4564C6.31486 67.8326 5.91434 68.5286 5.91646 69.2809L6.06288 121.236C6.06743 122.85 7.81522 123.856 9.21294 123.049L35.1387 108.079ZM91.2432 57.9648C91.895 57.5886 92.2956 56.8925 92.2934 56.14L92.248 40.3717C92.2433 38.7578 90.4956 37.7521 89.098 38.5591L45.7625 63.5801C45.1109 63.9563 44.7104 64.6524 44.7126 65.4048L44.758 81.1654C44.7627 82.7792 46.5101 83.7849 47.9078 82.9781L91.2432 57.9648ZM91.177 32.2572C91.8282 31.8811 92.2286 31.1854 92.2267 30.4334L92.2049 21.8809C92.2008 20.2667 90.4529 19.2605 89.055 20.0676L45.7294 45.0796C45.0776 45.456 44.677 46.1523 44.6794 46.905L44.7066 55.4665C44.7118 57.0802 46.4595 58.0854 47.8569 57.2783L91.177 32.2572ZM91.2942 75.6511C91.9456 75.275 92.346 74.5792 92.3441 73.827L92.3227 65.7191C92.3184 64.105 90.5706 63.0989 89.1727 63.9059L45.8462 88.9185C45.1948 89.2946 44.7942 89.9905 44.7962 90.7427L44.8176 98.8584C44.8218 100.473 46.5699 101.479 47.9678 100.671L91.2942 75.6511Z"
      fill="#F5FBFF"
      stroke="#176FFF"
      strokeWidth="0.2"
    />
    <path
      d="M89.2222 63.9922C90.5535 63.2237 92.2181 64.1818 92.2221 65.719L92.2435 73.827C92.2454 74.5433 91.864 75.206 91.2437 75.5642L47.9172 100.584C46.5859 101.353 44.9211 100.395 44.917 98.8578L44.8956 90.7422C44.8937 90.0258 45.2752 89.363 45.8957 89.0048L89.2222 63.9922Z"
      fill="#176FFF"
    />
    <path
      d="M92.088 18.4297L92.1299 31.5938L44.8095 58.9205L44.7676 45.748L92.088 18.4297Z"
      fill="#176FFF"
    />
    <path
      d="M94.8396 0.950053C95.4388 0.604172 96.1879 1.03546 96.1896 1.72727L96.2007 6.20891C96.2014 6.53123 96.0298 6.82936 95.7507 6.99054L1.4563 61.4376C0.856723 61.7838 0.107113 61.3517 0.106259 60.6593L0.100713 56.1628C0.100316 55.8408 0.271923 55.5432 0.550746 55.3822L94.8396 0.950053Z"
      fill="#F3F8FF"
      stroke="#176FFF"
      strokeWidth="0.2"
    />
    <path
      d="M47.0299 55.2602C67.3676 43.5223 83.9046 52.9445 83.9717 76.3194C84.0472 99.7028 67.6025 128.162 47.2648 139.9C26.9187 151.646 10.3733 142.224 10.3146 118.841C10.2559 95.4825 26.6838 67.0064 47.0299 55.2602ZM47.248 133.565C64.5401 123.581 78.5181 99.3755 78.4594 79.4993C78.4258 67.7363 73.4672 60.1264 65.849 57.9534L67.334 54.161L62.7362 53.7164L61.3351 57.3157C57.0477 57.257 52.1982 58.633 47.0634 61.6031C29.7714 71.5958 15.8018 95.7929 15.8521 115.652C15.8941 128.204 21.5155 136.024 30.0063 137.576L28.6303 141.1L33.2281 141.519L34.688 137.802C38.5223 137.526 42.7761 136.15 47.248 133.565Z"
      fill="#F54945"
    />
    <path
      d="M62.727 53.7266L67.3248 54.1629L65.8482 57.9636L34.6704 137.804L33.2105 141.521L28.6211 141.102L29.9887 137.578L61.3259 57.3259L62.727 53.7266Z"
      fill="#F54945"
    />
  </svg>
);

export default BlockedIcon;

BlockedIcon.defaultProps = {
  className: '',
};
