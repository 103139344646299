import { Reducer } from 'redux';
import constants from './constants';
import { TracksState } from './types';

const initialState: TracksState = {
  errors: undefined,
  loading: false,
  tracks: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<TracksState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchTracks.request:
      return { ...state, loading: true };

    case constants.fetchTracks.success: {
      return {
        ...state,
        loading: false,
        tracks: action.data.model,
      };
    }
    case constants.fetchTracks.failure: {
      return { ...state, loading: false, errors: [] };
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as tracksReducer };
