import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchCheckpointPreviewMode = async (
  trackId: string,
  checkpointId: string,
) => {
  const result = await axiosInstance.get(
    URL.learn.checkpoints.fetchCheckpointPreviewMode(trackId, checkpointId),
  );
  return result.data;
};

export default {
  fetchCheckpointPreviewMode,
};
