import React from 'react';

import styles from './GraduatedStudentsCard.module.css';
import UserGraduateIcon from '../../../shared/Icons/UserGraduate';

interface IGraduatedStudentsCardProps {
  studentNumber: number
}

const GraduatedStudentsCard: React.FC<IGraduatedStudentsCardProps> = ({
  studentNumber,
}) => (
  <div className={styles.graduatedStudentsCard}>
    <div className={styles.icon}><UserGraduateIcon /></div>
    <div className={styles.amount}>{studentNumber}</div>
    <div className={styles.title}>Graduated students</div>
  </div>
);

export default GraduatedStudentsCard;
