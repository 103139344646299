export const TransformTracksData = (data: []) => data
  .map((el: { track: { id: string; name: string } }) => ({
    id: el.track.id,
    name: el.track.name,
  }))
  .filter(
    (el, index, arr) => arr.findIndex((el2) => el2.id === el.id) === index,
  );
export const TransformAutomatedoneToOneAssignmentsFilterData = (data: []) => data
  .filter(
    (el: {
        oneToOneAssignment: {
          isAutomated: boolean;
          status: number;
          id: string;
          name: string;
        };
      }) => el.oneToOneAssignment.isAutomated && el.oneToOneAssignment.status >= 2,
  )
  .map((el: { oneToOneAssignment: { id: string; name: string } }) => ({
    id: el.oneToOneAssignment.id,
    name: el.oneToOneAssignment.name,
  }))
  .filter(
    (el, index, arr) => arr.findIndex((el2) => el2.name === el.name) === index,
  );

export const TransformoneToOneAssignmentsFilterData = (data: []) => data
  .filter(
    (el: {
        oneToOneAssignment: {
          isAutomated: boolean;
          status: number;
          id: string;
          name: string;
        };
      }) => !el.oneToOneAssignment.isAutomated && el.oneToOneAssignment.status >= 2,
  )
  .map((el: { oneToOneAssignment: { id: string; name: string } }) => ({
    id: el.oneToOneAssignment.id,
    name: el.oneToOneAssignment.name,
  }))
  .filter(
    (el, index, arr) => arr.findIndex((el2) => el2.name === el.name) === index,
  );
