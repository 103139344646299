// eslint-disable-next-line import/prefer-default-export
export const fetchAllLabPhaseApplicationsFields = {
  student: {
    fullName: '',
    picture: '',
    id: '',
  },
  requestedBy: {
    title: '',
    dateEffective: '',
  },
  labPhase: {
    id: '',
  },
};
