import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ResponsiveTrackCard.module.css';
import LinkArrowIcon from '../../../shared/Icons/LinkArrowIcon';
import { fetchSkeleton } from '../../../store/trackPreview/actions';
import { DashboardCustomGuild } from '../../../store/groups/types';
import { Node } from '../../../store/trackPreview/types';

interface IResponsiveTrackCardProps {
  data: DashboardCustomGuild;
}

const ResponsiveTrackCard: React.FC<IResponsiveTrackCardProps> = ({ data }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSkeleton({ query: [], trackId: data.track.id }));
  }, []);
  const skeleton = useSelector(
    (state: {
      trackPreview: {
        skeleton: Node;
      };
    }) => state.trackPreview.skeleton,
  );
  return (
    <div className={styles.responsiveTrackCard}>
      <img src={data.track.image} alt="track name" className={styles.image} />
      <div className={styles.infos}>
        <p className={styles.trackName}>{data.track.name}</p>
        <p className={styles.metrics}>
          {data.records.length}
          {' '}
          Active Guilds •
          {data.studentCount}
          {' '}
          Students
          trained
        </p>
        {skeleton?.children && (
          <Link
            className={styles.link}
            to={`/track-overview/${data.track.id}/node/${skeleton?.children[0]?.children[0]?.id}/kind/${skeleton?.children[0]?.children[0]?.kind}`}
          >
            Preview course
            {' '}
            <LinkArrowIcon className={styles.icon} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default ResponsiveTrackCard;
