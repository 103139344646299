import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/instructor/feedbacks';

export function* fetchInstructorNps(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.getNPS> = yield call(api.getNPS, action.values);
    yield put({
      type: constants.getNPS.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getNPS.failure,
      e,
    });
  }
}

export function* fetchInstructorNpsWatcher() {
  yield takeEvery(constants.getNPS.request, fetchInstructorNps);
}

export default function* npsSaga() {
  yield all([fetchInstructorNpsWatcher()]);
}
