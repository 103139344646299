import React from 'react';

type RepeatIconProps = {
    className?: string;
};

const RepeatIcon: React.FC<RepeatIconProps> = ({ className }) => (
  <svg className={className} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.8125 4.25C2.87109 4.25 1.3125 5.83594 1.3125 7.75C1.3125 8.13281 1.01172 8.40625 0.65625 8.40625C0.273438 8.40625 0 8.13281 0 7.75C0 5.09766 2.13281 2.9375 4.8125 2.9375H8.72266V1.40625C8.72266 1.16016 8.85938 0.914062 9.10547 0.804688C9.35156 0.722656 9.65234 0.777344 9.84375 0.96875L12.0312 3.15625C12.2773 3.40234 12.2773 3.8125 12.0312 4.05859L9.84375 6.24609C9.65234 6.4375 9.35156 6.49219 9.10547 6.41016C8.85938 6.30078 8.72266 6.05469 8.72266 5.78125V4.25H4.8125ZM13.3438 7.09375C13.6992 7.09375 14 7.39453 14 7.75C14 10.4297 11.8398 12.5625 9.1875 12.5625H5.25V14.0938C5.25 14.3672 5.08594 14.6133 4.83984 14.7227C4.59375 14.8047 4.29297 14.75 4.12891 14.5586L1.91406 12.3711C1.66797 12.125 1.66797 11.7148 1.91406 11.4414L4.12891 9.25391C4.29297 9.08984 4.59375 9.03516 4.83984 9.11719C5.08594 9.22656 5.25 9.47266 5.25 9.71875V11.25H9.1875C11.1016 11.25 12.6875 9.69141 12.6875 7.75C12.6875 7.39453 12.9609 7.09375 13.3438 7.09375Z" fill="#333333" />
  </svg>
);

export default RepeatIcon;

RepeatIcon.defaultProps = {
  className: '',
};
