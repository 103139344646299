import React from 'react';

type StudentProjectsIconProps = {
  className?: string;
};

const StudentProjectsIcon: React.FC<StudentProjectsIconProps> = ({
  className,
}) => (
  <svg
    className={className}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="svg-light-mode"
      d="M18.75 0.75H5C4.29688 0.75 3.75 1.33594 3.75 2V15.125C3.75 15.4766 3.4375 15.75 3.125 15.75C2.77344 15.75 2.5 15.4766 2.5 15.125V3.25H1.25C0.546875 3.25 0 3.83594 0 4.5V15.75C0 17.1562 1.09375 18.25 2.5 18.25H17.5C18.8672 18.25 20 17.1562 20 15.75V2C20 1.33594 19.4141 0.75 18.75 0.75ZM10.625 15.75H6.875C6.52344 15.75 6.25 15.4766 6.25 15.125C6.25 14.8125 6.52344 14.5 6.875 14.5H10.625C10.9375 14.5 11.25 14.8125 11.25 15.125C11.25 15.4766 10.9375 15.75 10.625 15.75ZM10.625 12H6.875C6.52344 12 6.25 11.7266 6.25 11.375C6.25 11.0625 6.52344 10.75 6.875 10.75H10.625C10.9375 10.75 11.25 11.0625 11.25 11.375C11.25 11.7266 10.9375 12 10.625 12ZM16.875 15.75H13.125C12.7734 15.75 12.5 15.4766 12.5 15.125C12.5 14.8125 12.7734 14.5 13.125 14.5H16.875C17.1875 14.5 17.5 14.8125 17.5 15.125C17.5 15.4766 17.1875 15.75 16.875 15.75ZM16.875 12H13.125C12.7734 12 12.5 11.7266 12.5 11.375C12.5 11.0625 12.7734 10.75 13.125 10.75H16.875C17.1875 10.75 17.5 11.0625 17.5 11.375C17.5 11.7266 17.1875 12 16.875 12ZM17.5 7.625C17.5 7.97656 17.1875 8.25 16.875 8.25H6.875C6.52344 8.25 6.25 7.97656 6.25 7.625V3.875C6.25 3.5625 6.52344 3.25 6.875 3.25H16.875C17.1875 3.25 17.5 3.5625 17.5 3.875V7.625Z"
      fill="#176FFF"
    />
  </svg>
);

export default StudentProjectsIcon;

StudentProjectsIcon.defaultProps = {
  className: '',
};
