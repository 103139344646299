import React from 'react';
import { Toast } from '@gomycode/design-system';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '@gomycode/types-utils/Types/types';
import { switchProfileWithRedirection } from '../../../store/profile/actions';
import styles from './OnlineSessionCard.module.css';
import RightAngleIcon from '../../../shared/Icons/RightAngleIcon';
import onlineSessionImage from './onlineSessionCard.png';
import { OnlineSessions } from '../../../store/online/types';
import sessionInstancesActions from '../../../store/sessionInstance/actions';

interface IOnlineSessionCardProps {
  onlineSession: OnlineSessions | undefined;
}

const OnlineSessionCard: React.FC<IOnlineSessionCardProps> = ({
  onlineSession,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(
    (state: { profile: { profile: Profile } }) => state.profile.profile,
  );

  const onLinkClick = () => {
    if (profile.id === onlineSession?.instructor.id) {
      navigate(`/online-sessions/${onlineSession?.onlineSession.id}/active`);
    } else {
      dispatch(
        switchProfileWithRedirection({
          profileId: onlineSession?.instructor.id,
          redirectUrl: `/online-sessions/${onlineSession?.onlineSession.id}/active`,
        }),
      );
    }
  };

  return (
    <div
      onClick={() => dispatch(sessionInstancesActions.resetState())}
      className={`${styles.onlineSessionCard} ${
        styles.current
      }`}
    >
      <div className={styles.leftSection}>
        <p className={styles.trackName}>
          {onlineSession?.track?.name}
        </p>
        <p className={styles.description}>
          {`${moment(onlineSession?.onlineSession.startDate).format(
            'hh:mm A',
          )} — ${moment(onlineSession?.onlineSession.dueDate).format(
            'hh:mm A',
          )}`}
        </p>
        <button
          className={styles.link}
          type="button"
          onClick={onLinkClick}
        >
          Join session
          {' '}
          <RightAngleIcon className={styles.rightIcon} />
        </button>
      </div>
      <div>
        <div className={styles.sessionsContainer}>
          <p className={styles.sessionsContainer}>
            <p className={styles.sessions}>
              Sessions
              {' '}
              {onlineSession?.onlineSession.sessionNumber}
              /
              {onlineSession?.opening.numberOfSessions}
              {' '}
            </p>
            <Toast
              className={styles.toast}
              title="Now"
              withBorder={false}
              type="primary"
            />
          </p>
        </div>
        <img
          className={styles.image}
          src={onlineSessionImage}
          alt="online session"
        />
      </div>
    </div>
  );
};

export default OnlineSessionCard;
