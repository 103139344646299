import countries from './countries.data.json';

// eslint-disable-next-line import/prefer-default-export
export const getCurrencyByName = (name:string) => {
  const country = countries.find((e:{name:string}) => e.name === name);
  if (country) {
    return country.currency;
  }
  return undefined;
};
