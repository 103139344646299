import { generateEmptyAction } from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  fetchInstructorStats: generateEmptyAction(
    constants.fetchInstructorStats.request,
  ),
};

export default actions;
