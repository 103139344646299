import React from 'react';

import styles from './TotalGuildsCard.module.css';
import GraduationCapIcon from '../../../shared/Icons/GraduationCap';

interface ITotalGuildsCardProps {
  numberOfGuilds: number;
}

const TotalGuildsCard: React.FC<ITotalGuildsCardProps> = ({
  numberOfGuilds,
}) => (
  <div className={styles.totalGuildsCard}>
    <div className={styles.icon}>
      <GraduationCapIcon />
    </div>
    <div className={styles.amount}>{numberOfGuilds}</div>
    <div className={styles.title}>Guilds</div>
  </div>
);

export default TotalGuildsCard;
