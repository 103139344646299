/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import axiosInstance from '../../utils/axios-config';
import { requestHeader } from '../../utils/requestHeader';
import URL from '../endpoints';

export const createOnlineSessionResource = async (id: string, body: any) => {
  const formData = new FormData();
  if (!isEmpty(body.file)) formData.append('file', body.file);
  formData.append('name', body.name);
  formData.append('kind', body.kind);
  formData.append('dataOrLink', body.dataOrLink);
  const result = await axiosInstance.post(
    URL.storage.files.createOnlineSessionResource(id),
    formData,
    requestHeader({
      'Content-Type': 'multipart/form-data',
    }),
  );
  return result.data;
};

export default {
  createOnlineSessionResource,
};
