/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/Earnings/';
export default {
  fetchInstructorEarnings: generateActionTypes(
    root,
    'FETCH_INSTRUCTOR_EARNINGS',
  ),
  fetchInstructorEarningSession: generateActionTypes(
    root,
    'FETCH_INSTRUCTOR_EARNING_SESSION',
  ),
  fetchInstructorPayCheckById: generateActionTypes(
    root,
    'FETCH_PAYCHECK_BY_ID',
  ),
};
