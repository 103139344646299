import moment from 'moment';

export const calculateExpectedScore = (
  skillsCount: number,
  subscriptionDate: string,
  expirationDate: string,
) => {
  const subscription = moment(subscriptionDate);
  const expiration = moment(expirationDate);
  const trackDaysCount = expiration.diff(subscription, 'days') + 2;
  const spentDaysCount = moment().diff(moment(subscriptionDate), 'days') + 2;
  const skillduration = trackDaysCount / skillsCount;
  // Skill expected progress
  const expected = spentDaysCount / skillduration;

  const ExpectedProgress = Math.round((expected * 100) / skillsCount);

  // Percentage expected progress
  const percentageExpectedProgress = ExpectedProgress < 100 ? ExpectedProgress : 100;
  return percentageExpectedProgress;
};

export function calculateAveragePresence(
  numPresence: number,
  numSessions: number,
): number {
  if (numSessions === 0) {
    return 0;
  }
  const averagePercentage: number = (numPresence / numSessions) * 100;
  return averagePercentage;
}
export const activeKeys = {
  overall: '0',
  profile: '1',
  attendance: '2',
  labPhase: '3',
  checkpoint: '4',
  oto: '5',
  learning: '6',
};
