import {
  generateActionWithBody,
  generateActionWithBodyAndId,
  generateActionWithId,
  generateActionWithMultipleId,
  generateActionWithMultipleIdAndBody,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  getLabPhases: generateActionWithMultipleValues(
    constants.getLabPhases.request,
  ),
  fetchTopThreeStudents: generateActionWithMultipleValues(
    constants.fetchTopThreeStudents.request,
  ),
  fetchLabPhaseStudents: generateActionWithMultipleValues(
    constants.fetchLabPhaseStudents.request,
  ),
  fetchLabPhaseById: generateActionWithMultipleValues(
    constants.fetchLabPhaseById.request,
  ),
  getLabPhaseAssignmentsByID: generateActionWithMultipleId(
    constants.getLabPhaseAssignmentsByID.request,
  ),
  fetchLabPhaseStats: generateEmptyAction(constants.fetchLabPhaseStats.request),
  isRefuseModalOpen: generateEmptyAction(constants.isRefuseModalOpen.request),
  setIsRefuseModalClose: generateEmptyAction(
    constants.setIsRefuseModalClose.request,
  ),
  isApprovedModalOpen: generateEmptyAction(
    constants.isApprovedModalOpen.request,
  ),
  setIsApprovedModalClose: generateEmptyAction(
    constants.setIsApprovedModalClose.request,
  ),
  acceptRequest: generateActionWithMultipleIdAndBody(
    constants.acceptRequest.request,
  ),
  rejectRequest: generateActionWithMultipleIdAndBody(
    constants.rejectRequest.request,
  ),
  fetchLabPhaseAssignmentById: generateActionWithId(
    constants.fetchLabPhaseAssignmentById.request,
  ),
  fetchAllLabPhaseAssignments: generateActionWithMultipleValues(
    constants.fetchAllLabPhaseAssignments.request,
  ),

  fetchPhaseAssignmentById: generateActionWithMultipleId(
    constants.fetchPhaseAssignmentById.request,
  ),
  approveLabPhase: generateActionWithBodyAndId(
    constants.approveLabPhase.request,
  ),
  openFinalModal: generateActionWithBody(constants.openFinalModal.request),
  fetchReviews: generateActionWithId(constants.fetchReviews.request),
  rejectPhase: generateActionWithBodyAndId(constants.rejectPhase.request),
  setShowRejectionModal: generateActionWithBody(
    constants.setShowRejectionModal.request,
  ),
  approvePhase: generateActionWithBodyAndId(constants.approvePhase.request),
  setApprovedModalClose: generateEmptyAction(
    constants.setApprovedModalClose.request,
  ),
};

export default actions;
