import React from 'react';

type Props = {
  className?: string;
};
const EarningsIcon = ({ className }: Props) => (
  <svg
    width="23"
    height="19"
    viewBox="0 0 23 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.125 2.625C5.25 3.44531 8.375 2.66406 11.5 1.88281C14.8984 1.02344 18.2969 0.125 21.6953 1.41406C22.3203 1.64844 22.75 2.3125 22.75 3.01562V15.125C22.75 16.0625 21.7344 16.6875 20.8359 16.4141C17.7109 15.5938 14.5859 16.375 11.5 17.1562C8.0625 18.0156 4.66406 18.9141 1.26562 17.625C0.640625 17.3906 0.25 16.7266 0.25 16.0234V3.91406C0.25 2.97656 1.22656 2.35156 2.125 2.625ZM11.4609 13.25C13.2188 13.25 14.625 11.5703 14.625 9.5C14.625 7.42969 13.2188 5.75 11.4609 5.75C9.74219 5.75 8.33594 7.42969 8.33594 9.5C8.33594 11.5703 9.74219 13.25 11.4609 13.25ZM2.71094 15.75H5.21094C5.21094 14.3828 4.11719 13.25 2.71094 13.25V15.75ZM2.71094 5.125V7.625C4.11719 7.625 5.21094 6.53125 5.21094 5.125H2.71094ZM20.25 11.375C18.8438 11.375 17.75 12.5078 17.75 13.875H20.25V11.375ZM17.75 3.25C17.75 4.65625 18.8438 5.75 20.25 5.75V3.25H17.75Z"
      fill="#176FFF"
    />
  </svg>
);

export default EarningsIcon;

EarningsIcon.defaultProps = {
  className: '',
};
