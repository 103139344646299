import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getStudentAbsence = async (studentId: string, trackId: string) => {
  const result = await axiosInstance.get(
    URL.capacity.studentAbsences.getStudentAbsence(studentId, trackId),
  );
  return result.data;
};

export const getStudentAbsenceNew = async (
  studentId: string,
  trackId: string,
) => {
  const result = await axiosInstance.get(
    URL.capacity.studentAbsences.getStudentAbsenceNew(studentId, trackId),
  );
  return result.data;
};

export default {
  getStudentAbsence,
  getStudentAbsenceNew,
};
