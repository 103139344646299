import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

  type Query = {
    query: [];
  };

export const fetchAllSessionInstances = async (values: Query) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.attendance.sessionInstances.getAllSessionInstance
        + queryString,
  );
  return result.data;
};

export const fetchSessionInstanceById = async (id: string) => {
  const result = await axiosInstance.get(
    URL.attendance.sessionInstances.fetchSessionInstanceById(id),
  );
  return result.data;
};

  interface MarkPresenceByIdParam {
    sessionInstanceId: string;
    studentId: string;
    body: {
      presenceStatus: number;
      note: string;
      absentAt: string;
    };
  }

export const markPresenceById = async (values: MarkPresenceByIdParam) => {
  const { sessionInstanceId, studentId, body } = values;
  const result = await axiosInstance.put(
    URL.attendance.sessionInstances.markAbsenceById(sessionInstanceId, studentId),
    body,

  );
  return result.data;
};

export const completeSession = async (id: string) => {
  const result = await axiosInstance.put(
    URL.attendance.sessionInstances.completeSession(id),
    {},
  );

  return result.data;
};

export const fetchPresenceStats = async () => {
  const result = await axiosInstance.get(
    URL.attendance.sessionInstances.getStats,
  );

  return result.data;
};
export const getStudentSessionInstances = async (values: {
    query: [];
    studentId: string;
    groupId: string;
  }) => {
  const { query, studentId, groupId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.attendance.sessionInstances.getStudentSessionInstances(
      studentId,
      groupId,
    )
        + queryString,
  );

  return result.data;
};
export const getStudentAverageAbsence = async (values: {
    query: [];
    studentId: string;
    groupId: string;
  }) => {
  const { query, studentId, groupId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.attendance.sessionInstances.getStudentAverageAbsence(
      studentId,
      groupId,
    )
        + queryString,
  );

  return result.data;
};

export default {
  fetchAllSessionInstances,
  fetchSessionInstanceById,
  markPresenceById,
  completeSession,
  fetchPresenceStats,
  getStudentSessionInstances,
  getStudentAverageAbsence,
};
