import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

type AllCheckPointsValues = {
    query: [];
  };

export const getAllCheckpoints = async (values: AllCheckPointsValues) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.checkpoints.getAllCheckpoints
        + queryString,
  );

  return result.data;
};

export const getCheckpointById = async (id: string) => {
  const result = await axiosInstance.get(
    URL.instructor.checkpoints.getCheckpointById(id),
  );

  return result.data;
};

export const addReview = async (id: string, body: {}) => {
  const result = await axiosInstance.post(
    URL.instructor.checkpoints.addReview(id),
    { ...body },
  );
  return result.data;
};

export const approveCheckpoint = async (id: string, body: {}) => {
  const result = await axiosInstance.post(
    URL.instructor.checkpoints.approveCheckpoint(id),
    { ...body },
  );
  return result.data;
};

export const getOrderedCheckpoints = async (values: {
  query: [];
  trackId: string;
}) => {
  const { trackId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.checkpoints.getOrderedCheckpoints(trackId)
        + queryString,
  );

  return result.data;
};

export default {
  getAllCheckpoints,
  getCheckpointById,
  addReview,
  approveCheckpoint,
  getOrderedCheckpoints,
};
