import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const patchBookMark = async (values: {
  query: [];
  trackId: string;
  nodeId: string;
}) => {
  const { trackId, nodeId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.patch(
    URL.instructor.tracks.patchBookMark(trackId, nodeId)
        + queryString,
    null,
  );

  return result.data;
};
export const getBookMarkedNodes = async (
  values: {
    query: [];
    trackId: string;
  },
) => {
  const { trackId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.tracks.getBookMarkedNodes(trackId)
        + queryString,
  );

  return result.data;
};

export const getResources = async (values: {
  query: [];
  trackId: string;
}) => {
  const { trackId, query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.instructor.tracks.getResources(trackId)
        + queryString,
  );

  return result.data;
};

export const patchResources = async (values: {
  query: [];
  trackId: string;
  body: {}
}) => {
  const { trackId, query, body } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.patch(
    URL.instructor.tracks.patchResources(trackId)
        + queryString,
    body,
  );

  return result.data;
};

export const fetchAllTracks = async () => {
  const result = await axiosInstance.get(
    URL.instructor.tracks.getTracks,
  );

  return result.data;
};

export default {
  patchBookMark,
  getBookMarkedNodes,
  getResources,
  patchResources,
  fetchAllTracks,
};
