/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, encodeArchival, encodeLimit, encodeSkip,
} from '@gomycode/design-system';
import moment from 'moment';
import styles from './StudentsListCard.module.css';
import StudentCard from './StudentCard';
import { getAllStudents } from '../../../store/Student/actions';

import { encodeFields } from '../../../shared/utils/queryHelper';
import { fetchStudents } from '../helpers';
import { StudentListApiResponse } from '../../../store/Student/types';

interface IStudentsListCardProps {
  allStudents: StudentListApiResponse[];
}

const StudentsListCard: React.FC<IStudentsListCardProps> = ({
  allStudents,
}) => {
  const studentHasMore = useSelector(
    (state: { student: { hasMore: boolean } }) => state.student?.hasMore,
  );
  const dispatch = useDispatch();
  const studentPaginationIndex = useSelector(
    (state: { student: { paginationIndex: number } }) => state.student?.paginationIndex,
  );
  const loading = useSelector(
    (state: { student: { fetchingStudentsListLoading: boolean } }) => state.student?.fetchingStudentsListLoading,
  );
  const loadMoreStudents = () => {
    dispatch(
      getAllStudents({
        query: [
          encodeLimit(12),
          encodeSkip(12 * studentPaginationIndex),
          encodeArchival(false),
          encodeFields(fetchStudents),
          { key: 'expirationDate', value: moment().toISOString(), operator: 'gt' },
        ],

      }),
    );
  };
  return (
    <div className={styles.studentsListCard}>
      <p className={styles.title}>My Students</p>
      <div className={styles.studentsCardContainer}>
        {allStudents
          .map((student) => (
            <StudentCard student={student} />
          ))}
        {studentHasMore && (
        <Button
          onClick={() => {
            loadMoreStudents();
          }}
          className={styles.loadMoreButton}
          priority="primary"
          loading={loading}
        >
          Load more
        </Button>
        )}
      </div>
    </div>
  );
};

export default StudentsListCard;
