import React from 'react';

type OneToOneIconProps = {
  className?: string;
};

const OneToOneIcon: React.FC<OneToOneIconProps> = ({ className }) => (
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.25 10.5V3C16.25 1.63281 15.1172 0.5 13.75 0.5H2.5C1.09375 0.5 0 1.63281 0 3V10.5C0 11.9062 1.09375 13 2.5 13L3.75 13.0391V15.1484C3.75 15.4609 4.10156 15.6172 4.33594 15.4609L7.57812 13.0391L13.75 13C15.1172 13 16.25 11.9062 16.25 10.5ZM22.5 5.5H17.5V10.5C17.5 12.5703 15.7812 14.25 13.75 14.25H10V15.5C10 16.9062 11.0938 18 12.4609 18H17.3438L20.5859 20.4219C20.8594 20.6172 21.25 20.4609 21.25 20.1484V18H22.5C23.8672 18 25 16.9062 25 15.5391V8C25 6.63281 23.8672 5.5 22.5 5.5Z"
      fill="#176FFF"
    />
  </svg>
);

export default OneToOneIcon;

OneToOneIcon.defaultProps = {
  className: '',
};
