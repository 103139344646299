import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import constants from './constants';
import * as api from '../../shared/services/online/sessions';
import * as storageApi from '../../shared/services/storage/files';

export function* fetchActiveOnlineSessions(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchActiveOnlineSessions> = yield call(api.fetchActiveOnlineSessions, action.values);
    yield put({
      type: constants.fetchActiveOnlineSessions.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchActiveOnlineSessions.failure,
      e,
    });
  }
}

export function* fetchActiveOnlineSessionsWatcher() {
  yield takeEvery(constants.fetchActiveOnlineSessions.request, fetchActiveOnlineSessions);
}

export function* fetchActiveOnlineSessionsV2(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchActiveOnlineSessionsV2> = yield call(api.fetchActiveOnlineSessionsV2, action.values);
    yield put({
      type: constants.fetchActiveOnlineSessionsV2.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchActiveOnlineSessionsV2.failure,
      e,
    });
  }
}

export function* fetchActiveOnlineSessionsV2Watcher() {
  yield takeEvery(constants.fetchActiveOnlineSessionsV2.request, fetchActiveOnlineSessionsV2);
}

export function* fetchAllOnlineSessions(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllOnlineSessions> = yield call(api.fetchAllOnlineSessions, action.values);
    yield put({
      type: constants.fetchAllOnlineSessions.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllOnlineSessions.failure,
      e,
    });
  }
}

export function* fetchAllOnlineSessionsWatcher() {
  yield takeEvery(constants.fetchAllOnlineSessions.request, fetchAllOnlineSessions);
}

export function* fetchAllOnlineSessionsWithoutPagination(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllOnlineSessions> = yield call(api.fetchAllOnlineSessions, action.values);
    yield put({
      type: constants.fetchAllOnlineSessionsWithoutPagination.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllOnlineSessionsWithoutPagination.failure,
      e,
    });
  }
}

export function* fetchAllOnlineSessionsWithoutPaginationWatcher() {
  yield takeEvery(constants.fetchAllOnlineSessionsWithoutPagination.request, fetchAllOnlineSessionsWithoutPagination);
}

export function* fetchOnlineSessionById(action: {
  type: string;
  values: {
    id: string;
  }
}) {
  try {
    const result: ReturnType<typeof api.fetchOnlineSessionById> = yield call(api.fetchOnlineSessionById, action.values);
    yield put({
      type: constants.fetchOnlineSessionById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchOnlineSessionById.failure,
      e,
    });
  }
}

export function* fetchOnlineSessionByIdWatcher() {
  yield takeEvery(constants.fetchOnlineSessionById.request, fetchOnlineSessionById);
}

export function* showResourcesModal(action: { type: string; body: boolean }) {
  try {
    yield put({
      type: constants.showResourcesModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.showResourcesModal.failure,
      e,
    });
  }
}

export function* showResourcesModalWatcher() {
  yield takeEvery(constants.showResourcesModal.request, showResourcesModal);
}

export function* createOnlineSessionResource(action: {
  type: string;
  id: string
  body: {}
}) {
  try {
    const result: ReturnType<typeof storageApi.createOnlineSessionResource> = yield call(storageApi.createOnlineSessionResource, action.id, action.body);
    yield put({
      type: constants.createOnlineSessionResource.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.createOnlineSessionResource.failure,
      e,
    });
  }
}

export function* createOnlineSessionResourceWatcher() {
  yield takeEvery(constants.createOnlineSessionResource.request, createOnlineSessionResource);
}

export function* showNotesModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.showNotesModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.showNotesModal.failure,
      e,
    });
  }
}

export function* showNotesModalWatcher() {
  yield takeEvery(constants.showNotesModal.request, showNotesModal);
}

export function* updateOnlineSessionById(action: {
  type: string;
  id: string;
  body: {};
}) {
  try {
    const result: ReturnType<typeof api.UpdateOnlineSessionById> = yield call(api.UpdateOnlineSessionById, action.id, action.body);
    yield put({
      type: constants.updateOnlineSessionById.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.updateOnlineSessionById.failure,
      e,
    });
  }
}

export function* updateOnlineSessionByIdWatcher() {
  yield takeEvery(constants.updateOnlineSessionById.request, updateOnlineSessionById);
}

export default function* onlineSaga() {
  yield all([
    fetchActiveOnlineSessionsWatcher(),
    fetchAllOnlineSessionsWatcher(),
    fetchOnlineSessionByIdWatcher(),
    showResourcesModalWatcher(),
    createOnlineSessionResourceWatcher(),
    showNotesModalWatcher(),
    updateOnlineSessionByIdWatcher(),
    fetchAllOnlineSessionsWithoutPaginationWatcher(),
    fetchActiveOnlineSessionsV2Watcher(),
  ]);
}
