import { Reducer } from 'redux';
import { CastLabPhase } from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { LabPhasesState } from './types';

const initialState: LabPhasesState = {
  loading: false,
  labPhases: [],
  isRefuseModalOpen: false,
  isApprovedModalOpen: false,
  allStudents: [],
  phaseAssignmentById: {},
  oneLabPhase: {
    labPhase: CastLabPhase(),
    phases: [],
  },
  studentLabPhaseAssignment: {},
  studentLabPhaseAssignmentLoading: false,
  fetchPhaseAssignmentByIdLoading: false,
  allLabPhaseAssignments: [],
  reviews: [],
  showRejectionModal: false,
  rejectPhaseLoading: false,
  topStudents: [],
  LabPhaseAssignmentsByID: {},
  getLabPhaseAssignmentsByIDLoading: false,
  allLabPhaseAssignmentsLoading: false,
  fetchTopThreeStudentsLoading: false,
  fetchAllStudentsLoading: false,
  fetchLabPhaseByIdLoading: false,
  stats: {
    invitations: 0,
    submitted: 0,
    rejected: 0,
    phasesConcluded: 0,
    closed: 0,
    totalAssignments: 0,
  },
  fetchLabPhaseStatsLoading: false,
  approvePhaseLoading: false,
  showApprovalModal: false,
  fetchReviewsLoading: false,
  approveLabPhaseLoading: false,
  approveLabPhaseErrors: [],
  finalModal: false,
};

// eslint-disable-next-line default-param-last, consistent-return
const reducer: Reducer<LabPhasesState | undefined> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getLabPhases.request:
      return {
        ...state,
        loading: true,
      };
    case constants.getLabPhases.success:
      return {
        ...state,
        loading: false,
        labPhases: action.data.model,
      };
    case constants.fetchLabPhaseStudents.request:
      return {
        ...state,
        fetchAllStudentsLoading: true,
      };

    case constants.fetchLabPhaseStudents.success:
      return {
        ...state,
        allStudents: action.data.model,
        fetchAllStudentsLoading: false,
      };

    case constants.fetchLabPhaseStudents.failure:
      return {
        ...state,
        fetchAllStudentsLoading: false,
      };
    case constants.fetchLabPhaseStats.request:
      return {
        ...state,
        fetchLabPhaseStatsLoading: true,
      };

    case constants.fetchLabPhaseStats.success:
      return {
        ...state,
        stats: action.data.model,
        fetchLabPhaseStatsLoading: false,
      };

    case constants.fetchLabPhaseStats.failure:
      return {
        ...state,
        fetchLabPhaseStatsLoading: false,
      };
    case constants.fetchTopThreeStudents.request:
      return {
        ...state,
        fetchTopThreeStudentsLoading: true,
      };

    case constants.fetchTopThreeStudents.success:
      return {
        ...state,
        topStudents: action.data.model,
        fetchTopThreeStudentsLoading: false,
      };

    case constants.fetchTopThreeStudents.failure:
      return {
        ...state,
        fetchTopThreeStudentsLoading: false,
      };
    case constants.fetchLabPhaseById.request:
      return {
        ...state,
        fetchLabPhaseByIdLoading: true,
      };

    case constants.fetchLabPhaseById.success:
      return {
        ...state,
        oneLabPhase: action.data.model,
        fetchLabPhaseByIdLoading: false,
      };

    case constants.fetchLabPhaseById.failure:
      return {
        ...state,
        fetchLabPhaseByIdLoading: false,
      };
    case constants.isRefuseModalOpen.success:
      return {
        ...state,
        isRefuseModalOpen: true,
      };

    case constants.isApprovedModalOpen.request:
      return {
        ...state,
        isApprovedModalOpen: true,
      };

    case constants.setIsApprovedModalClose.request:
      return {
        ...state,
        isApprovedModalOpen: false,
      };
    case constants.getLabPhaseAssignmentsByID.request:
      return {
        ...state,
        fetchLabPhaseByIdLoading: true,
      };

    case constants.getLabPhaseAssignmentsByID.success:
      return {
        ...state,
        LabPhaseAssignmentsByID: action.data.model,
        getLabPhaseAssignmentsByIDLoading: false,
      };

    case constants.getLabPhaseAssignmentsByID.failure:
      return {
        ...state,
        getLabPhaseAssignmentsByIDLoading: false,
      };
    case constants.acceptRequest.success:
      return {
        ...state,
        isApprovedModalOpen: true,
      };
    case constants.rejectRequest.success:
      // eslint-disable-next-line no-param-reassign
      state.isRefuseModalOpen = false;
      window.location.pathname = '/student-projects';
      break;
    case constants.fetchLabPhaseAssignmentById.request:
      return {
        ...state,
        studentLabPhaseAssignmentLoading: true,
      };

    case constants.fetchLabPhaseAssignmentById.success:
      return {
        ...state,
        studentLabPhaseAssignment: action.data.model,
        studentLabPhaseAssignmentLoading: false,
      };

    case constants.fetchLabPhaseAssignmentById.failure:
      return {
        ...state,
        studentLabPhaseAssignmentLoading: false,
      };
    case constants.fetchAllLabPhaseAssignments.request:
      return {
        ...state,
        allLabPhaseAssignmentsLoading: true,
      };

    case constants.fetchAllLabPhaseAssignments.success:
      return {
        ...state,
        allLabPhaseAssignments: action.data.model,
        allLabPhaseAssignmentsLoading: false,
      };

    case constants.fetchAllLabPhaseAssignments.failure:
      return {
        ...state,
        allLabPhaseAssignmentsLoading: false,
      };
    case constants.fetchPhaseAssignmentById.request:
      return {
        ...state,
        fetchPhaseAssignmentByIdLoading: true,
      };

    case constants.fetchPhaseAssignmentById.success:
      return {
        ...state,
        phaseAssignmentById: action.data.model,
        fetchPhaseAssignmentByIdLoading: false,
      };

    case constants.fetchPhaseAssignmentById.failure:
      return {
        ...state,
        fetchPhaseAssignmentByIdLoading: false,
      };
    case constants.approveLabPhase.request:
      return {
        ...state,
        approveLabPhaseLoading: true,
      };
    case constants.approveLabPhase.success:
      return {
        ...state,
        finalModal: true,
        approveLabPhaseLoading: false,
      };
    case constants.approveLabPhase.failure:
      return {
        ...state,
        approveLabPhaseLoading: false,
        approveLabPhaseErrors: action.arrayErrors,
      };

    case constants.openFinalModal.success:
      return {
        ...state,
        finalModal: true,
      };

    case constants.fetchReviews.request:
      return {
        ...state,
        fetchReviewsLoading: true,
      };

    case constants.fetchReviews.success:
      return {
        ...state,
        reviews: action.data.model,
        fetchReviewsLoading: false,
      };

    case constants.fetchReviews.failure:
      return {
        ...state,
        fetchReviewsLoading: false,
      };
    case constants.rejectPhase.request:
      return {
        ...state,
        rejectPhaseLoading: true,
      };

    case constants.rejectPhase.success:
      return {
        ...state,
        showRejectionModal: true,
        rejectPhaseLoading: false,
      };

    case constants.rejectPhase.failure:
      return {
        ...state,
        rejectPhaseLoading: false,
      };
    case constants.setIsRefuseModalClose.success:
      return {
        ...state,
        showRejectionModal: false,
        isRefuseModalOpen: false,
      };
    case constants.setApprovedModalClose.success:
      return {
        ...state,
        showApprovalModal: false,
      };

    case constants.approvePhase.request:
      return {
        ...state,
        approvePhaseLoading: true,
      };

    case constants.approvePhase.success:
      return {
        ...state,
        show: true,
        showApprovalModal: true,
        approvePhaseLoading: false,
      };

    case constants.approvePhase.failure:
      return {
        ...state,
        approvePhaseLoading: false,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as LabPhasesReducer };
