import React from 'react';

type UserGraduateProps = {
  className?: string;
};

const UserGraduateIcon: React.FC<UserGraduateProps> = ({ className }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.5 14.5C14.5 15.3438 13.8125 16 12.9688 16H1.96875C1.15625 16 0.5 15.3438 0.5 14.5C0.40625 11.7812 2.34375 9.46875 4.9375 9L7.5 12.4375L10.0312 9C12.625 9.5 14.5625 11.7812 14.5 14.5ZM2 14.5H7.1875L4.34375 10.75C2.90625 11.375 1.9375 12.8125 2 14.4688V14.5ZM12.9688 14.5V14.4688C13.0312 12.8125 12.0625 11.375 10.625 10.75L7.78125 14.5H12.9688ZM0.90625 2.5C0.34375 2.375 0.34375 1.65625 0.90625 1.53125L6.875 0.09375C7.0625 0.03125 7.28125 0 7.5 0C7.6875 0 7.90625 0.03125 8.09375 0.09375L14.0625 1.53125C14.625 1.65625 14.625 2.375 14.0625 2.5L11.5 3.125V4.5C11.5 6.71875 9.6875 8.5 7.5 8.5C5.28125 8.5 3.5 6.71875 3.5 4.5V3.125L1.875 2.75V4.375C2.09375 4.5 2.25 4.75 2.25 5C2.25 5.28125 2.09375 5.5 1.875 5.625L2.375 7.59375C2.4375 7.78125 2.3125 8 2.125 8H0.84375C0.65625 8 0.53125 7.78125 0.59375 7.5625L1.09375 5.625C0.875 5.5 0.75 5.28125 0.75 5C0.75 4.75 0.90625 4.5 1.125 4.375V2.5625L0.90625 2.5ZM5 3.5V4.5C5 5.90625 6.09375 7 7.5 7C8.875 7 10 5.90625 10 4.5V3.5L8.09375 3.9375C7.6875 4.03125 7.28125 4.03125 6.875 3.9375L5 3.5Z"
      fill="#828A92"
    />
  </svg>
);

export default UserGraduateIcon;

UserGraduateIcon.defaultProps = {
  className: '',
};
