import React from 'react';

type SunIconProps = {
  className?: string;
};
const SunIcon = ({ className }: SunIconProps) => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 4.25C8.24219 4.25 9.22656 5.28125 9.22656 6.5C9.22656 7.74219 8.24219 8.75 7 8.75C5.73438 8.75 4.75 7.69531 4.75 6.47656C4.75 5.25781 5.73438 4.25 7 4.25ZM12.9297 8.63281C13.0703 8.86719 12.9531 9.14844 12.6953 9.19531L10.1406 9.66406L9.67188 12.2188C9.625 12.4766 9.34375 12.5938 9.13281 12.4531L7 10.9766L4.84375 12.4531C4.63281 12.5938 4.35156 12.4766 4.30469 12.2188L3.83594 9.66406L1.28125 9.19531C1.02344 9.14844 0.90625 8.86719 1.04688 8.65625L2.52344 6.5L1.04688 4.36719C0.90625 4.15625 1.02344 3.875 1.28125 3.82812L3.83594 3.35938L4.28125 0.804688C4.32812 0.546875 4.60938 0.429688 4.84375 0.570312L7 2.04688L9.13281 0.546875C9.34375 0.40625 9.625 0.523438 9.67188 0.78125L10.1406 3.33594L12.6953 3.80469C12.9531 3.85156 13.0703 4.13281 12.9297 4.36719L11.4531 6.5L12.9297 8.63281ZM7 9.5C8.64062 9.5 9.97656 8.16406 9.97656 6.5C9.97656 4.88281 8.61719 3.52344 7 3.52344C5.35938 3.52344 4 4.85938 4 6.5C4 8.14062 5.33594 9.5 7 9.5Z"
      fill="#FFE86E"
    />
  </svg>
);
export default SunIcon;
SunIcon.defaultProps = {
  className: '',
};
