import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getStudentLearnings = async (
  studentId: string,
  trackID: string,
) => {
  const result = await axiosInstance.get(
    URL.stats.students.getLearnings(studentId, trackID),
  );

  return result.data;
};

export const fetchStudentsPerformanceById = async (values: {
    query: [];
    trackId: string;
    studentId: string;
  }) => {
  const { query, trackId, studentId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.stats.students.fetchStudentsPerformanceById(studentId, trackId)
        + queryString,
  );
  return result.data;
};

export default {
  getStudentLearnings,
  fetchStudentsPerformanceById,
};
