import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getLeaderBoardStudents = async (values: {
    query: [];
    trackId: string;
  }) => {
  const { query, trackId } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.ranked.rank.getLeaderBoardStudents(trackId)
        + queryString,
  );
  return result.data;
};

export default {
  getLeaderBoardStudents,
};
