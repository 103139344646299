import React from 'react';
import { Avatar, Progress } from 'antd';
import { Link } from 'react-router-dom';

import styles from './StudentCard.module.css';
import SuperSkillIcon from '../../../../shared/Icons/SuperSkill';
import { calculateExpectedScore } from '../../../StudentProfile/helper';
import { StudentListApiResponse } from '../../../../store/Student/types';

interface IStudentCardProps {
  student: StudentListApiResponse;
}

const StudentCard: React.FC<IStudentCardProps> = ({ student }) => {
  const renderProgressColor = () => {
    const progress = Number(
      (student?.subscribedToRelationships?.progress * 100).toFixed(),
    );
    const expectedProgress = calculateExpectedScore(
      student.track?.skillsCount,
      student.subscribedToRelationships.dateEffective,
      student.subscribedToRelationships.expirationDate,
    );
    if (progress >= expectedProgress) {
      return '#4CCD59';
    }
    if (expectedProgress - progress < 20 && expectedProgress - progress !== 0) {
      return '#176FFF';
    }
    if (expectedProgress - progress > 20 && expectedProgress - progress <= 40) {
      return '#FDA04B';
    }
    if (expectedProgress - progress > 40) {
      return '#E55353';
    }
    return '#E55353';
  };

  return (
    <div className={styles.studentCard}>
      <Avatar src={student.student.picture} className={styles.avatar} size={48} />
      <div className={styles.body}>
        <div className={styles.upperSection}>
          <p className={styles.studentName}>{student.student.fullName}</p>
          <p className={styles.percentage}>
            {Number(
              (student?.subscribedToRelationships?.progress * 100).toFixed(),
            )}
            %
          </p>
          <Link
            className={styles.link}
            to={`students/${student.student.id}/tracks/${student.track.id}/groups/${student?.groups.id}`}
          >
            Student Profile
          </Link>
        </div>
        <div className={styles.lowerSection}>
          <p className={styles.superSkillName}>
            <SuperSkillIcon />
            {' '}
            {student.track.name}
          </p>
          <div className={styles.progressWrapper}>
            <Progress
              strokeColor={renderProgressColor()}
              showInfo={false}
              percent={Number(
                (student?.subscribedToRelationships?.progress * 100).toFixed(),
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
