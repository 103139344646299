import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/groups/';

const constants = {
  fetchAllGroups: generateActionTypes(root, 'FETCH_ALL_GROUPS'),
  fetchGroupById: generateActionTypes(root, 'FETCH_GROUP_BY_ID'),
  fetchAllActiveGroups: generateActionTypes(root, 'FETCH_ALL_ACTIVE_GROUPS'),
};

export default constants;
