import { Reducer } from 'redux';
import {
  CastGroup, CastInstructorParticipation, CastOnlineSession, CastOpening, CastProfile, CastTrack,
} from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { OnlineState } from './types';

const initialState: OnlineState = {
  loading: false,
  onlineSessions: null,
  allOnlineSessions: [],
  onlineSessionsV2: null,
  fetchOnlineSessionsV2Loading: false,
  paginationIndex: 0,
  hasMore: false,
  currentOnlineSession: {
    onlineSession: CastOnlineSession(),
    track: CastTrack(),
    opening: CastOpening(),
    instructor: CastProfile(),
    students: [],
    studentsParticipation: [],
    instructorParticipation: CastInstructorParticipation(),
    resources: [],
    group: CastGroup(),
  },
  showResourcesModal: false,
  createResourceLoading: false,
  shouldRefetchSession: false,
  showNotesModal: false,
  editSessionLoading: false,
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<OnlineState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.fetchActiveOnlineSessions.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchActiveOnlineSessions.success:
      return {
        ...state,
        loading: false,
        onlineSessions: action.data.model,
      };
    case constants.fetchActiveOnlineSessionsV2.request:
      return {
        ...state,
        fetchOnlineSessionsV2Loading: true,
      };
    case constants.fetchActiveOnlineSessionsV2.success:
      return {
        ...state,
        fetchOnlineSessionsV2Loading: false,
        onlineSessionsV2: action.data.model,
      };
    case constants.fetchActiveOnlineSessionsV2.failure:
      return {
        ...state,
        fetchOnlineSessionsV2Loading: false,
      };
    case constants.fetchAllOnlineSessionsWithoutPagination.request:
    case constants.fetchAllOnlineSessions.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchAllOnlineSessionsWithoutPagination.success:
      return {
        ...state,
        loading: false,
        allOnlineSessions: action.data.model,
      };
    case constants.fetchAllOnlineSessions.success:
      if (state.paginationIndex === 0) {
        return {
          ...state,
          loading: false,
          allOnlineSessions: action.data.model,
          paginationIndex: state.paginationIndex + 1,
          hasMore: action.data.model.length === 10,
        };
      }
      return {
        ...state,
        allOnlineSessions: [...state.allOnlineSessions, ...action.data.model],
        loading: false,
        paginationIndex: state.paginationIndex + 1,
        hasMore: action.data.model.length === 10,
      };

    case constants.fetchOnlineSessionById.request:
      return {
        ...state,
        loading: true,
        shouldRefetchSession: false,
      };
    case constants.fetchOnlineSessionById.success:
      return {
        ...state,
        loading: false,
        currentOnlineSession: action.data.model,
      };

    case constants.showResourcesModal.success:
      return {
        ...state,
        showResourcesModal: action.data,
      };

    case constants.createOnlineSessionResource.request:
      return {
        ...state,
        createResourceLoading: true,
      };
    case constants.createOnlineSessionResource.success:
      return {
        ...state,
        createResourceLoading: false,
        shouldRefetchSession: true,
        showResourcesModal: false,
      };

    case constants.showNotesModal.success:
      return {
        ...state,
        showNotesModal: action.data,
      };

    case constants.updateOnlineSessionById.request:
      return {
        ...state,
        editSessionLoading: true,
      };
    case constants.updateOnlineSessionById.success:
      return {
        ...state,
        editSessionLoading: false,
        shouldRefetchSession: true,
        showNotesModal: false,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as onlineReducer };
