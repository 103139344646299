import React from 'react';

type AssessmentIconProps = {
  className?: string;
};

const AssessmentIcon: React.FC<AssessmentIconProps> = ({ className }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.90625 0.429688C4.16016 0.429688 4.375 0.644531 4.375 0.898438C4.375 1.17188 4.16016 1.36719 3.90625 1.36719H3.75V7.61719C3.75 8.49609 3.04688 9.17969 2.1875 9.17969C1.30859 9.17969 0.625 8.49609 0.625 7.61719V1.36719H0.46875C0.195312 1.36719 0 1.17188 0 0.898438C0 0.644531 0.195312 0.429688 0.46875 0.429688H3.90625ZM2.8125 4.80469V1.36719H1.5625V4.80469H2.8125ZM9.53125 0.429688C9.78516 0.429688 10 0.644531 10 0.898438C10 1.17188 9.78516 1.36719 9.53125 1.36719H9.375V7.61719C9.375 8.49609 8.67188 9.17969 7.8125 9.17969C6.93359 9.17969 6.25 8.49609 6.25 7.61719V1.36719H6.09375C5.82031 1.36719 5.625 1.17188 5.625 0.898438C5.625 0.644531 5.82031 0.429688 6.09375 0.429688H9.53125ZM8.4375 4.80469V1.36719H7.1875V4.80469H8.4375Z"
      fill="#828A92"
    />
  </svg>
);

export default AssessmentIcon;

AssessmentIcon.defaultProps = {
  className: '',
};
