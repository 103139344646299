import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const fetchOneToOnePreviewMode = async (
  trackId: string,
  oneToOneAssignmentId: string,
) => {
  const result = await axiosInstance.get(
    URL.learn.oneToOnes.fetchOneToOnePreviewMode(
      trackId,
      oneToOneAssignmentId,
    ),
  );
  return result.data;
};

export default {
  fetchOneToOnePreviewMode,
};
