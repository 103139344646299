import { SessionInstanceApiReponseItem } from '../../store/sessionInstance/types';

type ResultType = {
  [key: string]: SessionInstanceApiReponseItem[];
};

export const mergeSessionInstancesByGroup = (
  sessionInstances: SessionInstanceApiReponseItem[],
): ResultType => {
  const result: ResultType = {};
  sessionInstances.forEach((sessionInstance) => {
    if (!result[sessionInstance.group.id]) {
      result[sessionInstance.group.id] = [sessionInstance];
    } else {
      result[sessionInstance.group.id] = [
        ...result[sessionInstance.group.id],
        sessionInstance,
      ];
    }
  });

  return result;
};

export const encodeSessionInstancesFields = {
  sessionInstance: {
    status: '',
    isOnline: '',
    id: '',
  },
  group: {
    id: '',
  },
};
export const fetchStudents = {
  student: {
    email: '',
    fullName: '',
    id: '',
    picture: '',
  },
  groups: [{ name: '', id: '' }],
  subscribedToRelationships: [
    {
      dateEffective: '',
      expirationDate: '',
      progress: 0,
      hasDiploma: false,
    },
  ],
  productSchedules: [{ name: '' }],
  tracks: [
    {
      id: '',
      name: '',
      skillsCount: 0,
      checkpointCount: 0,
      automatedOneToOneCount: 0,
      notAutomatedOneToOneCount: 0,
    },
  ],
  studentRecords: [
    {
      numberOfCompletedCheckpoints: 0,
      numberOfClosedAutomatedOneToOneMeetings: 0,
      numberOfClosedNotAutomatedOneToOneMeetings: 0,
      averageCheckpointScore: 0,
      averageLearningScore: 0,
      averageOneToOneMeetingScore: 0,
    },
  ],
  diploma: [],
  labPhaseAssignments: [],
};
export const fetchOnlineSessionFields = {
  onlineSession: {
    hostRoomUrl: '',
    id: '',
    sessionNumber: '',
    startDate: '',
    dueDate: '',
  },
  track: {
    name: '',
  },
  opening: {
    numberOfSessions: '',
  },
  instructor: {
    id: '',
  },
};
