import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { Profile } from '@gomycode/types-utils/Types/types';
import constants from './constants';
import * as authApi from '../../shared/services/auth/auth';
import * as messaginApi from '../../shared/services/messaging/discordServers';
import * as profileApi from '../../shared/services/account/profiles';
import * as instructorApi from '../../shared/services/instructor/profiles';
import * as operationsApi from '../../shared/services/operations/locations';

export function* getProfile() {
  try {
    const result: ReturnType<typeof profileApi.getProfileByToken> = yield call(profileApi.getProfileByToken);
    yield put({
      type: constants.getProfile.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getProfile.failure,
      e,
    });
  }
}

export function* getProfileWatcher() {
  yield takeEvery(constants.getProfile.request, getProfile);
}

export function* fetchDiscordURLs() {
  try {
    const result: ReturnType<typeof messaginApi.fetchDiscordURLs> = yield call(messaginApi.fetchDiscordURLs);
    yield put({
      type: constants.fetchDiscordURLs.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchDiscordURLs.failure,
      e,
    });
  }
}

export function* fetchDiscordURLsWatcher() {
  yield takeEvery(constants.fetchDiscordURLs.request, fetchDiscordURLs);
}

export function* updateProfile(action: {
  type: string;
  body: Profile;
}) {
  try {
    const result: ReturnType<typeof profileApi.updateProfile> = yield call(profileApi.updateProfile, action.body);
    yield put({
      type: constants.updateProfile.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.updateProfile.failure,
      e,
    });
  }
}

export function* updateProfileWatcher() {
  yield takeEvery(constants.updateProfile.request, updateProfile);
}

export function* toggleShowSuccessModal(action: {
  type: string;
  body: boolean;
}) {
  try {
    yield put({
      type: constants.toggleShowSuccessModal.success,
      data: action.body,
    });
  } catch (e) {
    yield put({
      type: constants.toggleShowSuccessModal.failure,
      e,
    });
  }
}

export function* toggleShowSuccessModalWatcher() {
  yield takeEvery(constants.toggleShowSuccessModal.request, toggleShowSuccessModal);
}

function* changePasswordSaga(action: {
  type: string;
  body: {
    password: string;
    newPassword: string;
  }
}) {
  try {
    yield call(authApi.changePassword, action.body);
    yield put({ type: constants.changePassword.success });
  } catch (e) {
    yield put({
      type: constants.changePassword.failure,
      e,
    });
  }
}

function* changePasswordWatcher() {
  yield takeEvery(constants.changePassword.request, changePasswordSaga);
}

function* getProfilesList(action: {
  type: string;
  body: string;
}) {
  try {
    const result: ReturnType<typeof profileApi.getProfilesList> = yield call(profileApi.getProfilesList, action.body);
    yield put({
      type: constants.getProfilesList.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.getProfilesList.failure,
      e,
    });
  }
}

function* getProfilesListWatcher() {
  yield takeEvery(constants.getProfilesList.request, getProfilesList);
}

function* switchProfile(action: {
  type: string;
  body: string;
}) {
  try {
    const result: ReturnType<typeof profileApi.switchProfile> = yield call(profileApi.switchProfile, action.body);

    yield put({
      type: constants.switchProfile.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.switchProfile.failure,
      e,
    });
  }
}

function* switchProfileWatcher() {
  yield takeEvery(constants.switchProfile.request, switchProfile);
}

function* switchProfileWithRedirection(action: {
  type: string;
  body: {
    profileId: string;
    redirectUrl: string;
  };
}) {
  try {
    const result: ReturnType<typeof profileApi.switchProfile> = yield call(profileApi.switchProfile, action.body.profileId);

    yield put({
      type: constants.switchProfileWithRedirection.success,
      data: result,
      redirectUrl: action.body.redirectUrl,
    });
  } catch (e) {
    yield put({
      type: constants.switchProfileWithRedirection.failure,
      e,
    });
  }
}

function* switchProfileWithRedirectionWatcher() {
  yield takeEvery(constants.switchProfileWithRedirection.request, switchProfileWithRedirection);
}

export function* fetchInstructorDetails() {
  try {
    const result: ReturnType<typeof instructorApi.fetchInstructorDetails> = yield call(instructorApi.fetchInstructorDetails);
    yield put({
      type: constants.fetchInstructorDetails.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchInstructorDetails.failure,
      e,
    });
  }
}

export function* fetchInstructorDetailsWatcher() {
  yield takeEvery(constants.fetchInstructorDetails.request, fetchInstructorDetails);
}
export function* getMainHsm(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof operationsApi.getMainHsm> = yield call(
      operationsApi.getMainHsm,
      action.values,
    );
    yield put({
      type: constants.getMainHsm.success,
      data: result,
      request: action.values,
    });
  } catch (e) {
    yield put({
      type: constants.getMainHsm.failure,
      e,
    });
  }
}

export function* getMainHsmWatcher() {
  yield takeEvery(constants.getMainHsm.request, getMainHsm);
}

export default function* profileSaga() {
  yield all([
    getProfileWatcher(),
    fetchDiscordURLsWatcher(),
    updateProfileWatcher(),
    toggleShowSuccessModalWatcher(),
    changePasswordWatcher(),
    getProfilesListWatcher(),
    switchProfileWatcher(),
    switchProfileWithRedirectionWatcher(),
    fetchInstructorDetailsWatcher(),
    getMainHsmWatcher(),
  ]);
}
