import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import sessionInstancesActions from '../../../store/sessionInstance/actions';

import styles from './AveragePresenceCard.module.css';
import UserCheckIcon from '../../../shared/Icons/UserCheck';
import LinkArrowIcon from '../../../shared/Icons/LinkArrowIcon';

interface IAveragePresenceCardProps {
  averagePresence: string;
}

const AveragePresenceCard: React.FC<IAveragePresenceCardProps> = ({
  averagePresence,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => dispatch(sessionInstancesActions.resetState())}
      className={styles.averagePresenceCard}
    >
      <Link to="/attendance">
        <>
          <div className={styles.icon}>
            <UserCheckIcon />
          </div>
          <div className={styles.amount}>{averagePresence}</div>
          <div className={styles.footer}>
            <p className={styles.title}>Avrg Presence</p>
            <LinkArrowIcon />
          </div>
        </>
      </Link>
    </div>
  );
};

export default AveragePresenceCard;
