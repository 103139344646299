import React from 'react';

import { Avatar, Progress } from 'antd';
import styles from './ResponsiveNPSCard.module.css';

interface IResponsiveNPSCardProps {
  nps: number;
  numberOfDetractors: number;
  numberOfFeedbacks: number;
  numberOfPromoters: number;
}

const ResponsiveNPSCard: React.FC<IResponsiveNPSCardProps> = ({
  nps,
  numberOfDetractors,
  numberOfFeedbacks,
  numberOfPromoters,
}) => {
  const getPercentage = (value: number, total: number) => ((value / total) * 100).toFixed(0);
  const getStrokeColor = (_nps: number) => {
    if (_nps < 40 || Number.isNaN(_nps)) return { color: '#F14C4C' };
    if (_nps > 39 && _nps < 60) return { color: '#F2D21F' };
    if (_nps > 59) return { color: '#4CCD59' };
    return { color: '#4CCD59' };
  };
  const numberOfPassive = numberOfFeedbacks - (numberOfDetractors + numberOfPromoters);

  return (
    <div className={styles.responsiveNPSCard}>
      <div className={styles.infos}>
        <h1 className={styles.title}>
          Personal performance
          {' '}
          <span className={styles.subTitle}> LAST 30 DAYS</span>
        </h1>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <p className={styles.percentage}>
              {getPercentage(numberOfDetractors, numberOfFeedbacks) !== 'NaN'
                ? getPercentage(numberOfDetractors, numberOfFeedbacks)
                : 0}
              %
            </p>
            <p className={styles.type}>Detractors</p>
          </div>
          <div className={styles.stat}>
            <p className={styles.percentage}>
              {getPercentage(numberOfPassive, numberOfFeedbacks) !== 'NaN'
                ? getPercentage(numberOfPassive, numberOfFeedbacks)
                : 0}
              %
            </p>
            <p className={styles.type}>Passive</p>
          </div>
          <div className={styles.stat}>
            <p className={styles.percentage}>
              {getPercentage(numberOfPromoters, numberOfFeedbacks) !== 'NaN'
                ? getPercentage(numberOfPromoters, numberOfFeedbacks)
                : 0}
              %
            </p>
            <p className={styles.type}>Promoters</p>
          </div>
        </div>
      </div>
      <div>
        <Progress
          strokeWidth={10}
          strokeColor={getStrokeColor(nps)}
          type="dashboard"
          gapPosition="bottom"
          // eslint-disable-next-line react/no-unstable-nested-components
          format={() => <Avatar size={40} />}
          className={styles.circularProgress}
          size={60}
          percent={nps}
        />
        <div className={styles.score}>
          <p className={styles.currentScore}>
            {nps.toFixed(0)}
&nbsp;
          </p>
          {' '}
          <p className={styles.totalScore}> / 100</p>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveNPSCard;
