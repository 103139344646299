import React from 'react';

import { Link } from 'react-router-dom';
import styles from './TotalStudentsCard.module.css';
import LinkArrowIcon from '../../../shared/Icons/LinkArrowIcon';
import UserGraduateIcon from '../../../shared/Icons/UserGraduate';

interface ITotalStudentsCardProps {
  studentNumber: number;
}

const TotalStudentsCard: React.FC<ITotalStudentsCardProps> = ({
  studentNumber,
}) => (
  <Link to="/students">
    <div className={styles.totalStudentsCard}>
      <div className={styles.icon}>
        <UserGraduateIcon />
      </div>

      <div className={styles.amount}>{studentNumber}</div>
      <div className={styles.footer}>
        <p className={styles.title}>Students</p>
        <LinkArrowIcon />
      </div>
    </div>
  </Link>
);

export default TotalStudentsCard;
