import { Reducer } from 'redux';
import { CastOpening, CastProfile, CastTrack } from '@gomycode/types-utils/Types/Casters';
import constants from './constants';
import { SessionInstancesState } from './types';

const initialState: SessionInstancesState = {
  loading: false,
  data: [],
  dashboardSessionInstances: [],
  fetchDashboardSessionInstancesLoading: false,
  paginationIndex: 0,
  hasMore: false,
  sessionInstance: {
    track: CastTrack(),
    sessionInstance: {
      status: 0,
      id: '',
      sessionNumber: '',
      startDate: '',
      dueDate: '',
      numberOfPresentParticipants: 0,
      numberOfAbsentParticipants: 0,
      timeTable: 0,
      events: [],
      name: '',
      onlineSession: null,
    },
    students: [],
    studentsPartOf: [],
    opening: CastOpening(),
    instructor: CastProfile(),
    instructorPartOf: {
      presenceStatus: 0,
    },
  },
  fetchSessionInstanceLoading: false,
  markPresenceLoading: false,
  shouldRefetchSession: false,
  showAbsenceModal: false,
  showCompleteModal: false,
  completeSessionLoading: false,
  selectedStudentIndex: 0,
  fetchPresenceLoading: false,
  presenceStats: {
    numberOfAbsentInstructors: 0,
    numberOfAbsentStudents: 0,
    numberOfPresentInstructors: 0,
    numberOfPresentStudents: 0,
  },
  studentSessionInstances: [],
  studentSessionInstancesLoading: false,
  studentSessionInstancesErrors: [],
  getStudentAverageAbsenceLoading: false,
  getStudentAverageAbsenceErrors: [],
  studentAverageAbsence: {},
};

const reducer: Reducer<SessionInstancesState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action,
) => {
  switch (action.type) {
    case constants.fetchAllSessionInstances.request:
    case constants.fetchAllSessionInstancesWithoutPagination.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchAllSessionInstancesWithoutPagination.success:
      return {
        ...state,
        loading: false,
        data: action.data.model,
      };
    case constants.fetchAllSessionInstances.success:
      if (state.paginationIndex === 0) {
        return {
          ...state,
          loading: false,
          data: action.data.model,
          paginationIndex: state.paginationIndex + 1,
          hasMore: action.data.model.length === 10,
        };
      }
      return {
        ...state,
        data: [...state.data, ...action.data.model],
        loading: false,
        paginationIndex: state.paginationIndex + 1,
        hasMore: action.data.model.length === 10,
      };

    case constants.setPaginationIndex.success:
      return {
        ...state,
        paginationIndex: action.data,
      };

    case constants.fetchDashboardSessionInstances.request:
      return {
        ...state,
        fetchDashboardSessionInstancesLoading: true,
      };
    case constants.fetchDashboardSessionInstances.success:
      return {
        ...state,
        fetchDashboardSessionInstancesLoading: false,
        dashboardSessionInstances: action.data.model,
      };
    case constants.fetchDashboardSessionInstances.failure:
      return {
        ...state,
        fetchDashboardSessionInstancesLoading: false,
      };

    case constants.fetchSessionInstanceById.request:
      return {
        ...state,
        fetchSessionInstanceLoading: true,
      };
    case constants.fetchSessionInstanceById.success:
      return {
        ...state,
        fetchSessionInstanceLoading: false,
        sessionInstance: action.data.model,
        shouldRefetchSession: false,
      };

    case constants.markPresenceById.request:
      return {
        ...state,
        markPresenceLoading: true,
      };
    case constants.markPresenceById.success:
      return {
        ...state,
        markPresenceLoading: false,
        showAbsenceModal: false,
        shouldRefetchSession: true,
        selectedStudentIndex: state.selectedStudentIndex + 1,
      };

    case constants.showAbsenceModal.success:
      return {
        ...state,
        showAbsenceModal: action.data,
      };

    case constants.showCompletedSessionModal.success:
      return {
        ...state,
        showCompleteModal: action.data,
      };

    case constants.completeSession.request:
      return {
        ...state,
        completeSessionLoading: true,
      };
    case constants.completeSession.success:
      return {
        ...state,
        completeSessionLoading: false,
        shouldRefetchSession: true,
      };

    case constants.setSelectedStudentIndex.success:
      return {
        ...state,
        selectedStudentIndex: action.data,
      };

    case constants.fetchPresenceStats.request:
      return {
        ...state,
        fetchPresenceLoading: true,
      };
    case constants.fetchPresenceStats.success:
      return {
        ...state,
        fetchPresenceLoading: false,
        presenceStats: action.data.model,
      };

    case constants.resetState.success:
      return {
        ...state,
        data: [],
        paginationIndex: 0,
      };
    case constants.getStudentSessionInstances.request:
      return { ...state, studentSessionInstancesLoading: true };

    case constants.getStudentSessionInstances.success: {
      return {
        ...state,
        studentSessionInstancesLoading: false,
        studentSessionInstances: action.data.model,
      };
    }
    case constants.getStudentSessionInstances.failure: {
      return {
        ...state,
        studentSessionInstancesLoading: false,
        studentSessionInstancesErrors: [],
      };
    }
    case constants.getStudentAverageAbsence.request:
      return { ...state, getStudentAverageAbsenceLoading: true };

    case constants.getStudentAverageAbsence.success: {
      return {
        ...state,
        getStudentAverageAbsenceLoading: false,
        studentAverageAbsence: action.data.model,
      };
    }
    case constants.getStudentAverageAbsence.failure: {
      return {
        ...state,
        getStudentAverageAbsenceLoading: false,
        getStudentAverageAbsenceErrors: [],
      };
    }

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as sessionInstancesReducer };
