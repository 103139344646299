import {
  generateActionWithBody, generateActionWithBodyAndId, generateActionWithMultipleValues,
} from '../../shared/utils/generic-saga';
import constants from './constants';

const actions = {
  fetchActiveOnlineSessions: generateActionWithMultipleValues(
    constants.fetchActiveOnlineSessions.request,
  ),
  fetchActiveOnlineSessionsV2: generateActionWithMultipleValues(
    constants.fetchActiveOnlineSessionsV2.request,
  ),
  fetchAllOnlineSessions: generateActionWithMultipleValues(
    constants.fetchAllOnlineSessions.request,
  ),
  fetchOnlineSessionById: generateActionWithMultipleValues(
    constants.fetchOnlineSessionById.request,
  ),
  showResourcesModal: generateActionWithBody(
    constants.showResourcesModal.request,
  ),
  createOnlineSessionResource: generateActionWithBodyAndId(
    constants.createOnlineSessionResource.request,
  ),
  showNotesModal: generateActionWithBody(
    constants.showNotesModal.request,
  ),
  updateOnlineSessionById: generateActionWithBodyAndId(
    constants.updateOnlineSessionById.request,
  ),
  fetchAllOnlineSessionsWithoutPagination: generateActionWithMultipleValues(
    constants.fetchAllOnlineSessionsWithoutPagination.request,
  ),
};

export default actions;
