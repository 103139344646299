import React from 'react';

type BulletEyeIconProps = {
  className?: string;
};

const BulletEyeIcon: React.FC<BulletEyeIconProps> = ({ className }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.5078 6.10156L11.3008 6.23828C11.3828 6.56641 11.4375 6.89453 11.4375 7.25C11.4375 9.4375 9.66016 11.1875 7.5 11.1875C5.3125 11.1875 3.5625 9.4375 3.5625 7.25C3.5625 5.08984 5.3125 3.3125 7.5 3.3125C7.85547 3.3125 8.18359 3.36719 8.51172 3.44922L8.64844 4.24219L7.80078 5.08984C7.69141 5.08984 7.58203 5.0625 7.5 5.0625C6.26953 5.0625 5.3125 6.04688 5.3125 7.25C5.3125 8.48047 6.26953 9.4375 7.5 9.4375C8.70312 9.4375 9.6875 8.48047 9.6875 7.25C9.6875 7.16797 9.66016 7.05859 9.66016 6.94922L10.5078 6.10156ZM14.0898 4.95312C14.3359 5.66406 14.5 6.45703 14.5 7.25C14.5 11.1328 11.3555 14.25 7.5 14.25C3.61719 14.25 0.5 11.1328 0.5 7.25C0.5 3.39453 3.61719 0.25 7.5 0.25C8.29297 0.25 9.08594 0.414062 9.79688 0.660156L8.375 2.08203C8.07422 2.02734 7.77344 2 7.5 2C4.57422 2 2.25 4.35156 2.25 7.25C2.25 10.1484 4.57422 12.5 7.5 12.5C10.3984 12.5 12.75 10.1484 12.75 7.25C12.75 6.97656 12.7227 6.67578 12.668 6.375L14.0898 4.95312ZM7.9375 7.71484C7.69141 7.98828 7.28125 7.98828 7.03516 7.71484C6.76172 7.46875 6.76172 7.05859 7.03516 6.78516L9.55078 4.26953L9.30469 2.90234C9.27734 2.62891 9.35938 2.32812 9.55078 2.13672L10.9453 0.742188C11.1641 0.523438 11.5469 0.578125 11.6836 0.878906L12.3125 2.4375L13.8711 3.06641C14.1719 3.20312 14.2266 3.55859 14.0078 3.80469L12.6133 5.19922C12.4219 5.39062 12.1211 5.47266 11.8477 5.44531L10.4805 5.19922L7.9375 7.71484Z"
      fill="#176FFF"
      className={className}
    />
  </svg>
);

export default BulletEyeIcon;

BulletEyeIcon.defaultProps = {
  className: '',
};
