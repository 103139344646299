/* eslint-disable @typescript-eslint/no-explicit-any */
import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

type Query = {
    query: [];
  };

export const fetchActiveOnlineSessions = async (values: Query) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.online.sessions.getActiveSession + queryString,
  );
  return result.data;
};

export const fetchActiveOnlineSessionsV2 = async (values: Query) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.online.sessions.getActiveSessionV2 + queryString,
  );

  return result.data;
};

export const fetchAllOnlineSessions = async (values: Query) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.online.sessions.getAllSessions + queryString,
  );

  return result.data;
};

export const fetchOnlineSessionById = async ({ id }: { id: string }) => {
  const result = await axiosInstance.get(
    URL.online.sessions.getSessionById(id),
  );

  return result.data;
};

export const UpdateOnlineSessionById = async (id: string, body: {}) => {
  const result = await axiosInstance.put(
    URL.online.sessions.updateOnlineSessionById(id),
    body,
  );

  return result.data;
};

export default {
  fetchActiveOnlineSessions,
  fetchAllOnlineSessions,
  fetchOnlineSessionById,
  UpdateOnlineSessionById,
};
