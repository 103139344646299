/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/STUDENT/';
export default {
  getAllStudents: generateActionTypes(root, 'GET_ALL_STUDENTS'),
  getStudentById: generateActionTypes(root, 'GET_STUDENTS_BY_ID'),
  getStudentStats: generateActionTypes(root, 'GET_STUDENTS_STATS'),
  getLabPhaseAssignment: generateActionTypes(root, 'GET_LABPHASE_ASSIGMENT'),
  getStudentAbsence: generateActionTypes(root, 'GET_STUDENT_ABSENCE'),
  getStudentAbsenceNew: generateActionTypes(root, 'GET_STUDENT_ABSENCE_NEW'),
  fetchLabPhase: generateActionTypes(root, 'GET_STUDENTS_LAB_PHASE'),
  getSessionInstance: generateActionTypes(root, 'GET_SESSION_INSTANCE'),
  getAllSessionInstance: generateActionTypes(root, 'GET_ALL_SESSION_INSTANCE'),
  getLeaderBoardStudents: generateActionTypes(
    root,
    'GET_LEADER_BOARD_STUDENTS',
  ),
  getStudentLearnings: generateActionTypes(root, 'GET_STUDENT_LEARNING'),
  resetState: generateActionTypes(root, 'REST_STATE'),
  getLabPhaseAssignmentByID: generateActionTypes(
    root,
    'GET_LABPHASE_ASSIGNMENT_BY_ID',
  ),
  fetchStudentDiplomaByTrackId: generateActionTypes(
    root,
    'GET_STUDENT_DIPLOMA_BY_TRACK_ID',
  ),
  fetchStudentsPerformanceById: generateActionTypes(
    root,
    'GET_STUDENTS_PERFORMANCE_BY_ID',
  ),
  fetchAllLabPhaseApplications: generateActionTypes(
    root,
    'FETCH_ALL_LABPHASE_APPLICATIONS',
  ),
  setPaginationIndex: generateActionTypes(root, 'SET_PAGINATION_INDEX'),
  getStudentsDetails: generateActionTypes(root, 'GET_STUDENT_DETAILS'),
};
