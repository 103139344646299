/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import orderBy from 'lodash/orderBy';
import { CheckpointAssignment, Profile } from '@gomycode/types-utils/Types/types';

export const locked = 0;
export const unlocked = 1;
export const submitted = 2;
export const reviewed = 3;
export const approved = 4;
export const closed = 5;

type DataType = {
  meeting: {
    startDate: string;
  };
  checkpointAssignment: CheckpointAssignment;
}

type ReviewsList = {
  review: {
    comment: string;
    createdAt: string;
  };
  reviewer: Profile;
  status: number;
  date: string;
};
export const generateCheckpointHistory = (reviewsList: ReviewsList[], data: DataType) => {
  const { checkpointAssignment, meeting } = data;
  const formatDate = (date: string) => moment(date).format('dddd HH:mm - YYYY.MM.DD');

  const checkpointReviews = () => {
    if (reviewsList.length) {
      return reviewsList.map((el: ReviewsList) => ({
        title: 'Review',
        date: el.review.createdAt,
        status: `${el.reviewer.firstName} ${el.reviewer.lastName}`,
        comment: el.review.comment,
        reviewer: el.reviewer.fullName,
        reviewerPicture: el.reviewer.picture,
        type: 'reviewedTitle',
      }));
    }
    return reviewsList;
  };

  const checkpointEvents = () => {
    let events: { title: string; date: string; status: boolean; url: string; type: string; }[] = [];
    if (checkpointAssignment.lastSubmissions) {
      events = checkpointAssignment.lastSubmissions.map(
        (e: string, i: number) => ({
          title: 'Submission',
          date: checkpointAssignment.lastSubmissionDates[i],
          status: checkpointAssignment.status >= submitted,
          url: e,
          type: 'submittedTitle',
        }),
      );
    }
    return [
      ...events,
      {
        title: 'Submission',
        date:
          checkpointAssignment.submissionDate !== '0001-01-01T00:00:00+00:00'
          && checkpointAssignment.submissionDate,
        status: submitted,
        url: checkpointAssignment.url,
        type: 'submittedTitle',
      },
    ];
  };

  const checkpointHistory = !isEmpty(checkpointAssignment)
    ? [
      {
        title: 'Unlocked',
        date: checkpointAssignment.unlockDate,
        status: true,
        type: 'unlockedTitle',
      },
      ...checkpointReviews(),
      ...checkpointEvents(),

      {
        title: 'Approval',
        date:
            checkpointAssignment.approvalDate !== '0001-01-01T00:00:00+00:00'
            && checkpointAssignment.approvalDate,
        status: !isNull(checkpointAssignment.report),
        checkpoint: checkpointAssignment,
        type: 'approvedTitle',
      },
      {
        title: 'Meeting',
        date: meeting && meeting.startDate,
        status: meeting && moment().isAfter(meeting.startDate),
      },
    ]
    : [...checkpointReviews()];
  return orderBy(
    checkpointHistory,
    [(e) => Boolean(e.status), 'date'],
    ['desc', 'asc'],
  ).map((el) => {
    // eslint-disable-next-line no-param-reassign
    if (el.date) el.date = formatDate(el.date);
    return el;
  });
};

export const RATE_STUDENT_STATUS_MAP = {
  pending: 0,
  submitted: 1,
  rejecting: 2,
  rejected: 3,
  review: 4,
  validated: 5,
};

export const getCheckpointRateStudentStatus = (checkpointHistory: any) => {
  const newCheckpointHistory = checkpointHistory.filter(
    (event: { date: string }) => event.date,
  );
  const latestIndex = newCheckpointHistory.length - 1;
  if (newCheckpointHistory[latestIndex].title === 'Unlocked') return RATE_STUDENT_STATUS_MAP.pending;
  if (newCheckpointHistory[latestIndex].title === 'Submission') return RATE_STUDENT_STATUS_MAP.submitted;
  if (newCheckpointHistory[latestIndex].title === 'Review') return RATE_STUDENT_STATUS_MAP.rejected;
  if (newCheckpointHistory[latestIndex].title === 'Approval') return RATE_STUDENT_STATUS_MAP.validated;
  return null;
};
