import React from 'react';

type BookMarkIconProps = {
  className?: string;
  onClick?: () => void;
};

const BookMarkIcon: React.FC<BookMarkIconProps> = ({ className, onClick }) => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      d="M7.5 1.1875V10.25L3.75 8.0625L0 10.25V1.1875C0 0.679688 0.410156 0.25 0.9375 0.25H6.5625C7.07031 0.25 7.5 0.679688 7.5 1.1875Z"
      fill="#828A92"
    />
  </svg>
);

export default BookMarkIcon;

BookMarkIcon.defaultProps = {
  className: '',
  onClick: undefined,
};
