import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import trackActions from '../../store/tracks/actions';
import TrackCard from './TrackCard';
import styles from './MyCourses.module.css';

const MyCourses = () => {
  const dispatch = useDispatch();
  const tracks = useSelector(
    (state: {
      tracks: { tracks: { name: string; image: string; id: string }[] };
    }) => state.tracks.tracks,
  );
  useEffect(() => {
    dispatch(trackActions.fetchTracks());
  }, []);

  return (
    <Row>
      <Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>
        <div className={styles.myCourses}>
          <Row gutter={12}>
            {tracks?.map((el) => (
              <Col span={6}>
                <TrackCard image={el.image} title={el?.name} id={el?.id} />
              </Col>
            ))}
          </Row>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
        {tracks?.map((el) => (
          <TrackCard image={el.image} title={el?.name} id={el?.id} />
        ))}
      </Col>
    </Row>
  );
};

export default MyCourses;
