import { Reducer } from 'redux';
import { calculateScore } from '../../pages/AutomatedOneToOneDetails/QuestionsAndResponsesContainer/helper';
import constants from './constants';

import { automatedOneToOneState } from './types';

// Type-safe initialState!
export const initialState: automatedOneToOneState = {
  fetchAutomatedOneToOneByIdLoading: false,
  updateStudentAnswerLoading: false,
  automatedOneToOne: {},
  studentScores: [],
  studentNewAnswers: [],
  fetchAutomatedOneToOneByIdErrors: [],
  updateStudentAnswerErrors: [],
  questionIndex: 1,
  isCorrected: [],
  realTimeScore: 0,
  totalAnswrers: 0,
  correctAnswers: 0,
  showInstructorFeedback: false,
  showModal: false,
  sendInstructorFeedbackLoading: false,
  sendInstructorFeedbackErrors: [],
  sendFeedbackDisabled: false,
};

const reducer: Reducer<automatedOneToOneState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action,
) => {
  switch (action.type) {
    case constants.fetchAutomatedOneToOneById.request:
      return { ...state, fetchAutomatedOneToOneByIdLoading: true };

    case constants.fetchAutomatedOneToOneById.success: {
      return {
        ...state,
        fetchAutomatedOneToOneByIdLoading: false,
        automatedOneToOne: action.data.model,
        studentScores:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
          ),
        studentNewAnswers:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => (action.data.model.oneToOneAssignment.studentAnswers[i] == null
              ? 'Student didnt reply'
              : action.data.model.oneToOneAssignment.studentAnswers[i]),
          ),
        isCorrected: action.data.model.oneToOneAssignment.subjectQuestions.map(
          (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
        ),
        realTimeScore: calculateScore(
          action.data.model.oneToOneAssignment.studentAnswersScore,
          action.data.model.oneToOneAssignment.subjectQuestions.length,
        ),
        correctAnswers:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el === 2,
          ).length,
        totalAnswrers:
          action.data.model.oneToOneAssignment.studentAnswers.length,
        sendFeedbackDisabled:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el !== -1,
          ).length
          === action.data.model.oneToOneAssignment.subjectQuestions.length,
      };
    }
    case constants.fetchAutomatedOneToOneById.failure: {
      return {
        ...state,
        fetchAutomatedOneToOneByIdLoading: false,
        fetchAutomatedOneToOneByIdErrors: action.objectErrors,
      };
    }
    /** update student answer  */
    case constants.updateStudentAnswer.request:
      return { ...state, updateStudentAnswerLoading: true };
    case constants.updateStudentAnswer.success:
      return {
        ...state,
        updateStudentAnswerLoading: false,
        questionIndex: state.questionIndex + 1,

        studentScores:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
          ),
        studentNewAnswers:
          action.data.model.oneToOneAssignment.subjectQuestions.map(
            (e: string, i: number) => (action.data.model.oneToOneAssignment.studentAnswers[i] == null
              ? 'Student didnt reply'
              : action.data.model.oneToOneAssignment.studentAnswers[i]),
          ),
        isCorrected: action.data.model.oneToOneAssignment.subjectQuestions.map(
          (e: string, i: number) => action.data.model.oneToOneAssignment.studentAnswersScore[i],
        ),
        realTimeScore: calculateScore(
          action.data.model.oneToOneAssignment.studentAnswersScore,
          action.data.model.oneToOneAssignment.subjectQuestions.length,
        ),
        correctAnswers:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el === 2,
          ).length,

        showInstructorFeedback:
          state.questionIndex
          > action.data.model.oneToOneAssignment.subjectQuestions.length - 1,

        sendFeedbackDisabled:
          action.data.model.oneToOneAssignment.studentAnswersScore.filter(
            (el: number) => el !== -1,
          ).length
          === action.data.model.oneToOneAssignment.subjectQuestions.length,
      };
    case constants.updateStudentAnswer.failure:
      return {
        ...state,
        updateStudentAnswerLoading: false,
        updateStudentAnswerErrors: action.objectErrors,
      };

    case constants.setQuestionIndex.success:
      return {
        ...state,
        updateStudentAnswerErrors: [],
        questionIndex: action.data,
      };
    case constants.handleBackFromFeedback.success:
      return {
        ...state,
        updateStudentAnswerErrors: [],
        questionIndex: action.data,
        showInstructorFeedback: false,
      };

      // send instructor feedback

    case constants.sendInstructorFeedback.request:
      return { ...state, sendInstructorFeedbackLoading: true };
    case constants.sendInstructorFeedback.success:
      return {
        ...state,
        sendInstructorFeedbackLoading: false,
        showModal: true,
      };
    case constants.sendInstructorFeedback.failure:
      return {
        ...state,
        sendInstructorFeedbackLoading: false,
        sendInstructorFeedbackErrors: action.objectErrors,
      };
    case constants.setShowModal.success:
      return {
        ...state,
        showModal: action.data,
      };

    case constants.setShowInstructorFeedback.success:
      return {
        ...state,
        showInstructorFeedback: action.data,
      };
    case constants.resetOneToOneSettings.success:
      return {
        ...state,
        showModal: false,
        questionIndex: 1,
        showInstructorFeedback: false,
        sendFeedbackDisabled: false,
      };
    default: {
      return state;
    }
  }
};

export { reducer as automatedOneToOneReducer };
