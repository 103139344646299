import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import * as authApi from '../../shared/services/auth/auth';
import constants from './constants';

export function* refreshAccessToken(action: {
  type: string;
  body: {
    refreshToken: string;
  }
}) {
  try {
    const result: ReturnType<typeof authApi.refreshAccessToken> = yield call(authApi.refreshAccessToken, action.body);
    yield put({
      type: constants.refreshAccessToken.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.refreshAccessToken.failure,
      e,
    });
  }
}

export function* refreshAccessTokenWatcher() {
  yield takeEvery(constants.refreshAccessToken.request, refreshAccessToken);
}

export function* runRefreshToken() {
  try {
    yield put({
      type: constants.runRefreshToken.success,
    });
  } catch (e) {
    yield put({
      type: constants.runRefreshToken.failure,
      e,
    });
  }
}

export function* runRefreshTokenActionWatcher() {
  yield takeEvery(constants.runRefreshToken.request, runRefreshToken);
}

export default function* globalSaga() {
  yield all([
    refreshAccessTokenWatcher(),
    runRefreshTokenActionWatcher(),
  ]);
}
