import {
  generateActionWithBody,
  generateActionWithMultipleValues,
  generateEmptyAction,
} from '../../shared/utils/generic-saga';
import constants from './constants';

export const getProfile = generateEmptyAction(constants.getProfile.request);

export const fetchDiscordURLs = generateEmptyAction(
  constants.fetchDiscordURLs.request,
);

export const updateProfile = generateActionWithBody(
  constants.updateProfile.request,
);

export const toggleShowSuccessModal = generateEmptyAction(
  constants.toggleShowSuccessModal.request,
);

export const changePassword = generateActionWithBody(
  constants.changePassword.request,
);

export const getProfilesList = generateActionWithBody(
  constants.getProfilesList.request,
);

export const switchProfile = generateActionWithBody(
  constants.switchProfile.request,
);

export const switchProfileWithRedirection = generateActionWithBody(
  constants.switchProfileWithRedirection.request,
);
export const fetchInstructorDetails = generateEmptyAction(constants.fetchInstructorDetails.request);
export const getMainHsm = generateActionWithMultipleValues(
  constants.getMainHsm.request,
);
