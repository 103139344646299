import { Reducer } from 'redux';
import constants from './constants';
import { NPSState } from './types';

const initialState: NPSState = {
  loading: false,
  nps: 0,
  numberOfDetractors: 0,
  numberOfFeedbacks: 0,
  numberOfPromoters: 0,
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<NPSState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.getNPS.request:
      return {
        ...state,
        loading: true,
      };
    case constants.getNPS.success:
      return {
        ...state,
        loading: false,
        ...action.data.model,
      };

    default: {
      return state;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { reducer as NPSReducer };
