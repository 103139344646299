import React from 'react';

type LabPhaseListIconProps = {
  className?: string;
};

const LabPhaseListIcon: React.FC<LabPhaseListIconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 17.375C13.75 17.7266 13.4375 18 13.125 18H6.875C6.52344 18 6.25 17.7266 6.25 17.375V5.5H1.875C0.820312 5.5 0 6.35938 0 7.375V18.625C0 19.6797 0.820312 20.5 1.875 20.5H18.125C19.1406 20.5 20 19.6797 20 18.625V15.5H13.75V17.375ZM4.0625 17.6484C4.0625 17.8438 3.86719 18 3.71094 18H2.53906C2.34375 18 2.1875 17.8438 2.1875 17.6484V16.4766C2.1875 16.3203 2.34375 16.125 2.53906 16.125H3.71094C3.86719 16.125 4.0625 16.3203 4.0625 16.4766V17.6484ZM4.0625 13.5859C4.0625 13.7812 3.86719 13.9375 3.71094 13.9375H2.53906C2.34375 13.9375 2.1875 13.7812 2.1875 13.5859V12.4141C2.1875 12.2578 2.34375 12.0625 2.53906 12.0625H3.71094C3.86719 12.0625 4.0625 12.2578 4.0625 12.4141V13.5859ZM4.0625 9.52344C4.0625 9.71875 3.86719 9.875 3.71094 9.875H2.53906C2.34375 9.875 2.1875 9.71875 2.1875 9.52344V8.35156C2.1875 8.15625 2.34375 8 2.53906 8H3.71094C3.86719 8 4.0625 8.19531 4.0625 8.35156V9.52344ZM15.9375 16.4766C15.9375 16.3203 16.0938 16.125 16.2891 16.125H17.4609C17.6172 16.125 17.8125 16.3203 17.8125 16.4766V17.6484C17.8125 17.8438 17.6172 18 17.4609 18H16.2891C16.0938 18 15.9375 17.8438 15.9375 17.6484V16.4766ZM23.0859 0.5H9.33594C8.32031 0.5 7.46094 1.35938 7.46094 2.375V12.375C7.46094 13.4297 8.28125 14.25 9.33594 14.25H23.0859C24.1016 14.25 24.9609 13.4297 24.9609 12.375V2.375C25 1.35938 24.1406 0.5 23.0859 0.5ZM11.8359 3C12.5 3 13.0859 3.58594 13.0859 4.25C13.0859 4.95312 12.5 5.5 11.8359 5.5C11.1719 5.5 10.5859 4.95312 10.5859 4.25C10.5859 3.58594 11.1719 3 11.8359 3ZM22.4219 11.4375C22.3047 11.6328 22.0703 11.75 21.875 11.75H10.5859C10.3516 11.75 10.1562 11.6328 10.0391 11.4375C9.96094 11.2031 9.96094 10.9688 10.1172 10.7734L12.8516 7.02344C12.9688 6.86719 13.125 6.75 13.3203 6.75C13.5156 6.75 13.7109 6.86719 13.8281 7.02344L14.6875 8.23438L17.1484 4.5625C17.2656 4.36719 17.4609 4.25 17.6953 4.25C17.8906 4.25 18.0859 4.36719 18.2031 4.5625L22.3828 10.8125C22.5 10.9688 22.5 11.2422 22.4219 11.4375Z"
      fill="#176FFF"
      className="svg-light-mode"
    />
  </svg>
);

export default LabPhaseListIcon;

LabPhaseListIcon.defaultProps = {
  className: '',
};
