/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { logout } from './helper';

export const errorsExtraction = (errors: { [key: string]: any }): any[] => Object.keys(errors).reduce(
  (acc: any[], errorKey: string) => [...acc, ...errors[errorKey]],
  [],
);

export const registerErrorInterceptors = () => {
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle response error
      switch (error?.response?.status) {
        case 401:
          if (localStorage.getItem('token')) {
            logout();
          }
          break;
        default:
          break;
      }
      return Promise.reject(error);
    },
  );
};
