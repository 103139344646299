import React from 'react';

type InfoIconProps = {
  className?: string;
};

const InfoIcon: React.FC<InfoIconProps> = ({ className }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7 0.75C3.11719 0.75 0 3.89453 0 7.75C0 11.6328 3.11719 14.75 7 14.75C10.8555 14.75 14 11.6328 14 7.75C14 3.89453 10.8555 0.75 7 0.75ZM7 4.25C7.46484 4.25 7.875 4.66016 7.875 5.125C7.875 5.61719 7.46484 6 7 6C6.50781 6 6.125 5.61719 6.125 5.125C6.125 4.66016 6.50781 4.25 7 4.25ZM8.09375 11.25H5.90625C5.52344 11.25 5.25 10.9766 5.25 10.5938C5.25 10.2383 5.52344 9.9375 5.90625 9.9375H6.34375V8.1875H6.125C5.74219 8.1875 5.46875 7.91406 5.46875 7.53125C5.46875 7.17578 5.74219 6.875 6.125 6.875H7C7.35547 6.875 7.65625 7.17578 7.65625 7.53125V9.9375H8.09375C8.44922 9.9375 8.75 10.2383 8.75 10.5938C8.75 10.9766 8.44922 11.25 8.09375 11.25Z"
      fill="#828A92"
    />
  </svg>
);

export default InfoIcon;

InfoIcon.defaultProps = {
  className: '',
};
