import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/trackReview/';

const constants = {
  setNavigationIndex: generateActionTypes(root, 'SET_NAVIGATION_INDEX'),
  fetchSkeleton: generateActionTypes(root, 'FETCH_SKELETON'),
  getActiveStudents: generateActionTypes(root, 'GET_ACTIVE_STUDENTS'),
  getItem: generateActionTypes(root, 'GET_ITEM'),
  patchBookMark: generateActionTypes(root, 'PATCH_BOOK_MARK'),
  getBookMarkedNodes: generateActionTypes(root, 'GET_BOOKMARKED_NODES'),
  setCurriculumOpen: generateActionTypes(root, 'SET_CURRICULUM_OPEN'),
  openFeedbackModal: generateActionTypes(root, 'OPEN_FEEDBACK_MODAL'),
  postSkillFeedback: generateActionTypes(root, 'POST_SKILL_FEEDBACK'),
  getResources: generateActionTypes(root, 'GET_RESOURCES'),
  patchResources: generateActionTypes(root, 'PATCH_RESOURCES'),
  setAddModalOpen: generateActionTypes(root, 'SET_ADD_MODAL_OPEN'),
  setEditModalOpen: generateActionTypes(root, 'SET_EDIT_MODAL_OPEN'),
  getTrackStats: generateActionTypes(root, 'GET_TRACK_STATS'),
  setUnsavedModalOpen: generateActionTypes(root, 'SET_UNSAVED_MODAL'),
  getOrderedOneToOnes: generateActionTypes(root, 'GET_ORDERED_ONE_TO_ONES'),
  getOrderedCheckpoints: generateActionTypes(root, 'GET_ORDERED_CHECKPOINTS'),
  getOrderedWorkshops: generateActionTypes(root, 'GET_ORDERED_WORKSHOPS'),
  resetNotification: generateActionTypes(root, 'RESET_NOTIFICATION'),
  patchResourcesFromTrack: generateActionTypes(
    root,
    'PATCH_RESOURCES_FROM_TRACK',
  ),
  setShowCurriculum: generateActionTypes(root, 'SET_SHOW_CURRICULUM'),
};

export default constants;
