import { Reducer } from 'redux';
import constants from './constants';
import { statsState } from './types';

// Type-safe initialState!
export const initialState: statsState = {
  loading: false,
  instructor: [],
};

// eslint-disable-next-line default-param-last
const reducer: Reducer<statsState> = (state = initialState, action) => {
  switch (action?.type) {
    case constants.fetchInstructorStats.request:
      return {
        ...state,
        loading: true,
      };
    case constants.fetchInstructorStats.success:
      return {
        ...state,
        loading: false,
        instructor: action.data.model,
      };

    default: {
      return state;
    }
  }
};

export { reducer as statsReducer };
