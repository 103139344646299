import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentQueryStringParameter } from './shared/utils/helper';
import { switchProfile } from './store/profile/actions';
import Loader from './shared/components/Loader';

interface IURLActionInterceptorProps {
    children: ReactElement
}

const URLActionInterceptor: React.FC<IURLActionInterceptorProps> = ({ children }) => {
  const dispatch = useDispatch();
  const profileId = getCurrentQueryStringParameter('switchToProfile');

  useEffect(() => {
    if (profileId) {
      dispatch(
        switchProfile(profileId),
      );
    }
  }, [profileId]);

  return profileId ? <Loader /> : children;
};

export default URLActionInterceptor;
