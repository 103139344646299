import React from 'react';

type AutomatedOneToOneIconProps = {
  className?: string;
};

const AutomatedOneToOneIcon: React.FC<AutomatedOneToOneIconProps> = ({
  className,
}) => (
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M23.125 0.5H8.125C7.07031 0.5 6.25 1.39844 6.25 2.45312V4.25C7.14844 4.25 8.00781 4.52344 8.71094 4.95312V3H22.4609V14.25H19.9609V12.375C19.9609 12.0625 19.6484 11.75 19.3359 11.75H15.5859C15.2344 11.75 14.9609 12.0625 14.9609 12.375V14.25H11.2109C12.0703 14.9141 12.7344 15.7734 13.2031 16.75H23.0859C24.1406 16.75 25 15.8906 25 14.8359V2.45312C25 1.39844 24.1406 0.5 23.125 0.5ZM6.25 13C8.32031 13 10 11.3594 10 9.25C10 7.17969 8.32031 5.5 6.25 5.5C4.14062 5.5 2.5 7.17969 2.5 9.25C2.5 11.3203 4.14062 13 6.25 13ZM7.5 14.25H5C2.22656 14.25 0 16.5156 0 19.25C0 19.9531 0.546875 20.5 1.25 20.5H11.25C11.9141 20.5 12.5 19.9531 12.5 19.25C12.5 16.5156 10.2344 14.25 7.5 14.25Z"
      fill="#176FFF"
    />
  </svg>
);

export default AutomatedOneToOneIcon;

AutomatedOneToOneIcon.defaultProps = {
  className: '',
};
