import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import * as api from '../../shared/services/instructor/oneToOnes';
import constants from './constants';

export function* fetchAllAutomatedOneToOne(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllOneToOne> = yield call(api.fetchAllOneToOne, action.values);
    yield put({
      type: constants.fetchAllAutomatedOneToOne.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllAutomatedOneToOne.failure,
      e,
    });
  }
}

export function* fetchAllAutomatedOneToOneWatcher() {
  yield takeEvery(constants.fetchAllAutomatedOneToOne.request, fetchAllAutomatedOneToOne);
}

export function* fetchAllFaceToFaceOneToOne(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllOneToOne> = yield call(api.fetchAllOneToOne, action.values);
    yield put({
      type: constants.fetchAllFaceToFaceOneToOne.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllFaceToFaceOneToOne.failure,
      e,
    });
  }
}

export function* fetchAllFaceToFaceOneToOneWatcher() {
  yield takeEvery(constants.fetchAllFaceToFaceOneToOne.request, fetchAllFaceToFaceOneToOne);
}

export function* fetchAllOneToOne(action: {
  type: string;
  values: {
    query: [];
  };
}) {
  try {
    const result: ReturnType<typeof api.fetchAllOneToOne> = yield call(api.fetchAllOneToOne, action.values);
    yield put({
      type: constants.fetchAllOneToOne.success,
      data: result,
    });
  } catch (e) {
    yield put({
      type: constants.fetchAllOneToOne.failure,
      e,
    });
  }
}

export function* fetchAllOneToOneWatcher() {
  yield takeEvery(constants.fetchAllOneToOne.request, fetchAllOneToOne);
}

export default function* OneToOneListSaga() {
  yield all([
    fetchAllAutomatedOneToOneWatcher(),
    fetchAllFaceToFaceOneToOneWatcher(),
    fetchAllOneToOneWatcher(),
  ]);
}
