import { getQueryString } from '@gomycode/design-system';
import axiosInstance from '../../utils/axios-config';
import URL from '../endpoints';

export const getMainHsm = async (values: { query: [] }) => {
  const { query } = values;
  const queryString = getQueryString(query);
  const result = await axiosInstance.get(
    URL.operations.locations.getMainHsm + queryString,
  );
  return result.data;
};
export default {
  getMainHsm,
};
