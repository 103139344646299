/* eslint-disable import/no-anonymous-default-export */
import { generateActionTypes } from '../../shared/utils/generic-saga';

const root = 'app/AUTOMATEDONETOONEDETAILS/';
export default {
  fetchAutomatedOneToOneById: generateActionTypes(
    root,
    'FETCH_AUTOMATED_ONE_TO_ONE_BY_ID',
  ),
  updateStudentAnswer: generateActionTypes(root, 'UPDATE_STUDENT_ANSWER'),
  setQuestionIndex: generateActionTypes(root, 'SET_QUESTION_INDEX'),
  handleBackFromFeedback: generateActionTypes(
    root,
    'HANDLE_BACK_FROM_FEEDBACK',
  ),
  sendInstructorFeedback: generateActionTypes(root, 'SEND_INSTRUCTOR_FEEDBACK'),
  setShowModal: generateActionTypes(root, 'SET_SHOW_MODAL'),
  resetOneToOneSettings: generateActionTypes(root, 'REST_ONE_TO_ONE_SETTINGS'),

  setShowInstructorFeedback: generateActionTypes(
    root,
    'SET_SHOW_INSTRUCTOR_FEEDBACK',
  ),
};
