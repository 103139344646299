import React from 'react';

type AttendanceIconProps = {
  className?: string;
};

const AttendanceIcon: React.FC<AttendanceIconProps> = ({ className }) => (
  <svg
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13.125 3C14.1406 3 15 3.85938 15 4.875V18.625C15 19.6797 14.1406 20.5 13.125 20.5H1.875C0.820312 20.5 0 19.6797 0 18.625V4.875C0 3.85938 0.820312 3 1.875 3H3.94531C4.49219 1.55469 5.85938 0.5 7.5 0.5C9.10156 0.5 10.4688 1.55469 11.0156 3H13.125ZM7.5 3C6.79688 3 6.25 3.58594 6.25 4.25C6.25 4.95312 6.79688 5.5 7.5 5.5C8.16406 5.5 8.75 4.95312 8.75 4.25C8.75 3.58594 8.16406 3 7.5 3ZM7.5 8C6.09375 8 5 9.13281 5 10.5C5 11.9062 6.09375 13 7.5 13C8.86719 13 10 11.9062 10 10.5C10 9.13281 8.86719 8 7.5 8ZM11.25 18C11.5625 18 11.875 17.7266 11.875 17.375C11.875 15.6562 10.4688 14.25 8.75 14.25H6.25C4.49219 14.25 3.125 15.6562 3.125 17.375C3.125 17.7266 3.39844 18 3.75 18H11.25Z"
      fill="#176FFF"
    />
  </svg>
);

export default AttendanceIcon;

AttendanceIcon.defaultProps = {
  className: '',
};
